.nlm_system_overrider-settings {
    background-color: $color-gray-white;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    width: 100%;

    .title {
      align-self: stretch;
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 34px 48px 56px;

      span{
        font-size: 28px;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: left;
        color: $day-text-default;
      }
      input {
        height: 52px;
        box-sizing: border-box;
        padding: 16px;
        border-radius: 8px;
        background-color: $day-grey-lightest;
        color: $day-text-default;
        border: 1px solid transparent;
        outline: none !important;

        &:hover {
          background: $day-primary-lightest-tint;

          &::placeholder {
            color: $day-text-default;
          }
        }

        &:focus, &:active {
          border-color: $day-primary-blue;
          background: $day-grey-white;
        }

        &::placeholder {
          color: $day-grey-dark;
        }
      }
    }
  }

  .nlm_system_overrider-range {
    &:hover{
     & .range__tooltip, .range__tooltip-caret{visibility: visible;}
    }
    .range {
      &__tooltip {
        position: relative;
        margin-left: -28px;
        width: 56px;
        background: $color-blue-dark;
        @include border-radius(26px);
        color: $color-gray-white;
        text-align: center;
        z-index: 100;
        padding: 4px 12px;
        visibility: hidden;
       
        &-caret {
          position: relative;
          transform: rotate(138deg);
          width: 8px;
          height: 8px;
          margin-top: -6px;
          margin-left: -4px;
          background: $color-blue-dark;
          visibility: hidden;
        }
      }
      &__input {
        width: calc(100% - 64px);
        -webkit-appearance: none;
        display: block;
        height: 24px;
        padding: 0 32px;
        line-height: 1.5;
        font: 1rem/1 arial, sans-serif;
        background-clip: padding-box;
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        position: relative;
        margin:0;
        font-size: 14px;
        font-weight: 400;
  
        &:active, :focus{
          
          &::-webkit-slider-runnable-track{background:rgba($day-primary-blue,0.32);
          }
        }
  
        &::-webkit-slider-thumb {
  
          box-sizing: border-box;
          border: none;
          @include border-radius(50%);
          background: $day-primary-blue;
          height: 16px;
          width: 16px;
          cursor: pointer;
          transition: box-shadow 0.5s ease;
          position: relative;
          justify-items: center;
          margin-top: -6px;
        }
  
        &::-webkit-slider-runnable-track {
          box-sizing: border-box;
          border: none;
          height: 4px;
          background: $color-utility-lightest;
          @include border-radius(2px);
        }
  
      }  
  
      &__ticks{
        display: flex;
        justify-content: space-between;
        padding: 0px 32px 10px 32px;
  
        .item{
          position: relative;
          display: flex;
          justify-content: center;
          margin-bottom: 20px;
          color: $color-blue-light;
          font-size: 12px;
        }
      }
    }  
  }