﻿.card {
    @include border-radius(8px);
    @include box-shadow(0);
    @include mq(sm_down) {pointer-events: none;}
    background: $color-gray-white;
    cursor: pointer;
    display: grid;
    grid-row-gap: 8px;
    grid-template-rows: auto;
    padding: 2rem 2rem 1.5rem 2rem;
    position: relative;
    height: 256px;
    overflow-wrap: anywhere;

    &--profile {
        cursor: auto;
        height: 416px;
        width: 416px;
    }

    @include mq(lg_down) {
        height: 240px;
        padding: 1.75rem 1.5rem 1.25rem 1.5rem;
    }

    @include mq(sm_down) {
        height: 204px;
        padding: 1.25rem 1rem 1rem 1rem;
    }

    &:hover, &.is-selected {
        @include box-shadow(1);

        .card__button {
            @include transition(all 0.25s ease-in);
            opacity: 1;
        }
    }
}

.l-drawer 
{
    .card 
    {
        &.is-selected:before {
            @include border-radius(8px 8px 0 0);
            background-color: $color-primary;
            content: "";
            display: block;
            height: 8px;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
}

    @supports not (overflow-wrap: anywhere) {
        .card {
            overflow-wrap: break-word;
        }
    }

    .card__button {
        justify-self: end;
        opacity: 0;
    }

    .card__chart {
        &--full-width {
            margin: 0 -32px;
        }
    }

    .card__expand {
        cursor: pointer;
        font-size: 24px;
        position: absolute;
        right: 12px;
        top: 12px;
    }

    .card__grid {
        align-items: center;
        display: grid;
        grid-auto-flow: column;
        grid-column-gap: 16px;
        justify-content: space-between;
    }

    .card__group {
        display: contents;
    }

    .card__group-header {
        position: relative;
        border-bottom: 1px solid $day-utility-lightest;
        height: 50px;
        color: $day-utility-dark;
        margin-bottom: 2.5rem;

        &:not(#group0) {
            margin-top: 2rem;

            @include mq(md_down) {
                margin-top: .5rem;
            }
        }

        @include mq(lg_down) {
            margin-bottom: 2rem;
        }

        @include mq(md_down) {
            margin-bottom: 1.5rem;
            height: 44px;
        }
    }

    .card__group-toggle {
        position: absolute;
        right: 0;
        bottom: -12px;
        padding-left: 24px;
        background-color: $day-primary-lightest;

        & .icon {
            color: $day-utility-utility;
        }
    }

    .card__tags {
        height: 32px;
        color: $day-utility-dark;
        font-weight: $font-weight-medium;
    }

    .card__top {
        margin: 0;
        height: 148px;

        h1 {
            color: $day-text-default;
            margin: .5rem auto;
            max-height: 4rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        @include mq(lg_down) {
            height: 136px;

            h1 {
                max-height: 3.5rem;
            }
        }

        @include mq(md_down) {
            height: 128px;

            h1 {
                max-height: 3.5rem;
            }
        }
    }

    .card__footer {
        padding-top: 1rem;
        font-size: 14px;
        font-weight: $font-weight-medium !important;
        text-align: right;
        color: $day-utility-dark;

        @include mq(md_down) {
            padding-bottom: 8px;
        }
    }