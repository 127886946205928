﻿.filter-list {
    border-top: 1px solid $color-utility-lightest;
    list-style: none;
    margin: 0;
    padding: 0;
}
.filter-list__icon {
    display: inline-block;
    font-size: 24px;
}
.filter-list__item {
    @include transition(all 0.25s ease-in-out);
    align-content: center;
    color: $color-utility;
    cursor: pointer;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 8px;
    grid-template-columns: 24px auto 48px;
    padding: 16px;
    &:hover {
        background-color: $color-gray-light;
        color: $color-primary;
    }
}
.filter-list__toggle {
    font-size: 24px;
    justify-self: end;
}