﻿/* This sets the Ghost Region background and text attributes for drag and drop on the Gantt Grid */
.e-gantt .e-cloneproperties.e-dragclone {
    &.e-headerclone {
        position: absolute;
        color: $day-primary-blue;
        border-color: $day-primary-blue;
        background-color: $color-primary-lightest;
        border-radius: unset;
        border-width: 2px;
        margin: 1px;
        font-size: 16px;
        text-align: justify;
        padding: 0 1.25em;
        z-index: 20;

        &.e-notallowedcur {
            cursor: url('../../img/not-allowed.png'), auto;
        }
    }

    &:not(.e-headerclone) {
        position: absolute;
        box-sizing: border-box;
        padding: 0;
        height: 56px !important;
        min-width: 420px;
        @include box-shadow(3);
        font-family: $bst-font-family;
        z-index: 20;
        opacity: 1;
        pointer-events: none;

        .e-dropitemscount {
            display: none;
        }

        table {
            width: 100%;

            tr:first-child {
                td {
                    box-sizing: border-box;
                    padding: 0;
                    font-size: 14px;

                    &:first-child {
                        background-color: $day-other-drawer;
                        color: $day-primary-darkest;
                        width: 20px;

                        .e-icons {
                            width: 20px;

                            &:before {
                                font-family: '#{$icomoon-font-family}';
                                content: '#{$icon-drag-1}';
                                font-size: 1.5rem;
                                font-weight: $font-weight-regular !important;
                                color: $day-primary-darkest !important;
                                opacity: 1;
                                margin-left: -4px;
                            }
                        }
                    }

                    &:nth-child(2) {
                        background-color: $day-primary-lightest;
                        position: relative;
                        overflow: visible;

                        &::before {
                            content: attr(aria-label);
                            position: absolute;
                            left: 68px;
                            top: 20px;
                            width: calc(100% - 88px);
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .errorValue {
                            position: absolute;
                            top: 0;
                            padding-left: 0 !important;
                            height: 56px;
                            background-color: transparent;
                        }

                        .e-errorcontainer {
                            position: absolute !important;
                            top: 2px;
                            font-size: 1rem;
                            color: $day-red-dark !important;
                            font-weight: $font-weight-semibold;
                            z-index: 10;
                        }

                        .e-treecolumn-container {
                            position: relative;
                            height: 56px !important;
                            @include flexbox();

                            .e-treegridexpand, .e-treegridcollapse {
                                display: unset !important;
                                color: $day-grey-darkest;
                                margin-top: 20px;
                                margin-left: 24px;
                                z-index: 10;

                                &:before {
                                    font-family: '#{$icomoon-font-family}';
                                    content: '#{$icon-chevron-right_micro}';
                                    color: $day-grey-darkest !important;
                                    font-size: larger;
                                    position: relative;
                                }

                                & ~ .e-icons.e-none {
                                    width: 0 !important;
                                }
                            }

                            .e-icons.e-none {
                                display: unset;
                                width: 16px !important;
                            }

                            .floating-menu, .floating-menu--bottom {
                                display: none !important;
                            }

                            .e-treecell {
                                @include flexbox();
                                flex-wrap: nowrap;
                                align-items: stretch;
                                align-content: center;

                                .linenbr {
                                    display: unset;
                                    position: absolute;
                                    left: 0;
                                    box-sizing: border-box;
                                    text-align: center;
                                    line-height: 56px;
                                    width: 36px;
                                    background-color: $day-other-drawer;
                                    color: $day-utility-light;
                                    font-weight: $font-weight-semibold;
                                }

                                .property-cell {
                                    display: unset;
                                    padding-left: 6px;
                                }
                            }
                        }
                    }

                    &:nth-child(n+3) {
                        display: none;
                    }
                }
            }

            tr:not(:first-child) {
                display: none;
            }
        }

        .children-count {
            position: absolute;
            display: block;
            top: -8px;
            right: -8px;
            width: 20px;
            height: 20px;
            text-align: center;
            font-size: .75rem !important;
            line-height: 20px;
            @include border-radius(10px);
            z-index: 11;
            border: none !important;
            background: $day-primary-blue !important;
            color: $day-grey-white !important;
        }
    }
}
