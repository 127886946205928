﻿@mixin animation($name, $duration: 1s, $timing: ease, $delay: 0s, $iteration: 1) { //used to call animations in _animations.scss
    -webkit-animation-name: $name;
    -webkit-animation-duration: $duration;
    -webkit-animation-timing-function: $timing;
    -webkit-animation-delay: $delay;
    -webkit-animation-iteration-count: $iteration;
    -moz-animation-name: $name;
    -moz-animation-duration: $duration;
    -moz-animation-timing-function: $timing;
    -moz-animation-delay: $delay;
    -moz-animation-iteration-count: $iteration;
    animation-name: $name;
    animation-duration: $duration;
    animation-timing-function: $timing;
    animation-delay: $delay;
    animation-iteration-count: $iteration;
}

@mixin keyframes($name) { //only used within _animations.scss for adding browser prefixes to keyframes
    @-webkit-keyframes #{$name} {
        @content;
    }

    @-moz-keyframes #{$name} {
        @content;
    }

    @keyframes #{$name} {
        @content;
    }
}

@mixin border-radius($value) {
    -moz-border-radius: $value;
    -webkit-border-radius: $value;
    border-radius: $value;
}

@mixin box-shadow($level) {
    @if $level == -1 {
        -webkit-box-shadow: $box-shadow--1;
        -moz-box-shadow: $box-shadow--1;
        box-shadow: $box-shadow--1;
    }

    @if $level == 0 {
        -webkit-box-shadow: $box-shadow-0;
        -moz-box-shadow: $box-shadow-0;
        box-shadow: $box-shadow-0;
    }

    @if $level == 1 {
        -webkit-box-shadow: $box-shadow-1;
        -moz-box-shadow: $box-shadow-1;
        box-shadow: $box-shadow-1;
    }
    @else if $level == 2 {
        -webkit-box-shadow: $box-shadow-2;
        -moz-box-shadow: $box-shadow-2;
        box-shadow: $box-shadow-2;
    }
    @else if $level == 3 {
        -webkit-box-shadow: $box-shadow-3;
        -moz-box-shadow: $box-shadow-3;
        box-shadow: $box-shadow-3;
    }
    @else if $level == 4 {
        -webkit-box-shadow: $box-shadow-4;
        -moz-box-shadow: $box-shadow-4;
        box-shadow: $box-shadow-4;
    }
    @else if $level == 5 {
        -webkit-box-shadow: $box-shadow-5;
        -moz-box-shadow: $box-shadow-5;
        box-shadow: $box-shadow-5;
    }
    @else if $level == none {
        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
        box-shadow: none !important;
    }
    @else {
        -webkit-box-shadow: $level;
        -moz-box-shadow: $level;
        box-shadow: $level;
    }
}

@mixin flexbox() {
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
}

@mixin filter($value) {
    -webkit-filter: $value;
    -moz-filter: $value;
    -o-filter: $value;
    -ms-filter: $value;
    filter: $value;
}

@mixin user-select($value) {
    user-select: $value;
    -moz-user-select: $value;
    -webkit-user-select: $value;
    -ms-user-select: $value;
}

@mixin transform($value) {
    -o-transform: $value;
    -ms-transform: $value;
    -webkit-transform: $value;
    transform: $value;
}

@mixin transition($value...) {
    -webkit-transition: $value;
    transition: $value;
}

@mixin appearance($value) {
    -webkit-appearance: $value;
    -moz-appearance: $value;
    appearance: $value;
}


/* media query mixins */
$xxl: $break-xxl;
$xl: $break-xl;
$lg: $break-lg;
$md: $break-md;
$sm: $break-sm;
$xs: $break-xs;

@mixin mq($canvas) {
    @if $canvas == xs_up {
        @media only screen and (min-width: $xs) {
            @content;
        }
    }
    @else if $canvas == sm_up {
        @media only screen and (min-width: $sm) {
            @content;
        }
    }
    @else if $canvas == md_up {
        @media only screen and (min-width: $md) {
            @content;
        }
    }
    @else if $canvas == lg_up {
        @media only screen and (min-width: $lg) {
            @content;
        }
    }
    @else if $canvas == xl_up {
        @media only screen and (min-width: $xl) {
            @content;
        }
    }
    @else if $canvas == xl_down {
        @media only screen and (max-width: ($xl - 1px)) {
            @content;
        }
    }
    @else if $canvas == lg_down {
        @media only screen and (max-width: ($lg - 1px)) {
            @content;
        }
    }
    @else if $canvas == md_down {
        @media only screen and (max-width: ($md - 1px)) {
            @content;
        }
    }
    @else if $canvas == sm_down {
        @media only screen and (max-width: ($sm - 1px)) {
            @content;
        }
    }
    @else if $canvas == xs_down {
        @media only screen and (max-width: ($xs - 1px)) {
            @content;
        }
    }
    @else if $canvas == xxs {
        @media only screen and (max-width: ($xs - 1px)) {
            @content;
        }
    }
    @else if $canvas == xs {
        @media only screen and (min-width: $xs) and (max-width: ($sm - 1px)) {
            @content;
        }
    }
    @else if $canvas == sm {
        @media only screen and (min-width: $sm) and (max-width: ($md - 1px)) {
            @content;
        }
    }
    @else if $canvas == md {
        @media only screen and (min-width: $md) and (max-width: ($lg - 1px)) {
            @content;
        }
    }
    @else if $canvas == lg {
        @media only screen and (min-width: $lg) and (max-width: ($xl - 1px)) {
            @content;
        }
    }
    @else if $canvas == xl {
        @media only screen and (min-width: $xl) {
            @content;
        }
    }
    @else if $canvas == xxl {
        @media only screen and (min-width: $xxl) {
            @content;
        }
    }
}

// $mode parameter:
// 'v' - show only vertical scrollbar
// 'h' - show only horizontal scrollbar
@mixin scrollbars($track-background: transparent, $thumb-background: $color-utility-light, $mode: 'both', $size: 24px, $staticV: true, $staticH: true) {
    scroll-behavior: auto !important;
    scrollbar-gutter: auto;

    @if $mode == 'v' {
        overflow-y: auto;
        overflow-y: overlay !important;
    }
    @else if $mode == 'h' {
        overflow-x: auto;
        overflow-x: overlay !important;
    }
    @else {
        overflow-x: auto;
        overflow-y: auto;
        overflow-x: overlay !important;
        overflow-y: overlay !important;
    }

    &::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb {
        @include border-radius($size);
        background-clip: padding-box;
        border: ($size / 3) solid transparent;
        background-color: $track-background;
    }

    &::-webkit-scrollbar-thumb {
        cursor: pointer;
        min-height: 40px;
        min-width: 40px;
        background-color: $thumb-background;
    }

    &::-webkit-scrollbar-button {
        display: none;
        height: 0;
        width: 0;
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }
    //** Firefox
    scrollbar-base-color: transparent;
    scrollbar-3dlight-color: transparent;
    scrollbar-arrow-color: transparent;
    scrollbar-color: $thumb-background $track-background;
    scrollbar-width: thin;
    //**

    &::-webkit-scrollbar {
        background-color: transparent;

        @if ($staticV and $staticH) {
            height: $size;
            width: $size;
        }
        @else if $staticH {
            height: $size;
            width: 0;
        }
        @else if $staticV {
            height: 0;
            width: $size;
        }
        @else {
            height: 0;
            width: 0;
        }
    }

    
    



    @if not ($staticV and $staticH) {
        &::-webkit-scrollbar-track:hover,
        &::-webkit-scrollbar-track:active
        {
            background-color: rgba($track-background, 0.1);
        }

        &::-webkit-scrollbar-thumb:not(active) {
            display: none;
        }
        &::-webkit-scrollbar-thumb:hover,
        &::-webkit-scrollbar-thumb:active {
            display: block;
        }
        &.scrollbar-visible {
            @if $staticH {
                &::-webkit-scrollbar {
                    width: $size;
                }
            }

            @else if $staticV {
                &::-webkit-scrollbar {
                    height: $size;
                }
            }
            @else {
                &::-webkit-scrollbar {
                    height: $size;
                    width: $size;
                }
            }
        }
    }
    @if( $staticV or $staticH)
    {
        &::-webkit-scrollbar-thumb:not(active) {
            display: block;
        }
    }
}

// Detect Safari
@mixin safari {
    @media not all and (min-resolution:.001dpcm) {
        @supports (-webkit-appearance:none) {
           @content;
        }
    }
}
