﻿.button__switch {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .label {
        font-family: $bst-font-family;
        font-size: 16px;
        font-weight: $font-weight-medium;
        color: $color-utility;
    }

    .e-switch-wrapper {
        margin-left: 12px;
        height: 24px !important;
        width: 48px !important;

        .e-switch-inner.e-switch-active .e-switch-on {
            background-color: $color-blue !important;
            opacity: 1 !important;
        }

        .e-switch-off {
            background: $day-utility-lightest;
            opacity: 1 !important;
        }
        
        .e-switch-handle {
            background: $day-grey-white;
            width: 20px !important;
            height: 20px !important;
            left: 2px;
            margin-left: 0;

            &:before {
                width: 36px;
                height: 36px;
                position: absolute;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%, -50%));
                @include border-radius(50%);
            }

            &:hover,
            &:focus {
                &:before {
                    content: "";
                    background: rgba($day-utility-dark, 0.08);
                }
            }

            &:active {
                &:before {
                    content: "";
                    background: rgba($day-primary-blue, 0.12);
                }
            }

            &.e-switch-active {
                right: 2px;
                background-color: $day-grey-white !important;

                &:hover {
                    &:before {
                        content: "";
                        background: rgba($day-primary-blue, 0.08);
                    }
                }
            }
        }

        .e-ripple-container {
            display: none !important;
        }
    }
}