.nlm-threshold-settings {

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 56px 64px 44px 56px;

    font-size: 40px;
    line-height: 1.1;
    letter-spacing: -1px;
    text-align: left;
    color: $day-text-default;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 56px 64px 44px 56px;

    font-size: 40px;
    line-height: 1.1;
    letter-spacing: -1px;
    text-align: left;
    color: $day-text-default;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 44px;
    margin: 0 72px 0 56px;
    padding: 0;
  }

}
