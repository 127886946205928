﻿.avatar {
    @include border-radius(50%);
    background-color: $color-utility;
    box-sizing: border-box;
    color: $color-gray-white;
    font-size: 14px;
    height: 32px;
    margin: 0 auto;
    overflow: hidden;
    padding: 8px 0;
    text-align: center;
    width: 32px;
    &--xl {
        height: 96px;
        width: 96px;
    }
    &--lg {
        height: 48px;
        width: 48px;
    }
    &--md {
        height: 40px;
        width: 40px;
    }
    &--sm {
        height: 32px;
        width: 32px;
    }
    &--xs {
        height: 24px;
        width: 24px;
    }
    &--dark {
        background-color: $color-primary-dark;
    }
}