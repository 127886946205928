﻿.l-carousel {
    @include transition(height 0.5s ease-in-out);
    background-color: $color-drawer;
    height: 0;
    overflow: auto;
    overflow-y: hidden;
    padding: 0;
    width: 100%;
    &.is-open {
        height: 496px;
    }
}
.l-carousel__header {
    align-items: center;
    display: grid;
    grid-auto-flow: column;
    height: 48px;
    justify-content: stretch;
    left: 0;
    padding: 0 24px;
    position: sticky;
    top: 0;
}
.l-carousel__close {
    justify-self: end;
}