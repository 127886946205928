.flex-container {
    @include flexbox();
    flex-direction: column;
    height: 100%;

    &__inner {
        flex: 1;
        overflow: hidden;
    }

    .l-main {
        height: 100%;
    }
}