﻿.l-subheader {
  z-index: 12;
  background-color: $day-primary-lightest;
  box-sizing: border-box;
  @include flexbox();
  align-items: center;
  min-height: $layout-subheader-height;
  min-width: 0;
  padding: 0 4.5rem;
  position: relative;

  @include mq(xl_down) {
    padding: 0 4rem;
  }

  @include mq(lg_down) {
    min-height: $layout-subheader-height-md;
    padding: 0 2.75rem;

    .icon-button__moreprojectactions {
      transform: none;
    }
  }

  @include mq(md_down) {
    padding: 0 2rem;
  }

  @include mq(sm_down) {
    min-height: $layout-subheader-height-sm;
  }

  &--hidden {
    display: none;
  }

  &--sticky {
    position: relative;
  }
}
.l-subheader__left {
  justify-self: start;
}
.l-subheader__right {
  flex: 1;
  justify-self: end;
  & > .button-group {
    justify-content: end;
  }
}
