.model-run-details {
  &__row {
    gap: 48px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > div {
      flex: 0 0 30%;
    }
  }

  &__form {
    &-label {
      color: $day-utility-utility;
      font-size: 12px;
      font-weight: $font-weight-medium;
    }
  }

  &__skeleton {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 12px;
    padding: 0;
  }
}
