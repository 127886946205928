@import "./bst-icon-font-variables";

@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?ue4br5")
      format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?ue4br5")
      format("woff"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?ue4br5")
      format("woff2"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?ue4br5##{$icomoon-font-family}")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dependency {
  &:before {
    content: $icon-dependency;
  }
}

.icon-activity2 {
  &:before {
    content: $icon-activity2;
  }
}

.icon-add {
  &:before {
    content: $icon-add;
  }
}

.icon-add_small {
  &:before {
    content: $icon-add_small;
  }
}

.icon-add-column {
  &:before {
    content: $icon-add-column;
  }
}

.icon-alert {
  &:before {
    content: $icon-alert;
  }
}

.icon-analytics_solid {
  &:before {
    content: $icon-analytics_solid;
  }
}

.icon-all-apps {
  &:before {
    content: $icon-apps;
  }
}

.icon-inbox {
  &:before {
    content: $icon-house-check;
  }
}

.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}

.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}

.icon-arrow-negative_solid {
  &:before {
    content: $icon-arrow-negative_solid;
  }
}

.icon-arrow-positive_solid {
  &:before {
    content: $icon-arrow-positive_solid;
  }
}

.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}

.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}

.icon-attachment {
  &:before {
    content: $icon-attachment;
  }
}

.icon-back {
  &:before {
    content: $icon-back;
  }
}

.icon-baseline {
  &:before {
    content: $icon-baseline;
  }
}

.icon-bids_solid {
  &:before {
    content: $icon-bids_solid;
  }
}

.icon-billing_solid {
  &:before {
    content: $icon-billing_solid;
  }
}

.icon-blueprint {
  &:before {
    content: $icon-blueprint;
  }
}

.icon-bold {
  &:before {
    content: $icon-bold;
  }
}

.icon-bold_small {
  &:before {
    content: $icon-bold_small;
  }
}

.icon-bst_solid {
  &:before {
    content: $icon-bst_solid;
  }
}

.icon-budget {
  &:before {
    content: $icon-budget;
  }
}

.icon-building-1 {
  &:before {
    content: $icon-building-1;
  }
}

.icon-building-2 {
  &:before {
    content: $icon-building-2;
  }
}

.icon-building-3 {
  &:before {
    content: $icon-building-3;
  }
}

.icon-building-4 {
  &:before {
    content: $icon-building-4;
  }
}

.icon-building-5 {
  &:before {
    content: $icon-building-5;
  }
}

.icon-bullets {
  &:before {
    content: $icon-bullets;
  }
}

.icon-bullets_small {
  &:before {
    content: $icon-bullets;
  }
}

.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}

.icon-camera {
  &:before {
    content: $icon-camera;
  }
}

.icon-cards {
  &:before {
    content: $icon-cards;
  }
}

.icon-change-orders_solid {
  &:before {
    content: $icon-change-orders_solid;
  }
}

.icon-check {
  &:before {
    content: $icon-check;
  }
}

.icon-check_small {
  &:before {
    content: $icon-check_small;
  }
}

.icon-chevron-down {
  &:before {
    content: $icon-chevron-down;
  }
}

.icon-chevron-down_micro {
  &:before {
    content: $icon-chevron-down_micro;
  }
}

.icon-chevron-left {
  &:before {
    content: $icon-chevron-left;
  }
}

.icon-chevron-left_micro {
  &:before {
    content: $icon-chevron-left_micro;
  }
}

.icon-chevron-right {
  &:before {
    content: $icon-chevron-right;
  }
}

.icon-chevron-right_micro {
  &:before {
    content: $icon-chevron-right_micro;
  }
}

.icon-chevron-up {
  &:before {
    content: $icon-chevron-up;
  }
}

.icon-chevron-up_micro {
  &:before {
    content: $icon-chevron-up_micro;
  }
}

.icon-client {
  &:before {
    content: $icon-client;
  }
}

.icon-close {
  &:before {
    content: $icon-close;
  }
}

.icon-close_micro {
  &:before {
    content: $icon-close_micro;
  }
}

.icon-close_small {
  &:before {
    content: $icon-close_small;
  }
}

.icon-company {
  &:before {
    content: $icon-company;
  }
}

.icon-cone {
  &:before {
    content: $icon-cone;
  }
}

.icon-construction_1 {
  &:before {
    content: $icon-construction_1;
  }
}

.icon-construction_2 {
  &:before {
    content: $icon-construction_2;
  }
}

.icon-copy {
  &:before {
    content: $icon-copy;
  }
}

.icon-crop {
  &:before {
    content: $icon-crop;
  }
}

.icon-delete {
  &:before {
    content: $icon-delete;
  }
}

.icon-delivery {
  &:before {
    content: $icon-delivery;
  }
}

.icon-diamond {
  &:before {
    content: $icon-diamond;
  }
}

.icon-display {
  &:before {
    content: $icon-display;
  }
}

.icon-dot_solid {
  &:before {
    content: $icon-dot_solid;
  }
}

.icon-levels-expand {
  &:before {
    content: $icon-double-arrow-down;
  }
}

.icon-levels-collapse {
  &:before {
    content: $icon-double-arrow-up;
  }
}

.icon-download {
  &:before {
    content: $icon-download;
  }
}

.icon-reorder {
  &:before {
    content: $icon-drag;
  }
}

.icon-drag {
  &:before {
    content: $icon-drag-1;
  }
}

.icon-duration {
  &:before {
    content: $icon-duration;
  }
}

.icon-edit {
  &:before {
    content: $icon-edit;
  }
}

.icon-email {
  &:before {
    content: $icon-email;
  }
}

.icon-emoji {
  &:before {
    content: $icon-emoji;
  }
}

.icon-expand {
  &:before {
    content: $icon-expand;
  }
}

.icon-expand_horizontal {
  &:before {
    content: $icon-expand_horizontal;
  }
}

.icon-external {
  &:before {
    content: $icon-external;
  }
}

.icon-FAB-actions .path1 {
  &:before {
    content: $icon-uniE94A;
  }
}

.icon-FAB-actions .path2 {
  &:before {
    content: $icon-uniE94B;
    margin-left: -1em;
  }
}

.icon-filter {
  &:before {
    content: $icon-filter;
  }
}

.icon-flag {
  &:before {
    content: $icon-flag;
  }
}

.icon-flag_solid {
  &:before {
    content: $icon-flag_solid;
  }
}

.icon-forbidden {
  &:before {
    content: $icon-forbidden;
  }
}

.icon-forward {
  &:before {
    content: $icon-forward;
  }
}

.icon-gantt {
  &:before {
    content: $icon-gantt;
  }
}

.icon-general {
  &:before {
    content: $icon-general;
  }
}

.icon-hand {
  &:before {
    content: $icon-hand;
  }
}

.icon-hand_solid {
  &:before {
    content: $icon-hand_solid;
  }
}

.icon-hash {
  &:before {
    content: $icon-hash;
  }
}

.icon-help {
  &:before {
    content: $icon-help;
  }
}

.icon-home {
  &:before {
    content: $icon-home;
  }
}

.icon-house-check {
  &:before {
    content: $icon-house-check;
  }
}

.icon-insights {
  &:before {
    content: $icon-insights;
  }
}

.icon-invoice_solid {
  &:before {
    content: $icon-invoice_solid;
  }
}

.icon-italic {
  &:before {
    content: $icon-italic;
  }
}

.icon-jump-left {
  &:before {
    content: $icon-jump-left;
  }
}

.icon-jump-right {
  &:before {
    content: $icon-jump-right;
  }
}

.icon-language {
  &:before {
    content: $icon-language;
  }
}

.icon-layers {
  &:before {
    content: $icon-layers;
  }
}

.icon-like {
  &:before {
    content: $icon-like;
  }
}

.icon-like_solid {
  &:before {
    content: $icon-like_solid;
  }
}

.icon-link {
  &:before {
    content: $icon-link;
  }
}

.icon-link_small {
  &:before {
    content: $icon-link_small;
  }
}

.icon-link-broken {
  &:before {
    content: $icon-link-broken;
  }
}

.icon-list {
  &:before {
    content: $icon-list;
  }
}

.icon-logout {
  &:before {
    content: $icon-logout;
  }
}

.icon-magic {
  &:before {
    content: $icon-magic;
  }
}

.icon-menu {
  &:before {
    content: $icon-menu;
  }
}

.icon-comments {
  &:before {
    content: $icon-message;
  }
}

.icon-more_solid {
  &:before {
    content: $icon-more_solid;
  }
}

.icon-more-actions {
  &:before {
    content: $icon-more-actions;
  }
}

.icon-notification {
  &:before {
    content: $icon-notification;
  }
}

.icon-opportunities_solid {
  &:before {
    content: $icon-opportunities_solid;
  }
}

.icon-paper-clip {
  &:before {
    content: $icon-paper-clip;
  }
}

.icon-paste {
  &:before {
    content: $icon-paste;
  }
}

.icon-people_solid {
  &:before {
    content: $icon-people_solid;
  }
}

.icon-percent {
  &:before {
    content: $icon-percent;
  }
}

.icon-phone {
  &:before {
    content: $icon-phone;
  }
}

.icon-pin {
  &:before {
    content: $icon-pin;
  }
}

.icon-point-down_solid {
  &:before {
    content: $icon-point-down_solid;
  }
}

.icon-point-up_solid {
  &:before {
    content: $icon-point-up_solid;
  }
}

.icon-pricing {
  &:before {
    content: $icon-pricing;
  }
}

.icon-profile {
  &:before {
    content: $icon-profile;
  }
}

.icon-project {
  &:before {
    content: $icon-project;
  }
}

.icon-projects_solid {
  &:before {
    content: $icon-projects_solid;
  }
}

.icon-rate-history {
  &:before {
    content: $icon-rate-history;
  }
}

.icon-reset {
  &:before {
    content: $icon-reset;
  }
}

.icon-save {
  &:before {
    content: $icon-save;
  }
}

.icon-cut {
  &:before {
    content: $icon-scissors;
  }
}

.icon-score {
  &:before {
    content: $icon-score;
  }
}

.icon-search {
  &:before {
    content: $icon-search;
  }
}

.icon-send {
  &:before {
    content: $icon-send;
  }
}

.icon-settings {
  &:before {
    content: $icon-settings;
  }
}

.icon-shrink {
  &:before {
    content: $icon-shrink;
  }
}

.icon-snooze {
  &:before {
    content: $icon-snooze;
  }
}

.icon-snooze_small {
  &:before {
    content: $icon-snooze_small;
  }
}

.icon-sort {
  &:before {
    content: $icon-sort;
  }
}

.icon-star {
  &:before {
    content: $icon-star;
  }
}

.icon-subtract {
  &:before {
    content: $icon-subtract;
  }
}

.icon-subtract_small {
  &:before {
    content: $icon-subtract_small;
  }
}

.icon-suggestion {
  &:before {
    content: $icon-suggestion;
  }
}

.icon-task {
  &:before {
    content: $icon-task;
  }
}

.icon-tasks_solid {
  &:before {
    content: $icon-tasks_solid;
  }
}

.icon-template {
  &:before {
    content: $icon-template;
  }
}

.icon-time {
  &:before {
    content: $icon-time;
  }
}

.icon-timesheet {
  &:before {
    content: $icon-timesheet;
  }
}

.icon-timesheets_solid {
  &:before {
    content: $icon-timesheets_solid;
  }
}

.icon-underline {
  &:before {
    content: $icon-underline;
  }
}

.icon-underline_small {
  &:before {
    content: $icon-underline;
  }
}

.icon-unlike_solid {
  &:before {
    content: $icon-unlike_solid;
  }
}

.icon-upload {
  &:before {
    content: $icon-upload;
  }
}

.icon-work-type {
  &:before {
    content: $icon-work-type;
  }
}

.icon-wrench {
  &:before {
    content: $icon-wrench;
  }
}

.icon-appsettings {
  &:before {
    content: $icon-appsettings;
  }
}

.icon-adduser {
  &:before {
    content: $icon-adduser;
  }
}

.icon-usersettings {
  &:before {
    content: $icon-usersettings;
  }
}
