﻿/* Box Shadows */
$box-shadow--1: 0 4px 20px 0 rgba(0, 37, 77, 0.08);
$box-shadow-0: 0px 2px 8px rgba(0, 37, 77, 0.12);
$box-shadow-1: 0 8px 16px 0 rgba(12, 42, 75, 0.2);
$box-shadow-2: 0 10px 20px 0 rgba(79, 79, 79, 0.1);
$box-shadow-3: 0px 12px 28px rgba(0, 37, 77, 0.12);
$box-shadow-4: 0 20px 32px 0 rgba(0, 37, 77, 0.12);
$box-shadow-5: 0px 24px 56px rgba(0, 37, 77, 0.16);
$box-shadow-6: 0 -1px 19px 0 rgba(12, 42, 75, 0.1);
$box-shadow-7: 2px 0 20px 0 rgba(55, 78, 91, 0.1);

/* Breakpoints */
$break-xxl: 1900px;
$break-xl: 1600px;
$break-lg: 1280px;
$break-md: 920px;
$break-sm: 720px;
$break-xs: 600px;

/* BST Colors */
$color-blue: #1274E6;
$color-blue-tint: #257FE8;
$color-blue-dark: #0063CC;
$color-blue-darkest: #004FA3;
$color-blue-light: #99CBFF;
$color-blue-lightest: #EBF5FF;
$color-blue-lightest-tint: #F6F9FE;

$color-purple: #A07CE8;
$color-purple-dark: #764FC4;
$color-purple-darkest: #553399; 
$color-purple-light: #BAA0EE;
$color-purple-lightest: #F3F0FA;

$color-green: #14AA51;
$color-green-dark: #0D8930;
$color-green-light: #C8F9DC;
$color-green-lightest: #EDFDF3;

$color-red: #E65722;
$color-red-dark: #CD4B2C;
$color-red-light: #F9D5C8;
$color-red-lightest: #FDF1ED;

$color-orange: #14AA51;
$color-orange-dark: #C67906;
$color-orange-light: #FDE6C4;
$color-orange-lightest: #FEF7EB;

$color-gray: #8F99A3;
$color-gray-dark: #5C6670;
$color-gray-darkest: #40474F;
$color-gray-light: #EEF0F1;
$color-gray-lightest: #FAFAFA;
$color-gray-white: #FFFFFF;

$color-utility: #57799D;
$color-utility-dark: #32475D;
$color-utility-light: #87A2BF;
$color-utility-lightest: #D9E5F2;

/* Theme Colors */
$color-primary: $color-blue;
$color-primary-tint: $color-blue-tint;
$color-primary-dark: $color-blue-dark;
$color-primary-darkest: $color-blue-darkest;
$color-primary-light: $color-blue-light;
$color-primary-lightest: $color-blue-lightest;
$color-primary-lightest-tint: $color-blue-lightest-tint;

$color-accent: $color-purple;
$color-accent-dark: $color-purple-dark;
$color-accent-darkest: $color-purple-dark;
$color-accent-light: $color-purple-light;
$color-accent-lightest: $color-purple-lightest;

$color-text: #00254D;
$color-text-white: #FFFFFF;

$color-gantt: #79A8DB;
$color-drawer: #B9D4F0;

$color-success: $color-green;
$color-error: $color-orange;
$color-warning: $color-red;

/* Font Weights */
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

/* Layout */
$layout-header-height: 76px;
$layout-header-height-md: 64px;
$layout-header-height-sm: 56px;
$layout-subheader-height: 152px;
$layout-subheader-height-md: 108px;
$layout-subheader-height-sm: 92px;

$layout-gantt-header-height: 92px;