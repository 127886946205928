.filter-search__div{
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 0 8px 0 16px;
    border-radius: 28px;
    background: $day-grey-white;
    border: 1px solid transparent;

    &__desktop {
        width: 280px;
    }

    .icon-search {
        color: $day-utility-utility;
        border: none !important;
    }

    &:hover{
        background: $day-primary-lightest-tint;
        
        .filter-search__input,
        .filter-search__input::placeholder {
            color: $day-text-default;
        }
    }

    &:focus-within, &:active, &:focus {
        border: solid 1px $day-utility-light;
        background-color: $day-grey-white;

        .filter-search__input,
        .filter-search__input::placeholder {
            color: $day-text-default;
        }
    }
}

.filter-search__input{
    border: unset !important;
    outline: unset !important;
    height: 44px;
    flex-grow: 0;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: $day-grey-dark;
}

.filter-search__input--is-focused{
   box-shadow: unset !important;
}