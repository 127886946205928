﻿.reference-property-wrap {
    width: 100%;
    max-width: 400px;

    span,
    input {
        border: none !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    > span:after,
    > span:before {
        display: none;
    }

    input {
        font-size: 18px !important;
        font-weight: $font-weight-regular !important;
        font-family: $bst-font-family;
        line-height: 24px !important;
        color: #223555 !important;

        &::selection {
            background: $day-primary-blue !important;
            color: #223555 !important;
        }
    }

    .e-input-group {
        padding-bottom: 5px;

        input {
            padding-bottom: 0 !important;
            font-weight: 500 !important;
        }
    }

    .e-input-group-icon:before {
        font-family: "bst-icon-font" !important;
        content: $icon-chevron-down !important;
        color: $day-utility-utility;
    }
}
