﻿.e-dropdownbase {
    @include scrollbars(rgba($day-grey-white, 0), rgba($day-grey-white, 0.3), $size: 12px, $staticH: false);

    .e-list-parent {
        padding-bottom: 4px;
    }

    .e-list-item {
        background-color: $day-primary-blue;
        color: $day-text-white;
        font-family: 'Open Sans', sans-serif;
        height: 56px;
        line-height: 56px;

        .container {
            float: left;
            width: 100%;
            vertical-align: middle;
        }

        .container-left {
            color: $day-grey-white;
            float: left;
            width: calc(100% - 100px);
            text-overflow: ellipsis;
            overflow: hidden;
            vertical-align: middle;
            font-size: 16px;
        }

        .container-right {
            color: $day-primary-lightest;
            float: right;
            width: 100px;
            text-overflow: ellipsis;
            overflow: hidden;
            vertical-align: middle;
            font-size: 12px;
        }

        &.e-item-focus {
            background-color: $day-primary-tint;
            color: $day-grey-white;
        }
    }

    .e-list-item.e-hover,
    .e-list-item.e-active,
    .e-list-item.e-hover.e-active {
        background-color: $day-primary-tint;
        color: $day-grey-white;
    }
}

.e-ddl {
    border-bottom-color: $day-utility-lightest !important;

    &.e-popup {
        background-color: $day-primary-blue;
        @include border-radius(8px);
        @include box-shadow(0 20px 32px 0 rgba(0, 37, 77, 0.12));
        padding: 8px 4px 8px 4px;
        overflow: hidden;
    }
}
