﻿.multi-section-component {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding-top: 80px;
    background: rgba(0, 37, 77, 0.5);
    z-index: 1001;

    &__inner-wrap {
        background: $day-primary-lightest;
        @include border-radius(16px 16px 0 0);
        overflow: hidden;
        position: relative;
        height: 100%;
    }

    &__header {
        position: relative;
        padding: 20px 92px 20px 32px;
        background: $day-text-white;
        height: 92px;
        border-bottom: 1px solid $day-utility-lightest;
    }

    &__heading {
        @extend .heading-modal;
        color: $day-text-default;
        margin-bottom: 4px;
    }

    &__header-subtext {
        color: $day-utility-utility;
        @extend .label-sm;
        font-weight: 500;
    }

    &__header-button {
        position: absolute;
        right: 24px;
        top: 50%;
        transform: translate(0, -50%);
        padding: 20px;

        .icon {
            font-size: 24px;
            color: $day-utility-utility;
        }
    }

    &__content {
        position: absolute;
        top: 92px;
        left: 0;
        bottom: 0;
        width: 100%;
        @include flexbox;
        flex-direction: row;
    }

    &__sidebar {
        @include flexbox;
        width: 72px;
        background: $day-text-white;
        flex-direction: column;
        padding-top: 64px;

        @include mq(md_up) {
            min-width: 290px;
        }
    }

    &__sidebar-cta {
        @include flexbox;
        text-align: left;
        @extend .label-md;
        color: $day-utility-utility;
        padding: 17px 20px 17px 28px;
        text-decoration: none;
        margin-bottom: 16px;

        span,
        .icon:last-child {
            display: none;
        }

        @include mq(md_up) {
            span,
            .icon:last-child {
                display: block;
            }
        }

        span {
            flex-grow: 1;
            margin-left: 12px;
        }

        .icon {
            font-size: 22px;
        }
    }

    &__main {
        padding: 48px 64px 0;
        flex-grow: 1;
        @include scrollbars($mode: 'v');

        > div {
            padding: 44px 48px;
            margin-bottom: 32px;
            @include border-radius(16px);
            background: $day-text-white;
            @include box-shadow(-1);
        }

        .multi-section-component__block-heading {
            @extend .heading-modal;
            padding-bottom: 36px;
        }

        .multi-section-component__block-subheading {
            padding: 24px 0;
        }
    }
}
