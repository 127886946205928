﻿.e-dlg-container {
    .e-dlg-modal {
        @include scrollbars($mode: 'v');
        width: calc(100% - 64px) !important;
        max-width: 740px;
        margin: 0 32px;

        &.content-center {
            text-align: center;
            padding: 86px 0 96px;

            .heading-xs {
                color: $day-utility-utility;
                font-family: $bst-font-family;
            }
        }

        .e-dlg-header-content {
            border-bottom: 1px solid $color-utility-lightest;
            padding: 30px 32px;
        }

        .e-dlg-header,
        .modal-content-heading {
            @extend .heading-modal;
            color: $color-text;
            font-family: $bst-font-family;
        }

        .modal-content-text {
            font-weight: $font-weight-light;
        }

        .e-primary {
            @extend .button;

            &:hover {
                color: $color-text-white;
            }
        }
    }

    &.e-dlg-right-center,
    &.e-dlg-left-center {
        .e-dlg-modal {
            width: calc(100% - 32px) !important;
        }
    }

    &.e-dlg-left-center {
        .e-dlg-modal {
            margin: 0 32px 0 0;
        }
    }

    &.e-dlg-right-center {
        .e-dlg-modal {
            margin: 0 0 0 32px;
        }
    }

    &.e-dlg-right-bottom,
    &.e-dlg-left-bottom,
    &.e-dlg-center-bottom {
        .e-dlg-modal {
            @include border-radius(20px 20px 0 0);
        }
    }

    &.e-dlg-right-top,
    &.e-dlg-left-top,
    &.e-dlg-center-top {
        .e-dlg-modal {
            @include border-radius(0 0 20px 20px);
        }
    }

    &.e-dlg-center-center {
        .e-dlg-modal {
            @include border-radius(20px);
        }
    }

    &.e-dlg-left-center {
        .e-dlg-modal {
            @include border-radius(0 20px 20px 0);
        }
    }

    &.e-dlg-right-center {
        .e-dlg-modal {
            @include border-radius(20px 0 0 20px);
        }
    }

    @media only screen and (min-width: $md) {
        &.e-dlg-right-bottom,
        &.e-dlg-right-top {
            .e-dlg-modal {
                margin-right: 64px;
            }
        }

        &.e-dlg-left-bottom,
        &.e-dlg-left-top {
            .e-dlg-modal {
                margin-left: 64px;
            }
        }
    }
}
.e-grid .e-dialog.e-checkboxfilter .e-dlg-content, .e-grid .e-dialog.e-flmenu .e-dlg-content {
    border-radius: 8px 8px 8px 8px;
    background-color: $color-blue;
    display:block;
    flex-direction: row;
    align-items: center;
    padding: 16px !important;
    gap: 100px;
    &::before{
        width: 42px;
        height: 24px;
        content: "Filter";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 200px;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;

    }

    .e-checkfltrnmdiv {
        color: $day-primary-lightest;
    }

    .e-searchbox {
        ::placeholder {
           color: #FFFFFF;
        }
       
        background-color:$color-blue-dark;
        border-radius: 8px;
        padding: 8px 16px !important;
        color: $day-primary-lightest !important;
        margin-block-start: 16px;
        &::before,
        &::after {
            content: none;
            background: transparent !important;
        }   
    }

    .e-checkboxlist {

        &::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none;
        scrollbar-width: none;

        .e-ftrchk {
            padding: 16px 0 !important;

            &.e-chkfocus {
                background: transparent !important;
            }
        }

        .e-label {
            color: $day-text-white !important;
            font-family: $bst-font-secondary;
            font-weight: 300;
            font-size: 16px;
            line-height: 20px;
            margin-left: 14px;
        }

        .e-checkbox-wrapper {

            span.e-frame.e-icons.e-uncheck,
            span.e-frame.e-icons.e-selectall {
                border-color: $color-gray-white !important;
                background-color: transparent !important;
            }

            span.e-frame.e-icons.e-check {
                border-color: $color-gray-white !important;

                &::before {
                    color: $color-blue !important;
                }

                background-color: $color-gray-white !important;
            }
        }
    }

   
    .e-input-group {
        border: none !important;
        margin: 0 !important;
        display: flex;
        flex-direction: row-reverse;

        &::before, &::after{
            content: none !important;
        }

        &.e-input-focus.e-valid-input {
            flex-direction: row
        }

        .e-searchinput {
            color: $color-blue-lightest !important;
            font-family: $bst-font-secondary;
            font-weight: 300;
            font-size: 16px !important;
            line-height: 20px !important;
            margin-left: 12px !important;
        }

        .e-icons, .e-icons:hover {
            font-size: 17px !important;
            color: $color-text-white !important;
        }
    }
}

.e-checkboxfilter.e-filter-popup{
     .e-footer-content {
        display: none !important;
    }

}