﻿.logout-page {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url('../../img/logout.svg') no-repeat center top, linear-gradient(to bottom, #1274e6, #1274e6 65%, #1f5bcc 65%, #1271e6 90%);
    background-size: auto 80%, auto auto;

    .logout-page__content-wrapper {
        position: absolute;
        left: 0;
        top: 70px;
        bottom: 30px;
        width: 100%;
        @include flexbox;
        flex-direction: column;
        justify-content: space-between;
        color: $color-gray-white;
        padding: 0 40px;
    }

    .logout-page__heading {
        font-size: 28px;
        font-weight: $font-weight-light;
        line-height: 32px;

        @include mq(sm_up) {
            font-size: 40px;
            line-height: 44px;
        }
    }

    .logout-page__text {
        font-size: 18px;
        line-height: 28px;
        margin: 16px 0 32px;
    }

    .logout-page__button {
        border: 1px solid $color-gray-white;
        color: $color-gray-white;
        background: transparent;
    }

    @include mq(sm_up) {
        background-size: auto 70%, auto auto;
        background-position: top 35% right;

        .logout-page__content-wrapper {
            left: 10%;
            top: 200px;
            width: 90%;
            bottom: auto;
        }

        .logout-page__button {
            max-width: 160px;
        }
    }

    @include mq(lg_up) {
        background: url('../../img/logout.svg') no-repeat right 20% top, linear-gradient(to bottom, #1274e6, #1274e6 70%, #1f5bcc 70%, #1271e6 90%);
        background-size: auto 85%, auto auto;
    }
}
