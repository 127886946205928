﻿.l-header {
    @include box-shadow(-1);
    align-items: center;
    background-color: $day-grey-white;
    color: $day-utility-dark;
    display: flex;
    justify-content: space-between;
    height: $layout-header-height;
    margin: 0;
    position: sticky;
    z-index: 1000;


    @include mq(md_down) {
        height: $layout-header-height-md;

        .global-utilities__button {
            height: $layout-header-height-md
        }
    }
    @include mq(sm_down) {
        height: $layout-header-height-sm;

        .global-utilities__button {
            height: $layout-header-height-sm
         }
    }  
}

.l-header__left {
    justify-self: start;
 }
.l-header__right {
    justify-self: end;
 }