.pbi-cards__report {
  height: 720px;
}

.pbi-details__report {
  height: 1000px;
  background-color: $day-primary-lightest-tint;
}

.pbi-details {
  width: 100%;
  height: 100.5%;
  position: relative;
  background-color: $day-primary-lightest-tint;

  &-modal {
    top: 60px;
  }

  &__skeleton_container {
    z-index: 3;
    position: absolute;
  }

  &__skeleton_div {
    background-color: #ffff;
    border-radius: 8px;
    height: 152px;

    @include mq(lg_down) {
      width: calc(100% - 64px);
      margin: 32px 32px;
    }
    @include mq(sm_down) {
      width: calc(100% - 48px);
      margin: 24px 24px;
    }
    @include mq(xs_down) {
      width: calc(100% - 32px);
      margin: 44px 16px;
    }
  }
  &__skeleton {
    width: 100%;
    height: 366px;
    @include animation(backgroundPosition, 2s, linear, 0s, infinite);

    &_rectangle1 {
      x: 32;
      y: 35;
      rx: 8;
      ry: 8;
      width: 98px;
      height: 16px;
      background: $day-primary-lightest-tint
        linear-gradient(
          to right,
          rgba($day-utility-lightest, 0) 0%,
          rgba($day-utility-lightest, 0.16) 25%,
          $day-utility-lightest 50%,
          rgba($day-utility-lightest, 0.16) 75%,
          rgba($day-utility-lightest, 0) 100%
        );
    }
    &_rectangle2 {
      x: 32;
      y: 59;
      rx: 8;
      ry: 8;
      width: 283px;
      height: 28px;
      background: linear-gradient(
        79deg,
        rgba(196, 210, 224, 0) 45%,
        rgba(196, 210, 224, 0.16) 61%,
        #c4d2e0 77%,
        rgba(196, 210, 224, 0.16) 96%,
        rgba(196, 210, 224, 0) 110%
      );
    }
    &_rectangle3 {
      x: 32;
      y: 95;
      rx: 48;
      ry: 48;
      width: 32px;
      height: 32px;
    }
    &_rectangle4 {
      x: 76;
      y: 105;
      rx: 8;
      ry: 8;
      width: 98px;
      height: 12px;
    }
    &_rectangle5 {
      x: 500;
      y: 47;
      rx: 8;
      ry: 8;
      width: 925px;
      height: 52px;
    }
    &_rectangle6 {
      x: 32;
      y: 50;
      rx: 8;
      ry: 8;
      width: 436px;
      height: 360px;
    }
    &_rectangle7 {
      x: 500;
      y: 50;
      rx: 8;
      ry: 8;
      width: 925px;
      height: 360px;
    }

    @include mq(md_down) {
      width: 100%;
      height: 366px;

      &_rectangle1 {
        x: 24;
        y: 24;
        rx: 8;
        ry: 8;
        width: 98px;
        height: 16px;
      }
      &_rectangle2 {
        x: 24;
        y: 48;
        rx: 8;
        ry: 8;
        width: 348px;
        height: 28px;
      }
      &_rectangle3 {
        x: 24;
        y: 84;
        rx: 48;
        ry: 48;
        width: 32px;
        height: 32px;
      }
      &_rectangle4 {
        x: 68;
        y: 94;
        rx: 8;
        ry: 8;
        width: 98px;
        height: 12px;
      }
      &_rectangle5 {
        x: 24;
        y: 128;
        rx: 8;
        ry: 8;
        width: calc(100% - 48px);
        height: 140px;
      }
      &_rectangle6 {
        x: 0;
        y: 284;
        rx: 8;
        ry: 8;
        width: 100%;
        height: 84px;
      }
      &_rectangle7 {
        display: none;
      }
    }
  }

  &__chart-details {
    top: 0px;
    height: 640px;
    position: sticky;
  }

  &__key-factors {
    top: 0;
    width: 100%;
    height: 400px;
    position: relative;
    transition: all 0.8s ease;
  }

  &__project-info-background-layer {
    background-color: $day-grey-white;
    width: 100%;
    z-index: 1;
    position: absolute;
  }

  div[data-testid="embeddedReport"],
  .report-container {
    height: 100%;
    z-index: 2;
    position: relative;
  }

  &__close-button {
    top: 30px;
    right: 32px;
    z-index: 3;
    position: absolute;

    .icon:before {
      line-height: 30px;
    }
  }
}
