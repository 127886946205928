.filter-component {
    @include flexbox();
    flex-direction: column;
    flex-grow: 1;
    background: $day-grey-white;
    width: 324px;
    @include box-shadow($box-shadow-7);
    position: absolute;
    left: -100%;
    margin-top: 12px;
    height: 100%;
    border-radius: 8px;

    @include mq(md_down) {
        position: fixed;
        top: 0px;
        bottom: 0;
        height: auto;
        width: 100%;
        z-index: 1005;
        margin: 0;

        &--mobile {
            top: 0;
            width: 100%;
            z-index: 1001;
        }
    }
    
    &.is-active{
        left: 0;
    }

    &__header {
        position: relative;
        @include flexbox();
        flex-direction: row;
        min-height: 132px;
        border-bottom: 1px solid $day-utility-lightest;
        border-radius: 8px;
    }

    &__close {
        position: absolute;
        top: 4px;
        right: 4px;
    }

    &__heading-wrap {
        align-self: flex-end;
        width: 100%;
        padding: 24px 4px 24px 24px;
        @include flexbox();
        flex-direction: row;
        justify-content: space-between;
        min-height: 84px;
    }

    &__heading {
        font-size: 32px;
        line-height: 1.13;
        font-weight: 300;
        color: $day-text-default;
    }

    &__clear-all {
        width: auto;
        @include border-radius(20px);
        color: $day-text-default !important;
        font-size: 16px;
        @include flexbox();
        padding: 8px 12px;

        .icon {
            color: $day-utility-utility !important;
            font-size: 18px;
            margin-right: 8px;
        }

        &:hover {
            background: rgba($day-primary-blue, 0.08);
        }

        &:active {
            background: rgba($day-primary-blue, 0.24);
        }
    }

    &__filters-wrap {
        flex-grow: 1;
        height: 100%;
    }

    &__filters-list {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;
        margin: 0;
        height: 100%;
        overflow: auto;
        @include scrollbars(rgba($day-utility-dark, 0), rgba($day-utility-dark, 0.3), $staticV:false, $staticH:false, $mode: 'v', $size: 24px);
    }

    &__empty-div{
        padding: 115px 0px;
    }

    &__no-results{
        display: flex;
        flex-grow: 0;
        font-family: "Open Sans";
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;  
        height: 175px !important;
        margin: 24px 0 0;
        padding: 0 24px 8px 42px;
    }

    &__item {
        position: relative;
        background: $day-grey-white;
        padding: 0 24px;

        &:after {
            display: block;
            font-family: $icomoon-font-family;
            color: $day-utility-utility;
            content: "\e928";
            position: absolute;
            right: 24px;
            top: 28px;
            font-size: 12px;
            z-index: 0;
        }

        &:hover {
            background: $day-primary-lightest-tint;
        }

        &:active {
            background: $day-utility-lightest;
        }

        &.is-active {
            background: $day-grey-light;
            padding-bottom: 24px;

            .filter-component__item-content--input-field {
                min-height: 58px;
            }

            .filter-component__item-content,
            .filter-component__item-content-wrap {
                @include flexbox();
            }

            .filter-component__item-content--indented-input {
                display: block;
            }
        }

        &.has-value,
        &.is-active {
            .filter-component__item-header {
                color: $day-primary-dark;
            }
        }

        &:not(.is-active) {
            .filter-tag:nth-last-child(2) {
                margin-bottom: 24px;
            }
        }
    }

    &__item-header {
        @include flexbox();
        flex-direction: row;
        color: $day-utility-utility;
        position: relative;
        z-index: 1;
        cursor: pointer;
        padding: 24px;
        margin: 0 -24px;
        width: calc(100% + 48px);

        .icon {
            margin-right: 12px;
            font-size: 22px;
        }

        h3 {
            font-weight: $font-weight-medium;
            align-self: center;
        }
    }

    &__item-content-wrap {
        display: none;

        .filter-component__item-content-separator {
            margin: 0 8px;
            align-self: center;
            color: $day-text-default;
            font-family: $bst-font-secondary;
        }
    }

    &__item-content {
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        background: $day-grey-white;
        padding: 16px;
        @include border-radius(8px);
        border: 1px solid transparent;
        display: none;
        align-items: center;

        .icon-search {
            font-size: 18px;
            color: $day-utility-utility;
            margin-right: 16px;
        }

        input {
            flex-grow: 1;
            border: none;
            outline: none;
            @extend .label-md;
            font-family: $bst-font-secondary;
            font-weight: $font-weight-light;
            color: $day-text-default;
            height: 24px;
        }

        .icon-close {
            font-size: 20px;
            color: $day-utility-dark;
        }

        &:not(.filter-component__item-content--date):not(.filter-component__item-content--property) {
            &:hover {
                background: $day-primary-lightest-tint;
            }

            &:focus-within {
                background: $day-grey-white;
                border: 1px solid $day-utility-light;
            }
        }

        &--property {
            background: transparent;
            border: none;
            padding: 0;
        }

        &--input-field {
            padding: 12px 0 12px 16px;

            .icon-button {
                padding: 0;
                margin-right: 4px;
            }
        }

        &--date {
            padding: 0;
            background: transparent;
            justify-content: flex-start;

            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }

        &--indented-input {
            display: none;
            padding-left: 40px;
            font-size: 12px;
            line-height: 1;

            > div:first-child {
                text-indent: 16px;
                color: $day-utility-utility;
            }

            &:not(:last-child) {
                margin: 0 0 24px;
            }

            div {
                margin-top: 4px;
            }

            .filter-component__item-content {
                padding: 13px 16px;
                flex-direction: row-reverse;
            }

            .bst-autocomplete__input-icon {
                font-size: 18px !important;
                right: 12px;
                color: $day-utility-dark;
                @include transform(translate(0, -50%));
                z-index: 3;
            }
        }
    }

    &__item-content--date,
    .column-chooser {
        .filters-checkbox-wrap {
            position: relative;
            cursor: pointer;
            margin: 0 8px 0 0;
            min-width: 32px;
            height: 32px;
            align-self: center;
            border-radius: 50%;

            &:hover {
                background: rgba($day-primary-blue, 0.08);
            }

            &:active {
                background: rgba($day-primary-blue, 0.12);
            }

            input {
                visibility: hidden;

                &:before {
                    content: "";
                    border: 2px solid $day-utility-utility;
                    width: 20px;
                    height: 20px;
                    @include border-radius(4px);
                    box-sizing: border-box;
                }

                &:checked {
                    &:before {
                        border-color: $day-primary-blue;
                        background: $day-primary-blue;
                    }

                    &:after {
                        font-family: '#{$icomoon-font-family}' !important;
                        content: $icon-check;
                        color: $day-grey-white;
                        font-weight: $font-weight-semibold;
                        font-size: 14px;
                    }
                }

                &:before,
                &:checked:after {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    @include transform(translate(-50%, -50%));
                    visibility: visible;
                    cursor: pointer;
                }
            }

            & + div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                align-self: center;
            }
        }

        label {
            @include flexbox();
            margin-bottom: 16px;
            font-family: $bst-font-secondary;
        }
    }

    .column-chooser {
        width: 100%;
    }

    .cb-searchbox {
        box-sizing: border-box;
        @include flexbox;
        min-height: 58px;
        align-items: center;
        background: $day-grey-white;
        padding: 4px 8px 4px 16px;
        @include border-radius(8px);
        margin-bottom: 24px;
        border: 1px solid transparent;

        &:hover {
            background: $day-primary-lightest-tint;
        }

        &:focus-within {
            background: $day-grey-white;
            border: 1px solid $day-utility-light;
        }

        &::before {
            font-family: '#{$icomoon-font-family}';
            content: '#{$icon-search}';
            padding-right: 16px;
            font-size: 18px;
            color: $day-utility-utility;
        }

        input {
            @extend .label-md;
            font-weight: $font-weight-light;
            color: $day-text-default;
            font-family: $bst-font-secondary;
        }

        button {
            padding: 0;
            margin-left: -4px;
        }
    }

    .cb-listbox {
        height: 175px !important;
        padding-right: 5px;
        @include scrollbars(rgba($day-grey-darkest, 0), rgba($day-grey-darkest, 0.3), $size: 12px, $staticH: false);
    }

    &__mobile-button {
        padding: 12px 20px;
        @include box-shadow($box-shadow--1);

        button {
            width: 100%;
        }
    }

    .filter-tag {
        display: inline-flex;
        align-items: center;
        padding: 0 4px 0 12px;
        border-radius: 26px;
        background: $day-primary-blue;
        color: $day-grey-white;
        white-space: nowrap;
        font-size: 14px;
        margin: 0 12px 8px 0;

        &__button {
            margin-left: 12px;
        }

        .icon {
            font-size: 14px;
        }

        + .filter-component__item-content--property {
            margin-top: 20px;
        }
    }
}
.filter-chip__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    border-radius: 26px;
    background-color: $day-primary-blue;
    max-width: 240px;
}
.filter-chip__label {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: $day-grey-white;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}
.filter-chip__icon-button {
    border-radius: 50%;
    color: $day-grey-white;
    display: inline-block;
    font-size: 14px;
    line-height: 1;
}
.filter-range__input {
    height: 52px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 4px 8px 4px 16px;
    border-radius: 8px;
    background-color: $day-grey-white;
    border: 1px solid transparent;
    outline: none;
    margin-bottom: 24px;

    &:hover{
        background-color: $day-primary-lightest-tint;
    }
    &:focus{
        border: solid 1px $day-utility-light;
        background-color: $day-grey-white;
    }
}
.filter-range__label {
    margin: 0 193px 4px 0;
    font-family: $bst-default-font-family;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    padding-left: 15px;
    padding-bottom: 4px;
    color: $day-utility-utility;
}
.filter-range__wrapper{
    height: 192px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 24px 0 64px;
}
.filter-accordion{
    &__header{
        display: grid;        
    }
    &__header-wrapper{
        height: 72px;
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        padding: 24px;
        text-align: left;

        &:hover {
            background: rgba($day-primary-blue, 0.08);
        }

        &:active {
            background: rgba($day-primary-blue, 0.24);
        }
    }
    .accordion-button[aria-expanded="true"] {
        .filter-accordion__header-wrapper {
            height: 48px;
            padding: 0 24px;
            margin: 12px 0;
        }
    }
    &__header-label{
        display: flex;
        flex-direction: row;
        gap: 12px;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
        align-items: center;
    }
    
    &__header-icon {
        font-size: 24px;
    }
    &__item{border:unset !important;}

    &__body{

        &.collapse.show {
            background-color:$day-grey-light;
        }
    }

    .accordion-button {
        color:$day-utility-utility;
        padding:0;

        .chip {
            padding: 0 4px 0 12px;
        }
    }
    .accordion-button:collapsed{
        color: $day-utility-utility;
    }
    .accordion-button:not(.collapsed) {
        background-color:$day-grey-light !important;
        box-shadow: unset !important;
        color:  $day-primary-dark !important;

        & .icon-chevron-down {
            @include transform(rotate(180deg));
        }
        
    }

    .checkboxList {
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
        margin: 24px 0 0;
        padding: 0 24px 8px 24px;
        height: 175px !important;
        overflow: auto;
        width: 95%;
        @include scrollbars(rgba($day-grey-darkest, 0), rgba($day-grey-darkest, 0.3), $size: 12px, $staticV:true, $staticH: false);

        div {
            display: flex;
            align-items: center;
        }

        span:not(.text-tooltip) {
            font-family: 'Open Sans';
            font-size: 16px;
            text-align: left;
            color: $day-text-default;
            text-overflow: ellipsis;
            white-space: nowrap;
            align-items: center;
            overflow: hidden;
            max-width: 215px;
        }
        &-checkbox {
            @include appearance(none);
            outline: none;
            box-sizing: border-box;
            overflow: visible !important;
            width: 20px;
            height: 20px;
            border: 2px solid $day-utility-utility;
            @include border-radius(4px);
            position: relative;

            &:checked {
                border-color: $day-primary-blue;
                background-color: $day-primary-blue;

                &::before {
                    font-family: '#{$icomoon-font-family}';
                    content: '#{$icon-check}';
                    margin: 0 0 1px 1px;
                    font-weight: $font-weight-bold;
                    color: $day-grey-white;
                    position: absolute;
                    top: 1px;
                }
            }

            &::after {
                content: '';
                position: absolute;
                transform: translate(0, 0);
                top: -8px;
                left: -8px;
                width: 32px;
                height: 32px;
                @include border-radius(50%);
                background: $day-primary-blue;
                opacity: 0;
            }

            &:hover, &:focus-within {
                &::after {
                    opacity: 0.08;
                }
            }

            &:active {
                &::after {
                    opacity: 0.12;
                }
            }

            &:disabled {
                border-color: $day-utility-light;

                &::after {
                    display: none;
                }

                &:checked {
                    border-color: $day-utility-light;
                    background-color: $day-utility-light;
                }
            }
        }
    }
    .filter-search__div {
        height: 52px;
        flex-grow: 0;
        padding: 4px 8px 4px 16px;
        margin: 0 24px;
        border-radius: 8px;
    }
    .filter-search__input {
        min-width: unset;
        width: 100%;
    }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }
    
        /* Firefox */
        input[type=number] {
        -moz-appearance: textfield;
        appearance: textfield;
        }
}

.filter-accordion {
    .chip {
        position: relative;
    }
}