.dropdown-profile-menu-wrapper{
    padding: 8px 0px;
    background-color: $day-grey-white;
    border-radius: 0px 0px 8px 8px;
    width: 260px;
}
.dropdown-menu-wrapper .dropdown-item {
    gap:16px;
}

.dropdown-menu.show {
    display: block;
}
.dropdown-menu {
    display: none;
    border: unset;
}

.dropdown {
    opacity: unset;
    position: unset;
    width: unset;
    margin: unset;
    padding: unset;
}

.dropdown-profile-menu-icon {
    box-shadow: unset;
    @include border-radius(0);
}


[data-letters]:before {
    content: attr(data-letters);
    display: inline-block;
    font-size: 1em;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    text-align: center;
    border-radius: 50%;
    background: $day-utility-utility;
    vertical-align: middle;
    margin-right: 1em;
    color: $day-grey-white;
}