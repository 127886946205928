﻿.icon-button {
    @include border-radius(50%);
    background-color: $day-accent-purple;
    color: $day-grey-white;
    display: flex;
    align-items: center;
    font-size: 1rem;
    height: 2rem;
    outline: none;
    padding: .475rem;
    position: relative;
    text-align: center;
    min-width: 2rem;
    width: 2rem;
    box-sizing: border-box;

    &:hover {
        background-color: $day-accent-dark;
        border-color: $day-accent-dark;
    }

    &:active {
        background-color: $day-accent-dark;
        border-color: $day-accent-dark;
        color: $day-grey-white;
    }

    &.is-active {
        background-color: $day-accent-dark;
        border-color: $day-accent-dark;
    }

    &.has-notification::before {
        @include border-radius(50%);
        content: '';
        background-color: $day-primary-blue;
        border: 2px solid $day-grey-white;
        display: block;
        height: 12px;
        position: absolute;
        right: -4px;
        top: -4px;
        width: 12px;

        @include mq(lg_down) {
            top: -3px;
        }
    }

    &--floating {
        @include box-shadow(1);
        z-index: 4;

        &.right {
            position: absolute;
            right: 1rem;

            &.top {
                bottom: -6.25rem;
            }
        }

        &:active, &:hover, &.is-active {
            background-color: $day-accent-dark;
        }

        &.is-active {
            color: $day-accent-purple;
        }
    }

    &:disabled, &.disabled {
        background-color: $day-grey-white !important;
        border-color: $day-utility-lightest !important;
        color: rgba($day-grey-grey, 0.3) !important;
        cursor: text;
    }

    &--primary {
        background-color: $day-primary-blue;
        border-color: $day-primary-blue;
        color: $day-primary-lightest;

        &:hover {
            background-color: $day-primary-dark;
            border-color: $day-primary-dark;
            color: $day-primary-light;
        }

        &:active {
            background-color: $day-primary-darkest;
            border-color: $day-primary-darkest;
            color: $day-primary-tint;
        }

        &.is-active {
            background-color: $day-primary-blue;
            border-color: $day-primary-blue;
            color: $day-primary-light;
        }
    }

    &--secondary {
        background-color: $day-grey-white;
        border: 1px solid $day-utility-lightest;
        color: $day-utility-utility;

        &:active {
            background-color: $day-utility-light;
            color: $day-primary-blue;
            border-color: $day-utility-lightest;
        }

        &.is-active {
            background-color: $day-primary-lightest;
            color: $day-primary-blue;
            border-color: $day-primary-blue;
        }

        &:hover, &:focus-within {
            color: $day-primary-blue;
            background-color: $day-utility-lightest;
            border-color: $day-utility-light;
        }

        &:disabled {
            background-color: $day-grey-white;
            color: $day-grey-grey;
            border-color: $day-utility-lightest;
        }
    }

    &--ghost {
        color: $day-utility-utility;
        background-color: transparent;

        &:hover, &:focus-within {
            color: $day-utility-dark;
            background-color: rgba($day-utility-dark, 0.08);
        }

        &:active {
            color: $day-primary-blue;
            background-color: rgba($day-primary-blue, 0.16);
        }

        &.is-active {
            color: $day-primary-blue;
            background-color: rgba($day-primary-blue, 0.08);
        }
    }

    &--ghost-inverse {
        background-color: transparent;
        border-color: transparent;
        color: $day-grey-white;

        &:hover {
            background-color: $day-primary-dark;
            border-color: $day-primary-dark;
            color: $day-text-white;
        }

        &:active {
            background-color: $day-primary-darkest;
            border-color: $day-primary-darkest;
            color: $day-grey-white;
        }

        &.is-active {
            background-color: transparent;
            border-color: transparent;
            color: $day-grey-white;
        }
    }

    &--ghost-dark {
        background-color: transparent;
        border-color: transparent;
        color: $day-grey-white;

        &:hover, &:focus {
            background-color: rgba($day-grey-white, 0.08);
            border-color: transparent;
            color: $day-grey-white;
        }

        &:active {
            background-color: rgba($day-grey-white, 0.12);
            border-color: transparent;
            color: $day-grey-white;
        }

        &.is-active {
            background-color: transparent;
            border-color: $day-primary-lightest;
            color: $day-primary-lightest;
        }
    }

    &--ghost-sm {
        height: 24px;
        line-height: 24px;
        width: 24px;
        min-width: 24px;
        font-size: 14px;
        padding: 5px;
    }

    &--ghost-xs {
        height: 20px;
        width: 20px;
        min-width: 20px;
        font-size: 12px;
        padding: 4px;
    }

    &--xl {
        height: 52px;
        font-size: 24px;
        padding: 13px;
        width: 52px;
    }

    &--lg {
        height: 36px;
        font-size: 20px;
        padding: 7px;
        width: 36px;
    }

    &--md {
        height: 32px;
        font-size: 18px;
        width: 32px;
    }

    &--sm {
        height: 28px;
        font-size: 16px;
        padding: 5px;
        width: 28px;
        min-width: 28px;
    }

    &--xs {
        height: 20px;
        font-size: 8px;
        padding: 5px;
        width: 20px;
        min-width: 20px;
    }

    &.levels {
        position: relative;
        margin-left: -32px;
        font-size: 20px;
        padding: 6px;
        left: 24px;
        z-index: 11;
        border: none;

        .icon {
            display: inline-block;
            @include transition(transform 300ms ease-out);
        }

        &.is-active .icon {
            transform: rotate(180deg);
        }
    }

    &--deletedependency {
        position: absolute;
        z-index: 6;
        left: 8px;
        right: unset;
        top: 4px;
        @include flexbox();
        align-items: center;
        justify-content: center;
        min-width: 0 !important;
        padding: 0 !important;
        font-size: 14px !important;
        font-weight: $font-weight-semibold;
        width: 24px;
        height: 24px;
        background-color: $day-grey-white;
        color: $day-red-red;
        border: 1px solid currentColor !important;
        pointer-events: auto;

        .icon {
            position: relative;
            z-index: 1;
            pointer-events: none !important;
        }

        &.right {
            left: unset;
            right: 8px;
        }

        &:hover {
            background-color: $day-red-red;
            color: $day-grey-white;
        }
    }
}

.icon-button__notification {
    @include transform(translateX(-50%));
    display: block;
    font-size: 10px;
    left: 50%;
    margin: -6px 0 0 0;
    position: absolute;
    top: 100%;
}

.icon-button__moreprojectactions {
    @extend .icon-button, .icon-button--ghost;
    padding: 2px 0 0 0 !important;
    @include flexbox();
    align-content: center;
    justify-content: center;
    align-items: center;
    transform: translateY(4px);
}
