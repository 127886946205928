.dropdown--view {
    background: unset;
    box-shadow: unset;
    padding: 0;

    .dropdown__items-wrap {
        padding: 8px 0px;


        .dropdown__item {
            box-sizing: border-box;
            color: $day-grey-white;
            flex-grow: 1;
            font-family: "Open Sans";
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: left;
            height: 56px;
            padding: 16px;
            gap: 16px;

            &:hover {
                background-color: $day-primary-tint;
            }

            &:active,
            &:focus {
                background-color: $day-primary-dark !important;
            }
            &.is-active {
                font-weight: $font-weight-semibold;
                line-height: 1.5;
                padding: 18px 16px;

            }
        }
    }

    .dropdown__menu {
        background-color: $day-primary-blue;
    }

    .dropdown__button {
        position: static;
    }
}
