
.chip-group {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    padding: 0 16px 0 24px;
    margin-bottom: 24px;
}
.chip {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  border-radius: 26px;
  background-color: $day-primary-blue;
}