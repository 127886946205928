﻿.service {
    background-color: $color-primary-lightest;
    position: relative;
    min-height: calc(100vh - #{$layout-header-height});
}
.service__header {
    background-color: $color-drawer;
    padding: 0 8px;
    position: sticky;
    top: 0;
}
.service__heading {
    font-size: 16px;
    font-weight: $font-weight-regular;
    margin: 16px;
}
.service__body {
    padding: 8px 8px 80px;
}
.service__footer {
    bottom: 0;
    padding: 16px;
    position: absolute;
    text-align: right;
    width: 100%;
}