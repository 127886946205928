﻿.heading-xxl {
    font-size: 40px;
    font-weight: $font-weight-light;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include mq(lg_down) {
        font-size: 2rem;
    }
    @include mq(sm_down) {
        font-size: 1.75rem;
    }
}
.heading-xl {
    font-size: 32px;
    font-weight: $font-weight-light;
    @include mq(sm) {
        font-size: 28px;
    }
    @include mq(xs_down) {
        font-size: 22px;
    }
}
.heading-modal {
    font-size: 28px;
    font-weight: $font-weight-light;
}
.heading-lg {
    font-size: 1.75rem;
    font-weight: $font-weight-light;

    @include mq(lg_down) {
        font-size: 22px;
    }

    @include mq(md_down) {
        font-size: 1.5rem;
    }
}
.heading-md {
    font-size: 24px;
    font-weight: $font-weight-light;
    @include mq(sm) {
        font-size: 22px;
    }
    @include mq(xs_down) {
        font-size: 20px;
    }
}
.heading-sm {
    font-size: 20px;
    font-weight: $font-weight-regular;
}
.heading-xs {
    font-size: 18px;
    font-weight: $font-weight-regular;
}
.label-md {
    font-size: 16px;
    font-weight: $font-weight-semibold;
}
.label-sm {
    font-size: 14px;
    font-weight: $font-weight-semibold;
}
.label-xs {
    font-size: 12px;
    font-weight: $font-weight-semibold;
}
.lead {
    font-size: 18px;
    @include mq(sm_down) {
        font-size: 14px;
    }
}
.body {
    font-family: $bst-font-secondary;
}
.body-sm {
    font-family: $bst-font-secondary;
    font-size: 14px;
}