﻿.vertical-toolbar {
    position: absolute;
    padding-left: 1.5rem;
    padding-top: .25rem;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 0;
    }

    @include mq(lg_down) {
        position: relative;
        padding-left: 1rem;
    }

    @include mq(md_down) {
        padding-left: .75rem;
    }
}

.vertical-toolbar .button__icon--sm {
    color: $day-utility-light;
    font-weight: $font-weight-medium;
    font-size: 12px;
    padding: 0;

    &:not(:first-child) {
        margin-top: 12px;

        @include mq(md_down) {
            margin-top: 4px;
        }

    }

    &:hover {
        color: $day-utility-utility;
    }

    &:active {
        color: $day-utility-dark;
    }
}

.vertical-toolbar .button__icon--sm.disabled {
    color: $day-utility-lightest;
    cursor: text;
}

