﻿body {
  color: $color-text;
  font-family: $bst-default-font-family;
}
div,
header,
main,
section,
footer {
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
p {
  font-size: 16px;
  font-weight: $font-weight-light;
  line-height: 20px;
}
a {
  @include transition(all 0.25s ease-in-out);
  &:hover {
    text-decoration: none;
  }
}
button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
img {
  max-width: 100%;
}
input {
  background-color: transparent;
}
