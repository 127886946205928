.dropdown--user {
    padding: 0;
    @include box-shadow(0 20px 48px 0 rgba(0, 37, 77, 0.16));

    .dropdown__header {
        height: 60px;
        position: relative;
        padding: 0 16px 0 72px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
    }

    .dropdown__user-initials {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
        background: $day-utility-utility;
        position: absolute;
        top: 10px;
        left: 16px;
        @extend .label-md;
        font-weight: $font-weight-medium;
        text-align: center;
    }

    .dropdown__name-wrap {
        margin-left: -56px;

        .dropdown__name,
        .dropdown__title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .dropdown__name {
            @extend .label-md;
            font-weight: $font-weight-medium;
            color: $day-grey-white;
        }

        .dropdown__title {
            @extend .label-xs;
            font-weight: $font-weight-regular;
            color: $day-primary-lightest-tint;
            opacity: 0.8;
        }
    }

    .dropdown__user-initials + .dropdown__name-wrap {
        margin-left: 0;
    }

    .dropdown__items-wrap {
        padding: 8px 0;

        .dropdown__item {
            box-sizing: border-box;
            gap: 16px;

            &:hover {
                background-color: $day-primary-lightest-tint;
            }

            &:active,
            &:focus {
                background-color: $day-primary-light !important;
            }
        }
    }
}
