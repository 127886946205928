.nlm_project_overrides-settings{
    background-color: $color-gray-white;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    width: 100%;

    .title {
      align-self: stretch;
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 45px 48px 35px;

      span{
        font-size: 28px;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: left;
        color: $day-text-default;
      }
    }

     .no_project_overrides {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 56px 44px;
        gap: 24px;
        background: $day-grey-lightest;

        span{
            font-weight: 300;
            font-size: 28px;
            line-height: 32px;
            text-align: center;
            color: rgba($day-utility-utility,0.8);
        
        }
        button{ 
            height: 48px;
            gap: 16px; 
        }

        i{
            font-size: 19px;
        }

     }
     .project_grid{
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        color: $day-text-default;
        margin-bottom: 0;
        border-right: none;
        border-left: none;
    
        .e-gridcontent {

           .e-row {
                height: 56px;
                & td.e-rowcell,.e-input-group, .e-input.e-field {
                    font-family: 'Open Sans'!important;
                    font-weight: 300 !important;
                    font-size: 14px !important;
                    line-height: 20px !important;
                    color: $day-text-default !important;
                }
                td.e-rowcell {
                    height: 56px;
                }
                td.e-rowcell:nth-child(2){
                    input{
                        text-align: right;
                    }
                }
                &:hover {
                    td.e-rowcell {
                        background: rgba(18, 116, 230, 0.04) !important;
                    }
                }
            }
        }
        // header
        .e-headercell {
            font-family: $bst-font-family;
            font-weight: $font-weight-medium;
            color: $day-utility-dark;
            font-size: 16px;
            padding: 24px !important;
            overflow: hidden;
            white-space: nowrap;
            &, td{height: auto !important;}
            &[aria-sort=ascending], &[aria-sort=descending] {
                span.e-headertext {
                    color: $day-utility-dark;
                }
            }
    
            .e-filtermenudiv,
            .e-sortfilterdiv {
                width: 32px;
                height: 32px;
                border-radius: 32px;
                background: $color-gray-white;
                border: 1px solid transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 5px 0 !important;
                float: right;
                opacity: 0 !important;
    
                .e-rhandler {
                    height: 84px!important;
                }
    
                &.e-icons {
                    display: none;
                }
    
                &::before {
                    color: $color-utility;
                    font-size: 19px;
                    content: $icon-filter;
                    font-family: '#{$icomoon-font-family}' !important;
                }
    
                &:hover, &.e-ascending, &.e-descending {
                    border: 1px solid $color-utility-light;
                    background: $color-utility-lightest;
    
                    &::before {
                        color: $color-blue;
                    }
                }
            }

            .e-sortfilterdiv {
                &::before {
                    content: $icon-sort
                }
                &.e-ascending {
                    &::before {
                        content: $icon-arrow-up
                    }   
                }
                &.e-descending {
                    &::before {
                        content: $icon-arrow-down
                    }
                }
    
            }
    
            &:nth-child(3),
            &:nth-child(3):hover {
                .e-filtermenudiv,
                .e-sortfilterdiv {
                    display: none !important;
                }
            }
    
            .e-headercelldiv {
                text-overflow: ellipsis;
                white-space: nowrap;
                float: left;
                margin: 0 !important;
                padding: 0 !important;
                min-height: 32px;
                text-align: left !important;

                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: $day-utility-dark;
            }
            &:hover, &.e-focus, &[aria-sort="ascending"], &[aria-sort="descending"] {
                background: $color-utility-lightest;
    
                .e-filtermenudiv,
                .e-sortfilterdiv {
                    opacity: 1 !important;
                    &.e-icons { display: flex; }
                }
            }
            &[aria-sort="ascending"]:not(:hover), &[aria-sort="descending"]:not(:hover) {
                background: $color-gray-white;
                .e-sortfilterdiv {
                    border-color: $day-primary-blue;
                    background-color: $day-primary-lightest;
                }
            }
            .e-ascending, .e-descending {
                &.e-filtermenudiv,
                &.e-sortfilterdiv {
                    opacity: 1 !important;
                    &.e-icons { display: flex; }
                }
            }
            &:focus {
                background: $color-drawer;
                span { color: $color-blue-darkest !important; }
            }
        }
        .e-lastcell{
            background: unset !important;
        }

        .e-gridheader {
            padding-right: 0 !important;
        }
    
        .e-content {
            max-height: 392px !important;
            @include scrollbars($thumb-background: rgb(64, 71, 79, 0.3), $mode: 'v', $size: 8px, $staticV: true);
        }
    
        .e-movablescrollbar {
            @include scrollbars($thumb-background: rgb(64, 71, 79, 0.3), $mode: 'h', $size: 8px, $staticV: true);
    
            &,
            .e-movablechild {
                min-height: 8px !important;
                max-height: 8px !important;
            }
        }
    
        .e-spin-up, .e-spin-down {
            display: none;
        }

        .e-scrollbar {
            bottom: 0;
            position: fixed !important;
            width: 100%;
            z-index: 9999999999;
        }
    
        // general items
        table {
            border-collapse: collapse !important;
        }
    
        tr {
            td {
                background-color: $day-grey-white !important;
    
                &.e-rowcell {
    
                    &:focus,
                    &:active,
                    &:focus-within,
                    &:target {
                        border: 1px solid $color-blue !important;
                        cursor: text;
                        background-color: $day-grey-white !important;
                    }

                }
                &.e-rowcell:last-child{
                    &:focus,
                    &:active,
                    &:focus-within,
                    &:target {
                        border:0 !important;
                        cursor:unset;
                        border-left: 1px solid #e0e0e0 !important;
                        background-color: inherit !important;
                    }
                }

            }
            .e-input-group,.e-control-wrapper{
                    &::after,
                    &::before {
                        content: none !important;
                    }

                    border: none !important;

            }

            .input.e-input::selection, 
            textarea.e-input::selection, 
            .e-input-group input.e-input::selection, 
            .e-input-group.e-control-wrapper 
            input.e-input::selection {
                background: inherit !important;
                color: inherit !important;
                }
            
            &.e-editedrow.e-batchrow {
                td.e-inputbox {
    

                    &:hover,
                    &:focus,
                    &:active,
                    &:focus-within,
                    &:target {
                        border: 1px solid $color-blue !important;
                        cursor: text;
                        background-color: $day-grey-white !important;
                    }
                }
              }
    
            &:hover {
    
                &:not(.e-disabled),
                &:not(.e-editedrow) {
                    .avatar {
                        opacity: 0 !important;
                    }
    
                    .e-checkbox-wrapper .e-frame {
                        opacity: 1;
                        transition: all .3s;
                    }
    
    
                }
    
    
                td {
                    background: rgba(18, 116, 230, 0.04) !important;
    
                    &.e-inputbox {
    
                        .e-input-group {
    
                            &::after,
                            &::before {
                                content: none !important;
                            }
    
                            border: none !important;
                        }
    
                        &:hover,
                        &:focus,
                        &:active,
                        &:focus-within,
                        &:target {
                            border: 1px solid $color-blue !important;
                            cursor: text;
                            background-color: $day-grey-white !important;
                        }
                    }
                }
            }
    
        }
    
        .e-disabled {
          pointer-events: none !important;
          cursor: unset!important;
        }

        .e-headerclone {
            border: solid 3px $day-primary-blue;
            background: $day-primary-lightest;
            border-radius: 0;
            @include flexbox();
            padding: 32px 15px;
            align-items: center;
            font-size: 1rem;
            color: $day-primary-blue;
            font-weight: $font-weight-medium;
            letter-spacing: 0.4px;
        }


    }
    .e-columnheader {
        height: 84px!important;
    }

    .project-override-button {
        padding: 20px 0 20px 52px;
        border-bottom: 1px solid $day-utility-lightest;
        width: 100%;
        text-align: left;
        margin-bottom: 12px;

        &:hover {
            background: rgba($day-primary-blue, 0.04);
        }

        .footer-message {
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color:$day-utility-light;
        }
    }  
}

.e-popup-open{
    margin-top: 8px !important;
    width: 100%;
}
.e-nodata {
    font-family: 'Open Sans';
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color:$day-grey-white !important;
    width: 100%;
}

.modal-validation{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 2000;
    width: 753px;
    height: 436px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 10px;
    padding: 104px 48px;
    border-radius: 16px;
    box-shadow: 0 20px 32px 0 rgba(0, 37, 77, 0.12);
    background-color: $day-grey-white;
    
}

.modal-dialog{
    margin: 0px;
}

.fade.modal-backdrop.show{
    z-index: 2000;
}

.modal-body{
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
    padding: 0;
}

.modal-area{
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 0;
}

.modal-header-text{
    font-weight: 400;
    font-size: 40px;
    line-height: 44px;

    text-align: center;
    letter-spacing: -1px;

    color: $day-text-default;
}

.modal-subheader-text{
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;

    text-align: center;
    color:$day-utility-utility;
}

.modal-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 12px 24px;
    gap: 24px;

    width: 161px;
    height: 48px;

    background:$day-primary-blue;
    border-radius: 32px;

    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    text-align: center;

    color: $day-grey-white;
}