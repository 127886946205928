.info-table {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: $day-text-default;
    margin-bottom: 0;
    min-height: calc(100vh - 202px);
    @include border-radius(0);
    
    &.e-grid.e-device .e-gridheader .e-headercell .e-rhandler.e-rcursor { width: 32px; }

    .inline-grid-edit {
        width: 100%;
        padding: 10px 0;
        border: none;
    }
    .e-gridcontent {
        min-height: calc(100vh - 202px);
        max-height: auto;

        .e-row {
            height: 84px;
        }
        .e-rowcell {
            & > div > div {
                position: relative;
                height: 100%;
            }
        }
    }
    th:nth-child(2) {
        padding-left: 24px !important;

    }
    .e-frozenheader {
        th:nth-child(2) {
            padding-left: 0 !important;
            .e-sortfilterdiv {display: none!important;}
        }
        &.e-frozen-left-header {
            th:nth-child(2) { 
                padding: 0!important;
                .e-headercelldiv {
                    min-width: 100%;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
    .e-gridcontent .e-frozencontent .e-rowcell {
        .e-checkbox-wrapper {
            min-width: 26px;
            position: static;
            z-index: 9;
        }
        &:hover .e-checkbox-wrapper {
            position: relative;
        }
    }

    //user initials avatar
    .avatar {
        height: 32px !important;
        width: 32px !important;
        @include border-radius(32px);
        background-color: $day-utility-utility;
        text-transform: uppercase;
        display: flex;  
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        opacity: 1 !important;
        position: absolute !important;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto !important;
        z-index: 0;

        .label-sm {
            color: $day-grey-white;
        }
    }



    // header
    .e-headercell {
        font-family: $bst-font-family;
        font-weight: $font-weight-medium;
        color: $day-utility-dark;
        font-size: 16px;
        padding: 24px !important;
        overflow: hidden;
        white-space: nowrap;
        &, td{height: auto !important;}
        &[aria-sort=ascending], &[aria-sort=descending] {
            span.e-headertext {
                color: $day-utility-dark;
            }
        }
        &:not(:last-child) {
                border-right: 0 !important;
        }

        .e-rhandler {
            border-color: $day-utility-lightest !important;
            opacity: .4;
            width: 0;
        }
        &.e-mousepointer {cursor:default;}

        .e-filtermenudiv,
        .e-sortfilterdiv {
            cursor:pointer;
            width: 32px;
            height: 32px;
            border-radius: 32px;
            background: $color-gray-white;
            border: 1px solid transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 5px 0 !important;
            float: right;
            opacity: 0 !important;

            .e-rhandler {
                height: 84px!important;
            }

            &.e-icons {
                display: none;
            }

            &::before {
                color: $color-utility;
                font-size: 19px;
                content: $icon-filter;
                font-family: '#{$icomoon-font-family}' !important;
            }

            &:hover, &.e-ascending, &.e-descending {
                border: 1px solid $color-utility-light;
                background: $color-utility-lightest;

                &::before {
                    color: $color-blue;
                }
            }
        }

        .e-sortfilterdiv {
            &::before {
                content: $icon-sort
            }
            &.e-ascending {
                &::before {
                    content: $icon-arrow-up
                }   
            }
            &.e-descending {
                &::before {
                    content: $icon-arrow-down
                }
            }

        }

        .e-headercelldiv {
            text-overflow: ellipsis;
            white-space: nowrap;
            float: left;
            width: 100%;
            margin: 0 !important;
            padding: 0 !important;
            min-height: 32px;
            text-align: left !important;
        }
        &:hover, &.e-focus, &[aria-sort="ascending"], &[aria-sort="descending"] {
            background: $color-utility-lightest;

            .e-filtermenudiv,
            .e-sortfilterdiv {
                opacity: 1 !important;
                &.e-icons { display: flex; }
            }
            .e-headercelldiv {
                max-width: calc(100% - 90px);
            }
        }
        &[aria-sort="ascending"]:not(:hover), &[aria-sort="descending"]:not(:hover) {
            background: $color-gray-white;
            .e-sortfilterdiv {
                border-color: $day-primary-blue;
                background-color: $day-primary-lightest;
            }
        }
        .e-ascending, .e-descending {
            &.e-filtermenudiv,
            &.e-sortfilterdiv {
                opacity: 1 !important;
                &.e-icons { display: flex; }
            }
        }
        &:focus {
            background: $color-drawer;
            span { color: $color-blue-darkest !important; }
        }
    }

    .e-gridheader {
        padding-right: 0 !important;
    }

    // frozen columns
    .e-frozencontent,
    .e-frozenheader {
        .e-rowcell {
            font-family: $bst-font-family;
            font-weight: $font-weight-medium;
            color: $day-text-default;
            font-size: 16px;
        }

        &>.e-table {
            border-right: 1px solid $day-utility-light;

            td,
            th {
                border-right: none !important;

                &::after {
                    content: none !important;
                }
            }
        }

        .e-checkbox-wrapper .e-frame {
            opacity: 0;

            &.e-stop,
            &.e-check {
                opacity: 1;
            }
        }
    }

    // scrollbars
    // .e-content {
    //     overflow-y: hidden !important;
    //     height: auto !important;
    // }



    .e-content {
        @include scrollbars($thumb-background: rgb(64, 71, 79, 0.3), $mode: 'v', $size: 8px, $staticV: true);
    }

    .e-movablescrollbar {
        @include scrollbars($thumb-background: rgb(64, 71, 79, 0.3), $mode: 'h', $size: 8px, $staticV: true);

        &,
        .e-movablechild {
            min-height: 8px !important;
            max-height: 8px !important;
        }
    }

    .e-scrollbar {
        bottom: 0;
        position: fixed !important;
        width: 100%;
        z-index: 9999999999;

        .e-frozenscrollbar {
            border: none
        }
    }


    // checkbox
    .e-checkbox-wrapper {

        .e-frame.e-icons {
            &.e-stop {
                line-height: 16px; 
            }

            &.e-check,
            &.e-stop {
                color: $day-grey-white !important;
                background-color: $color-blue !important;
                border-color: $color-blue !important;
            }
        }

    }

    .e-gridchkbox {
        position: relative !important;
    }

    // general items
    table {
        border-collapse: collapse !important;
    }

    td:not(.e-gridchkbox) {
        padding: 0 22px !important;
    }


    tr,
    .e-frozencontent tr {
        td {
            background-color: $day-grey-white !important;

            &.e-inputbox {

                .e-input-group {

                    &::after,
                    &::before {
                        content: none !important;
                    }

                    border: none !important;
                }

                &:hover,
                &:focus,
                &:active,
                &:focus-within,
                &:target {
                    border: 1px solid $color-blue !important;
                    cursor: text;
                    background-color: $day-grey-white !important;
                }
            }
        }

        &.e-editedrow.e-batchrow {
            td.e-inputbox {

                .e-input-group {

                    &::after,
                    &::before {
                        content: none !important;
                    }

                    border: none !important;
                }

                &:hover,
                &:focus,
                &:active,
                &:focus-within,
                &:target {
                    border: 1px solid $color-blue !important;
                    cursor: text;
                    background-color: $day-grey-white !important;
                }
            }
        }

        &.e-disabled {
            pointer-events: none !important;
        }

        &[aria-selected='true'] {
            .avatar {
                opacity: 0 !important;
            }

            .e-checkbox-wrapper .e-frame {
                opacity: 1;
            }
        }
        &.e-frozenhover {
             &.e-disabled-invitation {
                .avatar {
                    opacity: 1 !important;
                }
            }
        }
        
        &:hover,
        &.e-frozenhover {
            background-color: #fff!important;
            &:not(.e-disabled),
            &:not(.e-disabled-invitation),
            &:not(.e-editedrow) {
                .avatar {
                    opacity: 0 !important;
                }

                .e-checkbox-wrapper .e-frame {
                    opacity: 1;
                    transition: all .3s;
                }


            }

            td {
                background: rgba(18, 116, 230, 0.04) !important;

                &.e-inputbox {

                    .e-input-group {

                        &::after,
                        &::before {
                            content: none !important;
                        }

                        border: none !important;
                    }

                    &:hover,
                    &:focus,
                    &:active,
                    &:focus-within,
                    &:target {
                        border: 1px solid $color-blue !important;
                        cursor: text;
                        background-color: $day-grey-white !important;
                    }
                }
            }
        }

    }

    .e-frozencontent {
        .e-disabled-invitation, .e-disabled-invitation[aria-selected='true'] {
            .e-checkbox-wrapper {
                pointer-events: none !important;
                opacity: 0.3;
            }
        }
        .e-rowcell.e-inputbox {
            input {
                font-family: $bst-font-family !important;
                font-weight: $font-weight-medium !important;
                font-size: 16px !important;
            }
        }
        .inline-grid-edit {
            font-family: $bst-font-family !important;
            font-weight: $font-weight-medium !important;
            font-size: 16px !important;
        }
    }
    .e-headerclone {
        border: solid 3px $day-primary-blue;
        background: $day-primary-lightest;
        border-radius: 0;
        @include flexbox();
        padding: 32px 15px;
        align-items: center;
        font-size: 1rem;
        color: $day-primary-blue;
        font-weight: $font-weight-medium;
        letter-spacing: 0.4px;
    }
}

.has-selected-items {
    .info-table {
        .avatar {
            opacity: 0!important;
        }
        .e-frozencontent .e-disabled-invitation {
            .e-checkbox-wrapper, 
            &[aria-selected=true] .e-checkbox-wrapper {
                //display: flex;
                opacity: 0.3;
            }
            .avatar {
                opacity: 0!important;
            }
        }
        .e-frozencontent .e-checkbox-wrapper .e-frame, 
        .e-frozenheader .e-checkbox-wrapper .e-frame {
            opacity: 1;
        }
    }
}

.e-columnheader {
    height: 84px!important;
}


.info-table__footer {
    width: 100%;
    background: $day-grey-white;
    position: fixed;
    z-index: 99;
    left: 0;
    bottom: 0;
    background: $day-grey-white;
    box-shadow: $box-shadow-6;

    .global-utilities__container {
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
    }

    &-left {
        flex: 1 1 auto;
        text-align: left;

        .invite-title,
        .invite-text {
            font-size: 18px;
            font-weight: $font-weight-medium;
            line-height: 24px;
            color: $color-text;
            display: block;
        }

        .invite-text {
            color: $color-utility-light;
        }
    }

    &-right {
        flex: 0 0 auto;
    }

    .DNA-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
        min-width: 160px;

        .icon {
            font-size: 24px;
            color: $day-grey-white;
        }

    }

}

.modal-body {
    .e-scrollbar, .info-table__footer {bottom: 80px;}
}

.skeleton {
    width: 100%;
    height: 289px;
    @include animation(backgroundPosition, 2s, linear, 0s, infinite);


    &_rectangle1 {
        x: 64;
        y: 41;
        rx: 8;
        ry: 8;
        width: 283px;
        height: 26px;
        background: $day-primary-lightest-tint linear-gradient(to right, rgba($day-utility-lightest, 0) 0%, rgba($day-utility-lightest, 0.16) 25%, $day-utility-lightest 50%, rgba($day-utility-lightest, 0.16) 75%, rgba($day-utility-lightest, 0) 100%);
    }

    &_rectangle2 {
        x: 64;
        y: 76;
        rx: 8;
        ry: 8;
        width: 200px;
        height: 16px;
    }

    &_rectangle3 {
        x: 64;
        y: 113.5;
        rx: 8;
        ry: 8;
        width: 506px;
        height: 136px;
        background: $day-primary-lightest-tint linear-gradient(to right, rgba($day-utility-lightest, 0) 0%, rgba($day-utility-lightest, 0.16) 25%, $day-utility-lightest 50%, rgba($day-utility-lightest, 0.16) 75%, rgba($day-utility-lightest, 0) 100%);
    }

    &_rectangle4 {
        x: 602;
        y: 113;
        rx: 0;
        ry: 0;
        width: 1px;
        height: 136px;
        background: $day-primary-lightest-tint linear-gradient(to right, rgba($day-utility-lightest, 0) 0%, rgba($day-utility-lightest, 0.16) 25%, $day-utility-lightest 50%, rgba($day-utility-lightest, 0.16) 75%, rgba($day-utility-lightest, 0) 100%);
    }

    &_rectangle5 {
        x: 634;
        y: 164.5;
        rx: 8;
        ry: 8;
        width: calc(100% - 768px);
        height: 34px;
        background: $day-primary-lightest-tint linear-gradient(to right, rgba($day-utility-lightest, 0) 0%, rgba($day-utility-lightest, 0.16) 25%, $day-utility-lightest 50%, rgba($day-utility-lightest, 0.16) 75%, rgba($day-utility-lightest, 0) 100%);
    }

    @include mq(sm_down) {
        width: 100%;
        height: 289px;

        &_rectangle1 {
            x: 24;
            y: 33;
            rx: 8;
            ry: 8;
            width: calc(100% - 917px);
            height: 26px;
        }

        &_rectangle2 {
            x: 24;
            y: 68;
            rx: 8;
            ry: 8;
            width: calc(100% - 1001px);
            height: 12px;
        }

        &_rectangle3 {
            x: 24;
            y: 92;
            rx: 8;
            ry: 8;
            width: calc(100% - 82px);
            height: 100px;
        }

        &_rectangle4 {
            x: 24;
            y: 212;
            rx: 8;
            ry: 8;
            width: calc(100% - 84px);
            height: 34px;
        }

        &_rectangle5 {
            display: none;

        }
    }


}