﻿/* Syncfusion styles.
   TODO: organize this file.
*/
.e-gantt {
    position: relative;
    align-self: stretch;
    min-height: 0;
    flex-grow: 1;
    box-sizing: border-box;
    width: 100% !important;
    background-color: $day-grey-white;
    @include flexbox();
    flex-direction: column;

    .e-spinner-pane {
        pointer-events: none !important;
        visibility: hidden !important;
    }

    .e-gantt-chart-pane {
        z-index: 0;
    }

    .e-gantt-chart {
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        @include user-select(none);


        .e-task-table {
            border-collapse: collapse;
        }

        .e-chart-root-container {
            background-color: $day-grey-lightest;

            .e-content {
                height: calc(100% - #{$layout-gantt-header-height}) !important;
                /* Padding at the bottom of the chart to account for Add new task PLUS to help retain positions after collapsing a row */
                /* 100% of the visible container height MINUS one task row (57px) */
                &::after {
                    content: '';
                    position: relative;
                    z-index: 0;
                    display: block;
                    height: calc(100% - 57px);
                    background-color: transparent;
                }

                .e-chart-rows-container {
                    height: 100%;
                    z-index: 0;
                    //Gantt side row
                    .e-chart-row {
                        line-height: 1px;

                        &.highlight, &:hover {
                            background-color: rgba($day-primary-blue, 0.04);
                        }
                        /*#region Row multi-select Gantt side styles */
                        &.e-active {
                            background: linear-gradient($day-primary-blue 1px, rgba($day-primary-blue, 0.08) 1px);
                        }

                        &.e-active + .e-active {
                            background: rgba($day-primary-blue, 0.08) !important;
                        }

                        &.e-active + :not(.e-active) {
                            background: linear-gradient($day-primary-blue 1px, transparent 1px);

                            &.highlight, &:hover {
                                background: linear-gradient($day-primary-blue 1px, rgba($day-primary-blue, 0.04) 1px);
                            }
                        }

                        &.e-active:last-of-type {
                            background: linear-gradient($day-primary-blue 1px, rgba($day-primary-blue, 0.08) 1px, 55px, $day-primary-blue 55px);
                        }

                        .e-chart-row-cell {
                            overflow: visible;
                            border: none !important;
                            box-sizing: border-box;
                        }
                        /*#endregion Row multi-select Gantt side styles */
                    }
                }
            }
        }
        /*#region Gantt event markers */
        .e-event-markers {
            border-left: 2px solid $color-accent-light;
            z-index: auto;

            .e-gantt-right-arrow {
                display: none;
            }

            .e-span-label {
                display: none;
            }

            &.e-custom-event-marker {
                position: absolute;
                box-sizing: content-box !important;
                border-left: 2px solid $day-accent-light !important;
            }

            &.e-event-markers-day {
                z-index: 1;
                margin-left: 14px;
            }

            &.e-event-markers-week {
                border: 0 !important;
                background-color: rgba($day-accent-light, 0.2);
                width: 92px !important;
                z-index: 0;
            }

            &.e-event-markers-month {
                border: 0 !important;
                width: 328px !important;
                background-color: rgba($day-accent-light, 0.2);
                z-index: 0;
            }

            &.e-event-markers-none {
                display: none !important;
            }
        }
        /*#endregion Gantt event markers */

        .e-taskbar-main-container {
            overflow-y: visible !important;

            .e-gantt-parent-taskbar {
                @include border-radius(8px);
                @include box-shadow(3);
                background-color: $day-grey-white;
                border: none;
                height: 16px;
                margin-top: 8px;
                cursor: pointer;

                &--collapsed {
                    @include flexbox();
                    align-items: center;
                    height: 32px;
                    margin-top: 0;
                }

                .e-label {
                    @include flexbox();
                    @extend .label-xs;
                    align-items: center;
                    color: $day-utility-utility;
                    position: absolute;
                    padding-left: 24px;
                    height: 100%;
                }
            }

            .e-gantt-child-taskbar {
                @include border-radius(8px);
                @include box-shadow(1);
                width: 100% !important;
                background-color: $day-other-gantt-bar;
                border: none;
                height: 32px;
                margin-top: 0;
                cursor: pointer;

                &.tentative, &.chargeable, &.done {
                    background-color: $color-utility-light;
                }

                &--thin {
                    @include border-radius(4px);
                    @include box-shadow(0);
                    height: 12px;
                }
            }

            .milestone {
                width: 17px;
                height: 17px;
                background: $day-accent-purple;
                top: 38%;
                right: -50px;
                position: absolute;
                transform: translate(-50%, -50%);
                transform: rotate(-45deg);
                transform-origin: 0 100%;
                z-index: 100;
            }

            .toplabel, .bottomlabel {
                position: absolute;
                bottom: -1px;
            }

            .e-connectorpoint-right,
            .e-connectorpoint-left {
                opacity: 0;
                background-color: $day-utility-utility;
                border: none !important;
                z-index: 4;
            }

            .e-right-connectorpoint-outer-div,
            .e-left-connectorpoint-outer-div {
                cursor: pointer;
            }

            .e-connectorpoint-right, .e-connectorpoint-right-hover {
                width: 12px !important;
                height: 12px !important;
                @include transform(translate(4px, -2px));
            }

            .e-connectorpoint-left,
            .e-connectorpoint-left-hover {
                width: 14px !important;
                height: 14px !important;

                &.e-connectorpoint-allow-block {
                    opacity: 0 !important;
                }
            }

            .e-taskbar-right-resizer,
            .e-taskbar-left-resizer {
                height: 32px !important;
                opacity: 0;

                &.e-left-resize-gripper:before {
                    left: 8px;
                }

                &.e-right-resize-gripper:before {
                    right: 8px;
                }

                &.e-right-resize-gripper:before,
                &.e-left-resize-gripper:before {
                    position: absolute;
                    content: '';
                    height: 12px;
                    width: 1.5px;
                    background: $day-grey-white;
                }

                &:hover {
                    opacity: 1;

                    &.e-right-resize-gripper:before,
                    &.e-left-resize-gripper:before {
                        width: 3px;
                        height: 16px;
                        @include border-radius(1px);
                    }

                    &.e-right-resize-gripper:before {
                        right: 7px;
                    }

                    &.e-left-resize-gripper:before {
                        left: 7px;
                    }
                }
            }

            &:hover {
                .e-taskbar-right-resizer, .e-taskbar-left-resizer {
                    opacity: 1;
                }
            }

            &[dependencyedit] {
                .e-taskbar-right-resizer,
                .e-taskbar-left-resizer {
                    opacity: 0;
                    pointer-events: none;
                }
            }

            &[dependencyedit="true"] {
                .e-connectorpoint-left,
                .e-connectorpoint-left-hover {
                    opacity: 0;
                    pointer-events: none;
                }
            }

            .bottomlabel {
                bottom: -1px;
            }

            .effort {
                position: absolute;
                bottom: 0;
                width: 70%;
                height: 7px;
                background-color: red;
                margin: 0 0 3px 3px;

                div {
                    background-color: blue !important;
                }
            }
        }

        .e-left-label-container {
            color: $color-utility;
            font-size: 14px;
            font-weight: $font-weight-regular;
            padding-top: 16px;
            text-align: right;
        }

        .e-connector-line-container {
            z-index: 1;

            .e-line {
                border-color: $day-utility-utility;
            }

            .e-connector-line-right-arrow {
                border-left-color: $day-utility-utility;
                border-right-color: $day-utility-utility;
            }
        }
        /*#region Timeline Headers*/
        .e-timeline-header-container {
            height: $layout-gantt-header-height;
            padding-top: 48px;
            z-index: 0;
            pointer-events: none;
            position: static;

            .e-timeline-header-table-container:first-child {

                thead, thead tr {
                    font-size: 12px;
                    height: 12px;
                    line-height: 1em;
                    vertical-align: baseline !important;
                }

                .e-timeline-top-header-cell {
                    height: 12px;
                    border: none;

                    & .e-header-cell-label {
                        display: inline-block;
                        width: auto !important;
                        position: -webkit-sticky;
                        position: sticky;
                        margin-left: 0;
                        left: 48px;

                        span.u-text-regular {
                            color: $day-utility-light;
                            padding-left: 1px;
                        }
                    }

                    color: $day-utility-utility !important;
                }
            }
            /*#region Bottom of timeline header (Day Week Month)*/
            .e-timeline-header-table-container:last-child .e-timeline-top-header-cell {
                padding-bottom: 3px;
                border: none;
                font-weight: $font-weight-medium;
                font-size: 12px;

                .e-header-cell-label {
                    overflow: visible;
                }

                .date-number {
                    color: $day-utility-light;

                    &--week, &--month {
                        color: $day-utility-light;
                        width: 56px !important;
                    }

                    &--week {
                        margin-left: 18px;
                    }
                }

                .date-number--today {
                    @include border-radius(12px);
                    box-sizing: border-box;
                    padding: 4px;
                    display: inline-block;
                    height: 24px;
                    line-height: 16px;
                    min-width: 24px;
                    background-color: $day-accent-light;
                    color: $day-text-white;
                    text-align: center;
                    vertical-align: middle;
                    margin-left: -6px;

                    &.date-number--week {
                        margin-left: 6px;
                    }
                }
            }
            /*#endregion Bottom of timeline header (Day Week Month)*/
            /*#endregion Timeline Headers*/
        }
        /*#region Weekend, Holiday, & non-working day styles */
        .e-weekend-header-cell, .e-weekend-header {
            color: $day-utility-lightest !important;
        }

        .e-holiday, .e-weekend, .e-custom-holiday, .e-work-calendar-exception {
            box-sizing: border-box;
            border-left: 4px solid $day-grey-lightest;
            border-right: 4px solid $day-grey-lightest;
            background: repeating-linear-gradient(-45deg, $day-grey-light, $day-grey-light 12px, $day-grey-lightest 12px, $day-grey-lightest 24px );
            @include transform(translateX(-6px));
            z-index: 1;
        }

        .e-work-calendar-exception {
            background: $day-grey-light;
        }
        /*#endregion Weekend, Holiday, & non-working day styles */
    }

    .e-gantt-splitter {
        border: none;
        height: 100% !important;
        width: 100% !important;
        position: static;
    }
    /*#region Full task side treegrid*/
    .e-gantt-tree-grid-pane {
        box-sizing: border-box;
        z-index: 1;
        box-shadow: 2px 0 20px 0 rgba(55, 78, 91, 0.15);

        &:not(.e-expanded) .e-content {
            &::-webkit-scrollbar {
                width: 0 !important;
            }
        }

        .e-treegrid {
            box-sizing: border-box;
            height: 100% !important;

            .e-grid {
                box-sizing: border-box;
                height: 100% !important;
                position: static;
                z-index: 10;

                .e-focused,
                .e-focus {
                    outline: none !important;
                    @include box-shadow(none);
                }
                /*#region Task side headers (column headers) */
                .e-gridheader {
                    box-sizing: border-box;
                    height: $layout-gantt-header-height;
                    border-top: none;
                    border-bottom: 1px solid $day-utility-lightest;
                    color: $color-utility;
                    white-space: nowrap;

                    & > .e-headercontent {
                        box-sizing: border-box;
                        height: 100%;

                        & > .e-table {
                            box-sizing: border-box;
                            height: 100%;
                            //Blank header space in table to the right of all columns -- prevents autosizing of columns
                            tr {
                                th {
                                    box-sizing: border-box;
                                    height: $layout-gantt-header-height;
                                }

                                &:after {
                                    content: '';
                                    position: absolute;
                                }
                            }
                        }

                        .e-reorderuparrow {
                            display: none !important;
                        }

                        .e-reorderdownarrow:before {
                            content: '';
                            position: absolute;
                            width: 4px;
                            background-color: $day-primary-blue;
                            border-radius: 2px;
                            right: -6px;
                            height: 91px;
                            top: 4px;
                        }
                    }

                    & .e-rowdragheader {
                        padding: 0;
                    }

                    & .e-headercell {
                        border-right: 1px solid $day-utility-lightest;
                        padding: 0;

                        &[aria-colindex="0"] {
                            pointer-events: none;
                            padding-left: 0 !important;
                            overflow: visible;

                            button, .e-rhandler.e-rcursor {
                                pointer-events: auto;
                            }
                        }

                        .e-rhandler, .e-rsuppress {
                            border: none !important;
                        }

                        .e-headercelldiv {
                            box-sizing: border-box;
                            @include flexbox();
                            padding: 0;
                            margin: 0;
                            align-items: center;
                            font-size: 1rem;
                            color: $day-utility-light;
                            font-weight: $font-weight-regular !important;
                            letter-spacing: 0.4px;
                            height: $layout-gantt-header-height;
                        }
                    }
                }
                /*#endregion Task side headers (column headers) */
                /*#region Task side grid without headers*/
                .e-gridcontent {
                    box-sizing: border-box;
                    position: relative;
                    height: 100%;
                    /* Padding at the bottom of the grid to help retain positions after collapsing a row */
                    /* 100% of the visible container height MINUS one task row (57px) and the add new task line (86px) */
                    .e-content {
                        &::after {
                            content: '';
                            position: relative;
                            z-index: 0;
                            display: block;
                            height: calc(100% - 143px);
                            background-color: transparent;
                        }
                    }

                    .e-content > .e-table {
                        box-sizing: border-box;
                        border-collapse: collapse !important;
                        border-spacing: 0 !important;
                        width: 100% !important;
                        border-bottom: none !important;
                        margin-left: -1px;

                        @include safari {
                            margin-left: 0;
                        }
                        //Task side row
                        .e-row {
                            box-sizing: border-box;
                            border: none !important;
                            position: relative;

                            td {
                                position: relative;
                                box-sizing: border-box !important;
                                border-bottom: none;
                                border-top: none;
                                overflow: visible !important;
                                padding: 0;
                                height: 56px;
                            }

                            &:first-child .property-cell {
                                margin-left: 0 !important;
                                width: 100% !important;
                            }
                            //Blank space in table to the right of all columns -- prevents autosizing of columns
                            &::after {
                                content: '';
                                display: block;
                                position: relative;
                                top: 55px;
                                height: 1px;
                                background-color: $day-utility-lightest;
                                z-index: 0;
                            }

                            .e-rowdragdrop {
                                .e-icons {
                                    visibility: hidden;
                                    margin-left: -4px;
                                    opacity: 0.5;
                                    z-index: 2;

                                    &:before {
                                        font-family: '#{$icomoon-font-family}';
                                        content: '#{$icon-drag-1}';
                                        font-size: 1.5rem;
                                        font-weight: $font-weight-regular !important;
                                        color: $day-primary-darkest !important;
                                        opacity: 1;
                                    }
                                }
                            }

                            &:hover {
                                background-color: $day-primary-lightest;

                                .linenbr, .e-rowdragdrop {
                                    color: $day-utility-light !important;
                                }

                                .e-rowdragdrop {
                                    .e-icons {
                                        visibility: visible;
                                    }

                                    &::before {
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        bottom: 0;
                                        width: 58px;
                                        background-color: $day-utility-lightest;
                                        z-index: 0;
                                    }
                                }
                            }

                            &:first-child {
                                .e-rowdragdrop {
                                    pointer-events: none;
                                    cursor: default;
                                    pointer-events: none;

                                    .e-icons {
                                        visibility: hidden;
                                    }
                                }
                            }
                            //Row multi-select task side styles
                            &[aria-selected="true"] {
                                background: linear-gradient($day-primary-blue 1px, rgba($day-primary-blue, 0.08) 1px);

                                td.e-active {
                                    background-color: transparent;
                                }

                                .linenbr, .e-rowdragdrop {
                                    color: $day-primary-darkest !important;
                                }

                                .e-rowdragdrop::before {
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    top: 1px;
                                    bottom: 0;
                                    width: 58px;
                                    background-color: $day-other-drawer !important;
                                }
                            }

                            &[aria-selected="true"] + [aria-selected="true"] {
                                background: rgba($day-primary-blue, 0.08) !important;

                                .e-rowdragdrop::before {
                                    top: -1px;
                                    bottom: -1px;
                                }
                            }

                            &[aria-selected="true"] + :not([aria-selected="true"]) {
                                background: linear-gradient($day-primary-blue 1px, $day-grey-white 1px);

                                &:hover {
                                    background: linear-gradient($day-primary-blue 1px, $day-primary-lightest 1px);

                                    .e-rowdragdrop::before {
                                        top: 1px;
                                        bottom: 0;
                                    }
                                }
                            }

                            &[aria-selected="true"]:first-of-type {

                                td:nth-child(2) .e-treecolumn-container:before {
                                    content: '';
                                    position: absolute;
                                    top: 1px;
                                    bottom: 0;
                                    left: 0;
                                    bottom: 0;
                                    width: 28px;
                                    background-color: $day-other-drawer;
                                }
                            }

                            &[aria-selected="true"]:last-of-type {
                                background: linear-gradient($day-primary-blue 1px, rgba($day-primary-blue, 0.08) 1px, 55px, $day-primary-blue 55px);

                                .e-rowdragdrop::before {
                                    bottom: 1px;
                                }

                                &::after {
                                    visibility: hidden;
                                }

                                .e-rowcell:not(:nth-child(-n+2)):before {
                                    display: none;
                                }
                            }
                            //Task side rowcells
                            .e-rowcell {
                                font-size: 14px;
                                color: $day-text-default;
                                text-overflow: ellipsis;
                                vertical-align: middle;
                                white-space: nowrap;

                                .e-treecolumn-container {
                                    overflow: visible !important;
                                }

                                &:not(:nth-child(-n+2)):before {
                                    content: '';
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    height: 1px;
                                    background-color: $day-utility-lightest;
                                }

                                &:nth-child(2) {
                                    border-right: 1px solid $day-utility-lightest;
                                    color: $day-grey-dark;
                                    font-size: 14px;

                                    .e-treecolumn-container {
                                        @include flexbox();
                                        position: relative;
                                        box-sizing: border-box;
                                        width: 100%;
                                        height: 100%;

                                        & > span.e-treecell {
                                            flex: 1;
                                            color: $day-grey-dark;
                                            position: static !important;

                                            & > span {
                                                display: block;
                                                height: 100%;
                                                line-height: 56px;
                                                vertical-align: middle;
                                                overflow: hidden;
                                            }

                                            &:nth-child(3) {
                                                font-weight: $font-weight-semibold !important;
                                                color: $day-text-default;
                                            }

                                            &:nth-child(4) {
                                                font-weight: $font-weight-semibold !important;
                                                color: $day-grey-darkest;
                                            }

                                            &:nth-child(5) {
                                                font-weight: $font-weight-regular !important;
                                                color: $day-grey-darkest;
                                            }

                                            &:nth-child(6) {
                                                font-weight: $font-weight-regular !important;
                                            }

                                            .property-cell {
                                                margin-left: 4px;
                                                padding-left: 6px;
                                                width: calc(100% - 4px);
                                            }
                                        }

                                        .linenbr {
                                            position: absolute;
                                            left: -12px;
                                            top: 0;
                                            width: 40px;
                                            color: $day-utility-lightest;
                                            font-weight: $font-weight-semibold !important;
                                            cursor: default;
                                            text-align: center;
                                            user-select: none;
                                        }

                                        .e-icons.e-none {
                                            width: 16px !important;
                                        }

                                        .e-treegridexpand:before, .e-treegridcollapse:before {
                                            font-family: '#{$icomoon-font-family}';
                                            content: '#{$icon-chevron-right_micro}';
                                            color: $day-grey-darkest;
                                            font-size: larger;
                                            position: relative;
                                            margin-top: 20px;
                                            margin-right: 0;
                                        }
                                        //Parent task chevrons for expand/collapse
                                        .e-treegridexpand, .e-treegridcollapse {
                                            margin-top: 20px;
                                            z-index: 6;

                                            &:not(:first-child) {
                                                margin-left: 1rem !important;
                                                //Parent task name cells
                                                &:not(.e-treegridcollapse) ~ .e-treecell > .property-cell {
                                                    &::after {
                                                        content: '';
                                                        position: absolute;
                                                        height: 1px;
                                                        left: 6px;
                                                        right: 0;
                                                        bottom: 0;
                                                        background-color: rgba($day-utility-lightest, 0.4);
                                                    }
                                                }

                                                & ~ .e-icons.e-none {
                                                    width: 0 !important;
                                                }
                                            }
                                        }
                                    }
                                }

                                &:not(:nth-child(2)):after {
                                    content: '';
                                    position: absolute;
                                    top: .5rem;
                                    right: -1px;
                                    bottom: .5rem;
                                    width: 1px;
                                    background-color: $day-utility-lightest;
                                    opacity: 0.4;
                                }

                                &.e-hide {
                                    display: none;
                                }
                            }

                            .e-childborder {
                                border: none;
                            }

                            &--dragover {
                                .e-rowdragdrop {
                                    &::before {
                                        display: none;
                                    }

                                    .e-icons {
                                        visibility: hidden !important;
                                    }
                                }
                            }

                            &--dragover:not(&--dragged) {
                                background: $day-grey-white;

                                &:not(:first-child) {
                                    background: linear-gradient($day-primary-blue 2px, 2px, $day-grey-white 2px);
                                }
                            }

                            &--dragged {
                                background-color: $day-grey-lightest !important;

                                .property-cell, .e-treegridexpand::before, .e-treegridcollapse::before {
                                    color: rgba($day-text-default, 0.4) !important;
                                }
                            }
                        }
                    }
                }
                /*#endregion Task side grid without headers*/
            }
        }
    }
    /*#endregion Full task side treegrid*/
    /*#region Scrollbars */
    .e-content, .e-gantt-tree-grid-pane .e-gridcontent .e-content {
        box-sizing: border-box;
        position: relative;
        height: 100% !important;
        width: 100% !important;
        @include scrollbars(rgba($day-grey-darkest, 0), rgba($day-grey-darkest, 0.3), $staticV:false);
    }
    /*#endregion scrollbars */

    .e-control {
        font-family: $bst-default-font-family;
    }

    .e-gantt-false-line {
        border-top-color: $day-primary-blue !important;
        pointer-events: none;
        margin-left: 6px;

        &::after {
            font-family: '#{$icomoon-font-family}';
            content: '#{$icon-chevron-right_micro}';
            font-size: 14px;
            position: absolute;
            top: -8px;
            left: calc(100% - 6px);
            color: $day-primary-blue;
        }
    }
}
