 table, th,td{
    border-collapse: collapse;
 }

 th, td {
    border: 1px solid rgba($day-utility-utility, 0.2);
  }
  th {
    height: 84px;
    padding: 0 24px;
  }
  th:first-child, td:first-child{
    border-left: 0px;
  }
  th:last-child, td:last-child{
    border-right: 0px;
  }

  tr {
    height: 56px;
  }
  td{
    padding: 24px;
  }