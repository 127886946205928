﻿.button {
    @include border-radius(24px);
    align-items: center;
    background-color: $color-primary;
    color: $color-gray-white;
    display: grid;
    grid-auto-flow: column;
    font-family: $bst-font-family;
    font-size: 16px;
    font-weight: $font-weight-medium;
    text-transform: none;
    height: 48px;
    line-height: 24px;
    padding: 0 24px;

    &:hover {
        background-color: $color-primary-dark;
    }

    &:disabled, &.is-disabled {
        opacity: .4;
    }

    &:focus, &:active, &.is-active {
        background-color: $color-primary-darkest;
    }

    &--inverse {
        background-color: $color-gray-white;
        color: $color-primary;

        &:hover {
            background-color: $color-primary-lightest-tint;
        }

        &:disabled, &.is-disabled {
            background-color: $color-gray-light;
            color: $color-gray-dark;
        }

        &:focus, &:active, &.is-active {
            background-color: $color-primary-lightest;
        }
    }

    &--secondary {
        background-color: $color-gray-white;
        border: 1px solid $color-utility-light;
        color: $color-utility;

        &:hover {
            background-color: $color-gray-white;
            border-color: $color-utility-dark;
            color: $color-utility-dark;
        }

        &:disabled, &.is-disabled {
            background-color: $color-gray-white;
            border-color: $color-utility-light;
            color: $color-utility;
        }

        &:focus, &:active, &.is-active {
            background-color: $color-utility-lightest;
            border-color: $color-utility-dark;
            color: $color-utility-dark;
        }
    }

    &--secondary-inverse {
        background-color: transparent;
        border: 1px solid $color-primary-lightest-tint;
        color: $color-text-white;

        &:hover {
            background-color: transparent;
            border: 1px solid $color-gray-white;
            color: $color-gray-white;
        }

        &:focus, &:active, &.is-active {
            background-color: transparent;
            border: 1px solid $color-primary-lightest-tint;
            color: $color-primary-lightest-tint;
        }
    }

    &--secondary-v2 {
        color: $color-utility-light;
        border: 1px solid $color-utility-light;
        background: $color-text-white;

        &:hover, &:focus, &:active, &.is-active {
            color: $color-utility-dark;
            border-color: $color-utility-dark;
        }

        &:hover {
            background: $color-gray-white;
        }

        &:focus, &:active, &.is-active {
            background-color: $color-utility-lightest
        }

        &:disabled, &.is-disabled {
            opacity: .4;
        }
    }

    &--small {
        font-size: 14px;
        height: 40px;
        line-height: 20px;
        padding: 0 16px;
    }

    &--destructive {
        color: $color-text-white;
        background: $day-red-dark;

        &:hover {
            background: $color-red;
        }

        &:focus, &:active, &.is-active {
            color: $color-red-light;
        }

        &:disabled, &.is-disabled {
            opacity: 0.4
        }
    }

    &--sm {
        height: 32px;
        font-size: 14px;
        line-height: 20px;
        padding: 0 16px;

        .button__icon {
            font-size: 20px;
            margin: 0 0 0 10px;
        }
    }

    &--xs {
        height: 24px;
        font-size: 12px;
        padding: 0 12px 4px 12px;
        border: none;
        background-color: $day-grey-lightest;
        color: $day-utility-utility;

        &:hover {
            background-color: $day-primary-lightest;
            color: $day-primary-dark;
        }
    }
}
.button__icon {
    display: inline-block;
    font-size: 24px;
    margin: 0 0 0 16px;

    &--left {
        margin: 0 16px 0 0;
    }
}

.button__utility {
    background: transparent;
    color: $day-utility-dark;
    padding: 24px;

    &:hover {
        background: $day-primary-lightest-tint;
        color: $day-utility-utility;
    }

    &:active, &.active, &.is-active {
        background: $day-primary-lightest-tint;
        color: $day-primary-blue;

        &:hover {
            color: $day-primary-dark;
        }
    }

    @include mq(md_down) {
        padding: 18px;
    }
}

.button__ghost {
    // Medium is default size
    color: $day-utility-dark;
    width: 32px;
    height: 32px;
    font-size: 14px;
    background: transparent;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: rgba($day-utility-dark, 0.08);
    }
    &:active, &.active, &.is-active {
        color: $day-primary-blue;
        background-color: rgba($day-primary-blue, 0.16);
    }
    &:focus {
        color: $day-primary-dark;
        background-color: rgba($day-primary-blue, 0.16);
    }

    &--large {
        width: 36px;
        height: 36px;
        font-size: 16px;
    }
    &--small {
        width: 24px;
        height: 24px;
        font-size: 10px;
    }
    &--xsmall {
        width: 24px;
        height: 24px;
        font-size: 8px;
    }
}