﻿.bst-autocomplete {
    position: relative;
    width: 100%;
    border: none;
}

.bst-autocomplete__controls {
    position: relative;
    cursor: text;
    display: flex;
}

.bst-autocomplete__input {
    flex: 1;
    max-width: 100%;
    border: none;
    padding: .5rem;
}

.bst-autocomplete:focus-within {
    box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}

    .bst-autocomplete__input:focus {
        outline: none;        
    }


.bst-autocomplete__down-arrow path {
    fill: #dbdbdb;
}

.bst-autocomplete__input-icon {
    width: 40px;
    display: flex;
    border-radius: 0 5px 5px 0;
    padding: .5rem;
    cursor: pointer;
    background: none;
}

    .bst-autocomplete__input-icon:focus {
        outline: none;
        box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
    }

    .bst-autocomplete__input-icon:hover {
        background-color: #f5f5f5;
    }

        .bst-autocomplete__input-icon:hover .bst-autocomplete__down-arrow path {
            fill: #333333;
        }

.bst-autocomplete__input-icon--disabled,
.bst-autocomplete__input-icon--disabled:hover {
    background-color: #e9ecef;
    cursor: not-allowed;
}

    .bst-autocomplete__input-icon--disabled .bst-autocomplete__down-arrow path,
    .bst-autocomplete__input-icon--disabled:hover .bst-autocomplete__down-arrow path {
        fill: #9c9c9c;
    }

.bst-autocomplete__results {
    position: absolute;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    border: 1px solid #dbdbdb;
    min-height: 2rem;
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
    width: 100%;
    background: #fff;
}

.bst-autocomplete__result,
.bst-autocomplete__notfound,
.bst-autocomplete__results-help-template,
.bst-autocomplete__results-header,
.bst-autocomplete__results-footer {
    padding: .5rem;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
}

.bst-autocomplete__selected-item {
    background-color: #dbdbdb;
}

.bst-autocomplete__selected-item-highlighted {
    background-color: #d3e2f2;
}

.bst-autocomplete__active-item,
.bst-autocomplete__result:hover,
.bst-autocomplete__result:focus {
    background-color: #007bff;
    border-top: 1px solid #007bff;
    border-bottom: 1px solid #007bff;
    color: #fff;
    cursor: pointer;
}
