.find-project__container {
    top: 0;
    left: 0;
    transition: 350ms;
    position: fixed;
    width: 100%;
    z-index: 1005;
    background-color: $day-grey-white;
}

.find-project__header{
    height: 60px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
}

.find-project__header-label {
    flex-grow: 0;
    font-size: 20px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: var(--day-text-default);
}
.find-project__searchbar {
    padding: 8px 24px;
    box-shadow: 0 4px 20px 0 rgb(83 134 184 / 10%);
    background-color: $day-grey-white;
}

.find-project__searchbar-div {
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 4px 8px 4px 16px;
  border-radius: 8px;
  border: solid 1px $day-utility-light;
  background-color: $day-grey-white;
}
.find-project__searchbar-input {
    height: 52px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 8px 4px 16px;
    width: 100%;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: $day-text-default;
}