@import "./bst-icon-font";

$bst-font-family: "Poppins" !default;
$bst-font-path: "./../../fonts/poppins" !default;

$bst-font-secondary: "Open Sans", Arial, Helvetica, sans-serif;
$bst-font-secondary-path: "./../../fonts/open-sans";

$bst-default-font-family: "Poppins", "Open Sans", Arial, Helvetica, sans-serif !default;

@font-face {
  font-display: swap;
  font-family: "#{$bst-font-family}";
  src: url("#{$bst-font-path}/#{$bst-font-family}-Regular.woff2")
      format("woff2"),
    url("#{$bst-font-path}/#{$bst-font-family}-Regular.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "#{$bst-font-family}";
  src: url("#{$bst-font-path}/#{$bst-font-family}-Medium.woff2") format("woff2"),
    url("#{$bst-font-path}/#{$bst-font-family}-Medium.woff") format("woff");
  font-weight: $font-weight-medium;
}
@font-face {
  font-display: swap;
  font-family: "#{$bst-font-family}";
  src: url("#{$bst-font-path}/#{$bst-font-family}-Semibold.woff2")
      format("woff2"),
    url("#{$bst-font-path}/#{$bst-font-family}-Semibold.woff") format("woff");
  font-weight: $font-weight-semibold;
}
@font-face {
  font-display: swap;
  font-family: "#{$bst-font-family}";
  src: url("#{$bst-font-path}/#{$bst-font-family}-Bold.woff2") format("woff2"),
    url("#{$bst-font-path}/#{$bst-font-family}-Bold.woff") format("woff");
  font-weight: $font-weight-bold;
}
@font-face {
  font-display: swap;
  font-family: "#{$bst-font-family}";
  src: url("#{$bst-font-path}/#{$bst-font-family}-Light.woff2") format("woff2"),
    url("#{$bst-font-path}/#{$bst-font-family}-Light.woff") format("woff");
  font-weight: $font-weight-light;
}

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("#{$bst-font-secondary-path}/open-sans-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$bst-font-secondary-path}/open-sans-regular.woff") format("woff"); /* Modern Browsers */
}
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("#{$bst-font-secondary-path}/open-sans-600.woff2") format("woff2"),
    /* Super Modern Browsers */
      url("#{$bst-font-secondary-path}/open-sans-600.woff") format("woff"); /* Modern Browsers */
}
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url("#{$bst-font-secondary-path}/open-sans-800.woff2") format("woff2"),
    /* Super Modern Browsers */
      url("#{$bst-font-secondary-path}/open-sans-regular.woff") format("woff"); /* Modern Browsers */
}
