﻿.l-container--list {
    .info-table__scroll-wrap {
        height: 100%;

        > div {
            height: 100%;
        }

        .e-gridcontent {
            height: calc(100% - 82px) !important;

            .e-content {
                height: 100% !important;
            }
        }

        input,
        .draft-status-field {
            font-size: 14px;
            color: $day-text-default;
        }
    }

    .column-header {
        font-family: $bst-font-family;
    }

    .property {
        font-size: 14px;
        font-family: "Open Sans", Arial, Helvetica, sans-serif;
    }

    .button-group {
        display: none;

        button:first-child {
            margin-right: 8px;
        }
    }

    .e-headercell:hover .button-group,
    .e-headercell .column-header--active .button-group {
        display: block;
        visibility: visible;
    }
}
