﻿.l-drawer {
    @include transition(all 0.25s ease-in-out);
    @include flexbox();
    flex-direction: column;
    height: calc(100vh - #{$layout-header-height});
    overflow: visible;
    max-width: 444px;
    @include box-shadow(2px 0 20px 0 rgba(55, 78, 91, 0.1));
    z-index: 2;

    &--sm {
        width: 324px;
    }

    & .l-subheader {
        padding-top: 36px;
        padding-bottom: 36px;
        min-height: 0 !important;

        & .heading-xl {
            line-height: 44px;
        }

        .icon-button:disabled {
            background-color: $day-grey-lightest;
            border-color: $day-grey-lightest;
            color: rgba(137,148,159,.3);
        }
    }

    @include mq(lg_up) {
        & .l-subheader {
            padding-top: 52px;
            padding-bottom: 64px;
        }
    }

    & .l-container--grid,
    & .l-subheader {
        padding-left: 34px !important;
        padding-right: 34px !important;
    }

    & .l-container--grid {
        padding-top: 0;
    }

    &--dark,
    &--dark > .l-subheader {
        background-color: $color-drawer;
    }

    &--light,
    &--light > .l-subheader {
        background-color: $day-grey-white;
    }

    &.is-closed {
        overflow: hidden;
        width: 0;
    }

    & .card__group-header {
        border-bottom-color: $day-utility-light;
    }

    & .card__group-toggle {
        background-color: $color-drawer;
    }

    .project-card {
        margin-right: 0 !important;
        padding-right: 14px !important;

        h1 {
            @extend .heading-xl;
        }
    }
}
