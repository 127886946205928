.key-factor-details {
  &__title {
    color: $color-text;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    background-color: $day-red-lightest;

    gap: 12px;
    display: flex;
    align-items: center;

    & i {
      color: $day-red-red;
      font-size: 20px;
    }
  }

  &-grid {
    color: $day-utility-utility;
    font-size: 12px;
    font-weight: 500;

    &__header {
      margin-bottom: 32px;
    }

    &__body {
      font-size: 14px;
      overflow: auto;
      max-height: 320px;
    }

    &__row {
      display: flex;
      margin-top: 20px;
      align-items: center;
    }

    &__col {
      flex: 0 0 calc(100% - 190px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:first-child {
        flex-basis: 190px;
      }
    }

    &__employee-details {
      gap: 12px;
      display: flex;
      align-items: center;
      color: $color-text;
      font-size: 16px;

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  &__modal {
    transform: translate(0%, 25%);
    & .modal-dialog {
      max-width: 770px;
    }
    & .modal-header {
      padding: 24px 32px;
    }
    & .modal-body {
      padding: 20px 32px;
      padding-bottom: 38px;
    }
  }
}
