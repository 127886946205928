﻿.fab {
    @include border-radius(50%);
    @include box-shadow(3);
    background-color: $day-accent-purple;
    border: none;
    color: $day-grey-white;
    display: inline-block;
    font-size: 24px;
    height: 64px;
    outline: none;
    padding: 20px;
    position: fixed;
    right: 3.5rem;
    bottom: 3.75rem;
    text-align: center;
    width: 64px;
    z-index: 1000;

    & .path2 {
        color: $day-accent-purple;
    }

    &:hover, &:active, &.is-active {
        background-color: $day-accent-dark;

        & .path2 {
            color: $day-accent-dark;
        }
    }

    &:disabled, &.is-disabled {
        background-color: $day-accent-lightest;
        cursor: default;

        & .path2 {
            color: $day-accent-lightest;
        }
    }
}
