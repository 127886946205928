.summary-top{
    width: 100%;

    .global-utilities__container{
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    &__content{
        @include flexbox();
        width: auto;
        flex-wrap: nowrap;
        align-items: stretch;
        gap: 8.8px;
        padding: 24px 29px;
        background: $day-grey-white;
        box-shadow: $box-shadow-6; 
        @include border-radius(15px);
        @include scrollbars($thumb-background: rgb(64,71,79, 0.3), $mode: 'h', $size: 5px);
    }

    &__block{
        flex: 0 1 auto;
        max-width: 156px;
        min-width: 156px;
        padding: 5px 32px;
        text-align: center;

        .heading-md{
            font-family: $bst-font-family;
            font-weight: $font-weight-light;
            white-space: nowrap;
            margin: 0;
            color:$day-text-default;
        }
        .label-sm{
            margin: 8px 0 0;
            color:$color-utility;
            display: block;
            font-weight: $font-weight-semibold;
            color:$day-utility-utility;
        }

        &:first-child{
            border-right: 1px solid $color-utility-lightest;
            padding-right: 32px;
            padding-left: 0;
            .heading-md{
                font-size: 28px;
                line-height: 28px;
            }
        }
    }

    @include mq(md_up){
        .global-utilities__container{
            padding-top: 2.75rem;
            padding-bottom: 1.5rem;
        }

        &__content{
            gap: 3.5vw;
            padding: 21px 30px;
        }
    
        &__block{
            padding: 15px;
            max-width: max-content;
            min-width: unset;
            &:first-child{
                padding-right: 68px;
                padding-left: 22px;
                .heading-md{
                    font-size: 28px;
                    line-height: 28px;
                }
            }
    
         
        }
    }
    @include mq(lg_up){
        &__content{
            gap: 3.5vw;
        }
    }

    @include mq(xl_up){
        &__content{
            gap: 45px;
        }
    }

    @include mq(md_up){
        .global-utilities__container{
            padding-top: 2.75rem;
            padding-bottom: 1.5rem;
        }

        &__content{
            gap: 3.5vw;
            padding: 21px 30px;
        }
    
        &__block{
            padding: 15px;
            max-width: max-content;
            min-width: unset;
            &:first-child{
                padding-right: 68px;
                padding-left: 22px;
                .heading-md{
                    font-size: 28px;
                    line-height: 28px;
                }
            }
    
         
        }
    }
    @include mq(lg_up){
        &__content{
            gap: 3.5vw;
        }
    }

    @include mq(xl_up){
        &__content{
            gap: 45px;
        }
    }
}