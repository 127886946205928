﻿.column-header {
    position: relative;
    @include flexbox();
    align-items: center;
    justify-items: flex-start;
    box-sizing: border-box;
    min-height: 100%;
    padding: 0 24px;
    flex-wrap: nowrap;
    min-width: 100%;

    div:first-child {
        flex: 1;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .icon-button--ghost {
        background-color: $day-text-white;
    }

    button:not(.is-active) {
        display: none;
    }

    &:hover {
        background-color: $day-utility-lightest;

        .button-group {
            display: block;
        }

        button {
            display: inline-block;
        }
    }

    &--active,
    &:focus-within {
        @extend .column-header;
        background-color: $day-other-drawer !important;
        color: $day-primary-darkest;

        &:hover {
            background-color: $day-other-drawer !important;
        }

        button {
            display: inline-block;
        }
    }

    &.sort-active {
        .button-group {
            display: block;
        }

        button.is-active {
            display: inline-block;
        }
    }
}

.dropdown--column__chooser {
    &.dropdown {
        padding: 0;
    }

    .column-chooser {
        height: auto !important;
        overflow: hidden;
        font-size: 1rem;

        .cb-searchbox {
            box-sizing: border-box;
            @include flexbox();
            height: 44px;
            align-items: center;
            padding: 0 1rem;
            border-bottom: 1px solid $day-utility-lightest;
            @include box-shadow(6);

            &::before {
                font-family: '#{$icomoon-font-family}';
                content: '#{$icon-search}';
                padding-right: .75rem;
                font-size: 1.2rem;
                color: $day-utility-utility;
            }

            .e-input-group {
                border: none !important;
                margin: 0;

                input {
                    font-family: 'Open Sans', sans-serif;
                    font-size: 1rem;
                }

                .e-clear-icon {
                    color: $day-utility-utility;
                }

                &::before,
                &::after {
                    display: none;
                }
            }
        }

        .cb-listbox {
            box-sizing: border-box;
            padding: 0;
            margin-bottom: 8px;
            @include scrollbars(rgba($day-grey-darkest, 0), rgba($day-grey-darkest, 0.3), $size: 12px, $staticH: false);
            max-height: 474px !important;

            .e-listview {
                animation: none !important;

                .e-checklist {
                    box-sizing: border-box;
                    padding: .4rem 1rem;
                    font-size: 1rem;
                    height: auto;

                    &:not(.e-list-group-item) {
                        &:hover,
                        &:active,
                        &[aria-selected="true"] {
                            background-color: $day-grey-white !important;
                        }

                        .e-list-text {
                            padding-left: .75rem;
                            color: $day-text-default;
                        }
                    }

                    &.e-list-group-item {
                        background: $day-grey-light !important;
                        opacity: 1;
                        color: $day-text-default;
                        border: none !important;
                        @include box-shadow(none);
                        padding: 2px 1rem;
                        box-sizing: border-box;
                        height: 44px;
                    }
                }
            }
        }

        .dialog-header {
            height: 60px;
            padding: 1rem;
            @include flexbox();
            align-items: center;
            justify-content: space-between;
            background-color: $day-primary-blue;
            color: $day-grey-white;
            font-size: 18px;
            font-weight: $font-weight-semibold;

            button {
                font-size: inherit;
                color: inherit;
            }
        }
    }
}
