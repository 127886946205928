.menu-logout{
    cursor:pointer !important;
}
.dropdown--menu {
    top: -4px !important;
    -webkit-border-radius: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    width:360px !important;
}
.divAppCloseIcon > .app-home__app-name{
    font-weight:normal !important;
}
.menu-left-bar {
    width: 3px;
    height: 51px;
    background: $color-text-white;
    border-radius: 0 2px 2px 0;
}

.backdrop {
    content: "";
    position: fixed;
    top: -100%;
    left: 0;;
    width: 100%;
    z-index: 1001;
    background: rgba(0, 37, 77, 0.5);
    .app-content {
        display: flex;
        height: 100%;
        flex-direction: column;

        .app-switcher-content {
          margin: auto;   
        }
    }
}

.backdrop.active {
    top: 0;
    left: 0;
    transition: 350ms;
    height: 100%;
}

.menu-container-apps {
    width: 100%;
    height: 100%;
    background-color: $color-blue;
}
.menu-container {
    width: 360px;
    height: 100%;
    position: fixed;
    background-color: $color-blue;
}

.menu-container-primary-section {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 5px;
    background-color: $color-blue;

}
.menu-container-secondary-section {
    bottom: 0;
    width: 360px;
    position: absolute;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 8px;
    padding: 8px 0;
    background-color: $color-blue-dark;
}

.menu-primary-label {
    font-size: 18px;
    font-weight: $font-weight-regular;
    color: $color-text-white;
}

.menu-line-black {
    height: 1px;
    background-color: $color-blue-dark;
}
.menu-primary-label-icon {
    color: $color-text-white;
    font-weight: $font-weight-light;
}
.menu-secondary-label-icon {
    height: 20px;
    flex-grow: 0;
    font-family: $bst-font-family;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.25;
    text-align: left;
    color: $color-text-white;
}
.menu-secondary-label {
    padding-left: 16px;
    font-family: $bst-font-family;
    font-size: 16px;
    color: $color-text-white;
    padding-top: 2px;
}

.menu-dropdown__item_menu {
    text-decoration: none;
    padding: 19px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.menu-picker-row:hover {
    background-color: $color-blue;
}
.menu-parent-section {
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 24px 0 0;
}
.menu-picker-row {
    height: 56px;
    background-color: $color-blue-dark;
}
.menu-line-white {
    height: 1px;
    align-self: stretch;
    flex-grow: 0;
    background-color: $color-text-white;
    
}
.menu-fill-white {
    color: $color-text-white;
    cursor:pointer;
}
.menu-top-section {
    height: 68px;
    padding-top: 18px;
    padding-bottom: 17px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    color: $color-text-white;
}
.menu-close-icon {
    padding-top: 8px;
    padding-right: 8px;
    font-size: 22px;
    font-weight: $font-weight-light;
}
.menu-app-close-icon {
    padding-right: 22px;
    font-size: 24px;
}
.menu-title {
    color: $color-text-white;
    font-weight: $font-weight-medium;
}
.menu-title-apps {
    color: $color-text-white;
    font-weight: normal;
    font-size: 26px;
}
.menu-change-icon-inside {
    width: 15.8px;
    height: 15.8px;
    background-color: $color-text-white;
}
.menu-apps-content-text {
    height: 112px;
    flex-grow: 0;
    margin: 20.6px 18px 0 19px;
    font-family: $bst-font-family;
    font-size: 18px;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
}