﻿.button-totask {
    position: absolute;
    white-space: nowrap;
    border: none;
    @include border-radius(1rem);
    @include box-shadow(3);
    background-color: $day-grey-white;
    color: $day-utility-dark !important;
    font-weight: $font-weight-regular;
    min-width: 2rem;
    box-sizing: border-box;
    padding: .475rem .5rem;
    height: 2rem;

    & > span {
        line-height: 1rem;

        & > span {
            font-size: .75rem;
            vertical-align: middle;
            margin: 0 .5rem;
            display: none;
        }
    }

    &:hover {
        & > span > span {
            display: unset;
        }
    }

    & .icon {
        vertical-align: middle;
        font-size: 1rem;
        color: $day-utility-utility !important;
    }
}

.gantt-toolbar {
    position: absolute;
    top: .5rem;
    right: .5rem;
    z-index: 1;
}

.e-checkbox-wrapper {
    span.e-frame.e-icons {
        color: day-grey-white !important;
        border-color: $day-utility-utility !important;
        background-color: $day-grey-white !important;
        @include border-radius(4px);

        &.e-check {
            border-color: $day-primary-blue !important;
            background-color: $day-primary-blue !important;
        }
    }
}

.floating-menu {
    position: absolute;
    z-index: 10;
    overflow: visible !important;
    padding-bottom: 4px;

    &--bottom {
        bottom: -20px;
        right: 24px;
    }

    &--top {
        right: 24px;
        top: -40px;
    }

    &--taskbar {
        @extend .floating-menu;
        bottom: 28px;
        display: block;
        height: 41px;
        white-space: nowrap;
        cursor: pointer;

        button {
           padding-top: 8px;
        }

        button:first-child {
            margin-right: 8px;
        }
    }
}

.project-header__dropdown-wrap {
    position: relative;
    margin-left: 12px;

    .dropdown {
        right: 0;
        top: calc(100% + 5px);
    }

    .dropdown__item {
        width: 100%;
    }
}