.error-modal{
    display: flex !important;
    flex-direction: column;
    z-index:2001;
    padding: 90px 75px 10px  !important;

    @include mq(md_down) {
        padding: 70px 51px 10px 51px !important;
    }
    @include mq(sm_down) {
        padding: 60px 19px 10px !important;
    }

    .modal-content{
        padding: 75px 48px;
        border-radius: 16px;
        box-shadow: 0 20px 32px 0 rgba(0, 37, 77, 0.12);
        background-color: $day-grey-white;
        width: 100%;
    
        @include mq(sm_down) {
            padding: 50px 48px;
        }
    }

}



.error-modal-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap:16px;
}

.error-modal-header{
    align-self: stretch;
    font-size: 40px;
    line-height: 1.1;
    letter-spacing: -1px;
    text-align: center;
    color: $day-text-default;

    @include mq(sm_down) {
        font-size:28px;
        padding: 4.5px 18px 11.5px 8px;
    }
}

.error-modal-subheader{
    align-self: stretch;
    font-size: 18px;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: $day-utility-utility;

    @include mq(md_down) {
    }
    @include mq(sm_down) {
        font-size:18px;
    }
}
