﻿.suggestion-list {
    list-style: none;
    margin: 0;
    padding: 0;
}
.suggestion-list__content {
    font-size: 14px;
    margin: 0 0 16px;
}
.suggestion-list__icon {
    @include border-radius(50%);
    align-content: center;
    background-color: $color-primary-lightest;
    color: $color-utility;
    display: grid;
    font-size: 16px;
    height: 24px;
    justify-content: center;
    width: 24px;
}
.suggestion-list__item {
    display: grid;
    grid-column-gap: 16px;
    grid-auto-flow: column;
    margin: 0 8px 16px 0;
}
.suggestion-list__link {
    color: $color-utility;
    font-size: 16px;
}