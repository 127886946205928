﻿/* show/hide */
.u-hide-xxs { @include mq(xxs) { display: none !important; }}
.u-hide-xs-up { @include mq(xs_up) { display: none !important; }}
.u-hide-sm-down { @include mq(sm_down) { display: none !important; }}
.u-hide-sm-up { @include mq(sm_up) { display: none !important; }}
.u-hide-md-down { @include mq(md_down) { display: none !important; }}
.u-hide-md-up { @include mq(md_up) { display: none !important; }}
.u-hide-lg-down { @include mq(lg_down) { display: none !important; }}
.u-hide-lg-up { @include mq(lg_up) { display: none !important; }}
.u-hide-xl-down { @include mq(xl_down) { display: none !important; }}
.u-hide-xl { @include mq(xl) { display: none !important; }}
.u-hide { display: none !important; }

/* display */
.u-display-block {
    display: block !important;
}
.u-display-inline {
    display: inline !important;
}
.u-display-inline-block {
    display: inline-block !important;
}

/* spacing */
.u-pad-bottom-rm {
    padding-bottom: 0 !important;
}

.u-pad-left-rm {
    padding-left: 0 !important;
}

.u-pad-right-rm {
    padding-right: 0 !important;
}

/* layout */
.u-justify-self-end {
    justify-self: end;
}
.u-overflow-hide {
    overflow: hidden;
}
.u-position-relative {
    position: relative !important;
}
.u-scroll-h {
    @include scrollbars($mode: 'h');

    @include mq(md_down) {
        @include scrollbars($mode: 'h', $size: 12px);
    }

    @include mq(sm_down) {
        @include scrollbars($mode: 'h', $size: 0);
    }
}
.u-scroll-v {
    @include scrollbars($mode: 'v');

    @include mq(md_down) {
        @include scrollbars($mode: 'v', $size: 12px);
    }

    @include mq(sm_down) {
        @include scrollbars($mode: 'v', $size: 0);
    }
}

/* animations */
.u-animate {
    animation-fill-mode: both;
    position: absolute;
    @media print, (prefers-reduced-motion: reduce) {
            animation-duration: 1ms !important;
            transition-duration: 1ms !important;
            animation-iteration-count: 1 !important;
    }
}
.u-animate-fade-in {
    @include animation(fade-in);
}
.u-animate-fade-out {
    @include animation(fade-out);
}
.u-loading {
    background-color: $day-primary-lightest;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80px auto;
    opacity: 1.0;
    z-index: 10;
    height: 100%;
    overflow: hidden;
    width: 100% !important;

    * {
        opacity: 0;
    }
}

/* text alignment */
.u-text-align-center {
    text-align: center !important;
}
.u-text-align-left {
    text-align: left !important;
}
.u-text-align-right {
    text-align: right !important;
}

/* text color */
.u-color-utility {
    color: $day-utility-utility;
}

/* text font weight */
.u-text-regular {
    font-weight: $font-weight-light !important;
}
.u-text-medium {
    font-weight: $font-weight-regular !important;
}
.u-text-semibold {
    font-weight: $font-weight-medium !important;
}
.u-text-bold {
    font-weight: $font-weight-semibold !important;
}

/* text truncation */
.u-text-nowrap {
    white-space: nowrap !important;
}
.u-text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap !important;
}