﻿.l-main {
    @include flexbox();
    margin: 0;
    padding: 0;
    width: 100%;
}
.l-main__content {
    @include flexbox();
    flex-direction: column;
    flex: 1;
    height: calc( 100vh - #{$layout-header-height});
    overflow: auto;
    padding: 0;
    position: relative;
    width: 100%;
    background-color: $day-primary-lightest;
}

