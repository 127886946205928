﻿.global-utilities {
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
  justify-content: start;
  margin: 0;
  padding: 0;
}
.global-utilities__avatar {
  @include border-radius(50%);
  @include box-shadow(1);
  border: 1px solid $day-grey-white;
  height: 34px;
  overflow: hidden;
  padding: 0;
  width: 34px;
  &:active {
    @include box-shadow(0);
  }
}
.global-utilities__button {
  color: $day-utility-dark;
  cursor: pointer;
  height: $layout-header-height;
  padding: 0 24px;

  &:hover,
  &.is-active {
    background-color: $day-primary-lightest-tint;
    color: $day-utility-utility;
  }

  &.is-active {
    color: $day-primary-blue;
  }

  &--highlight {
    background-color: $day-primary-blue;

    .global-utilities__button__icon {
      color: $day-grey-white;
    }

    &:hover,
    &.is-active {
      background-color: $day-primary-tint;
      color: $day-primary-lightest;
    }

    &:active {
      background-color: $day-primary-dark;
      color: $day-grey-white;
    }
  }
}
.global-utilities__button__icon {
  & > .icon-profile {
    font-size: 1.5rem;
  }

  & > .icon-all-apps {
    font-size: 1.8rem;
  }

  & > .icon-menu {
    font-size: 1.5rem;
  }
}
.global-utilities__item {
  position: relative;
}

.global-utilities__employee-avatar {
  display: flex;
  align-items: center;
  border-radius: 100%;
  box-shadow: 0px 4px 8px 1px #363b641f;
  justify-content: center;
  color: $day-grey-white;
  background-color: $day-utility-utility;
  width: 32px;
  height: 32px;
  font-size: 14px;
  font-weight: 600;

  & img {
    clip-path: circle(50%);
  }
}
