.banner-div {
    display: flex;

    margin: 12px;
    padding: 12px 16px;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    background: $day-orange-lightest;

    @include mq(sm_down){
        margin: 8px;

    }

    .banner-alert-icon {
        color: $day-orange-orange;
        font-size: 20px;
    }

    .banner-label {
        color: $day-text-default;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
.banner {
    visibility: hidden;
    background: $day-grey-white;
}
.banner-show{
    height: 68px;
    visibility: visible;
    @include mq(sm_down){
        height:88px;

    }
    transition: height 0.5s ease-in-out;
}

.banner-hide{
    height: 0px;;
    visibility: hidden;
    transition: height 0.5s ease-in-out;
}