﻿.l-container {
    width: 100%;

    @include mq(sm_down) {
        padding: 16px;
    }

    &--grid {
        height: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(390px, 1fr));
        align-content: start;
        align-items: start;
        justify-content: start;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 4.5rem;
        padding-right: 3rem;
        text-align: left;

        & > div:not(.project-card, .virtualize-spacer_after),
        & > .card__group > div:not(.project-card, .virtualize-spacer_after) {
            grid-column: 1 / -1;
            margin-right: 1.5rem;

            @include mq(md_down) {
                margin-right: 1rem;
            }
        }

        & > .project-card,
        & > .virtualize-spacer_after,
        & > .card__group > .project-card,
        & > .card__group > .virtualize-spacer_after {
            box-sizing: border-box;
            margin: 0 1.5rem 1.5rem 0;

            @include mq(md_down) {
                margin: 0 1rem 1rem 0;
            }
        }

        @include mq(xl_down) {
            padding-left: 4rem;
            padding-right: 2.5rem;
        }

        @include mq(lg_down) {
            padding-left: 2.75rem;
            padding-right: 1.5rem;
        }

        @include mq(md_down) {
            padding-left: 2rem;
            padding-right: 1rem;
        }

        @include mq(sm_down) {
            grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
            padding-left: 1rem;
            padding-right: 0;
        }
    }

    &--grid-horizontal {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 24px;
        justify-content: start;
        padding: 0 24px 8px;
        overflow: auto;

        @include mq(sm_down) {
            grid-gap: 16px;
            padding: 16px;
        }
    }

    &--list {
        position: absolute;
        top: 152px;
        bottom: 0;
        left: 0;
        width: 100%;

        @include mq(lg_down) {
            top: 108px;
        }
    }

    &--padding-sm {
        padding: 24px 16px;
    }

    &--gantt {
        flex: 1 0 100%;
        min-height: 0;
        @include flexbox();
        flex-direction: column;
        align-content: start;
        align-items: start;

        & > .l-subheader {
            & .icon-button--ghost {
                top: -2px !important;
                padding: 0.38rem;

                & > .icon {
                    font-size: 24px;
                }
            }

            .handle--left {
                z-index: 2;
            }
        }
    }

    &--flex {
        @include flexbox();
        flex-direction: row;
        min-height: 0;
        position: relative;
        padding-bottom: .5rem;
        height: 100%;
        overflow: hidden;

        & > .u-loading {
            background-position: center calc(50% - #{$layout-header-height});
        }
    }
}
