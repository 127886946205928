.divsummary {
    display: flex;
    flex-direction: column;
    background-color:$day-grey-white;
}

.divsummary-hide {
    display: none;
}


.filter-buttongroup {
    border-radius: 200px;
    background: $day-grey-white !important;
}
.filter-header {
    display: flex;
    padding: 20px 40px;
    align-items: center;
}
.filter-right {
    margin-left: auto;
}
.filter-button {
    border: unset;
    background-color: $day-grey-white;
    border-radius: 200px;
    margin-right: 10px;
}
.filter-label {
    font-size: 24px;
}
.filter-text {
    border:none;
    border-radius: 200px;
    min-width: 350px;
    margin-right: 10px;
    text-indent: 35px;
}
.filter-text:focus {
    outline: none;
}

.filter-search-icon {
    position: absolute;
    padding-left: 10px;
    padding-top: 10px;
    margin: 0px;
    height: 25px;
}

.main-content{
    display: flex !important;
    flex-direction: column;

    &.is-active {
        margin-left:352px;
    }
}

input[type=search]::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
    appearance: searchfield-cancel-button;
}
.flex{
    padding: 15px;
    display: flex !important;
    justify-content: center;
}

.disable__scrollbar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
    &::-webkit-scrollbar {
      background: transparent; /* Chrome/Safari/Webkit */
      width: 0px;
    }
  }



.l-subheader--sticky {
    position: -webkit-sticky;
    position: sticky;
    top:0;
    width: 100%;
    transform: translate3d(0,0,0);
}

.pbicards{
    display: flex;
    flex-direction: column;
}

.main-content--sticky{

    padding-top: $layout-header-height;

    @include mq(lg_down) {
        padding-top: $layout-header-height-md;
    }
    @include mq(sm_down) {
        padding-top: 0;
    } 
    
}

.filter--sticky {
    top: $layout-subheader-height + $layout-header-height;
    position: fixed !important;
    margin-top: 0px !important;

    @include mq(lg_down) {
        top: $layout-subheader-height-md + $layout-header-height-md;
    }
    @include mq(sm_down) {
        top: $layout-subheader-height-sm + $layout-header-height-sm;
    } 
}
.projects-none {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:570px;
    padding-top: 76px;

    img {
        padding-bottom: 32px;
    }
    &__header {
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 28px;
        color: $day-text-default;
        text-align: center;
        padding: 0px 40px 12px 40px;    
    }
    &__description {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        color:$day-utility-utility;
        text-align: center;
        padding: 0px 40px;
    }
}
