.app-settings {

    &__modal {
        display: flex;
        height: 100%;
        width: 100%;
        overflow: hidden;
        .modal-body {
            height: 100%;
        }
    }

    &__body {
        display: flex;
        padding: 0px;
    }

    &__content {
        background-color: $day-primary-lightest;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 172px;
        overflow: auto;
        @include scrollbars(rgba($day-utility-dark, 0), rgba($day-utility-dark, 0.3), $staticV:false, $staticH:false, $mode: 'v', $size: 24px);
 
    }

    &__sidebar{
        min-width: 288px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        gap: 16px;
        padding: 44px 0 0;
        box-shadow: 2px 0 20px 0 rgba(55, 78, 91, 0.1);
        background-color: #fff;
        min-width: 288px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        gap: 16px;
        padding: 44px 0 0;
        box-shadow: 2px 0 20px 0 rgba(55, 78, 91, 0.1);
        background-color: #fff;

        .sidebar {
            &--top-level {
                height: 56px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                padding: 16px 24px;
                color:$day-utility-utility;

                i {
                  font-size: 22px;  
                }
                span {
                    height: 24px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-end;
                    gap: 12px;
                    padding: 0;
                }

                height: 56px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                padding: 16px 24px;
                color:$day-utility-utility;

                i {
                  font-size: 22px;  
                }
                span {
                    height: 24px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-end;
                    gap: 12px;
                    padding: 0;
                }

            }
    
            &--second-level {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                padding: 0 24px 0 0;
            }

            &--second-level-item {
                padding-left: 72px;
                font-size: 16px;
                height: 56px;
                line-height: 48px;
                width: 100%;
                border: 4px solid transparent;
                color: $day-utility-utility;

                &.is-active {
                    font-weight: $font-weight-medium;
                    color: $day-primary-dark;
                    border-left: 4px outset $day-primary-blue;
                }
            }
        }

        .sidebar--active-state {
            width: 4px;
            height: 56px;
            margin: 0 20px 0 0;
            background-color: $day-primary-blue;
        }

        .sidebar--active-state {
            width: 4px;
            height: 56px;
            margin: 0 20px 0 0;
            background-color: $day-primary-blue;
        }
    }  
}