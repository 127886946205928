﻿.override-counter {
    @include flexbox;
    align-items: center;

    &__inner {
        @extend .label-sm;
        color: $day-utility-utility;
        background: $day-grey-lightest;
        @include border-radius(200px);
        padding: 8px 12px;

        &:before {
            content: '';
            display: inline-block;
            width: 12px;
            height: 12px;
            background: $day-primary-blue;
            @include border-radius(50%);
            margin: 0 6px -1px 0;
        }
    }
}

.override-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $day-primary-blue;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}