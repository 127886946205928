 #l-main-content-principal.no-overflow {
     overflow: hidden !important;
 }

.user-management {
    background-color: $day-primary-lightest;
    .l-subheader {
    &.user-management-subheader {
        min-height: 125px;

        @include mq(lg_down) {
            min-height: 108px;
        }
    }
        &--fixed {
            position: fixed !important;
            top: 95px !important;
            left: 0;
            z-index: 999;
        width: 100%;
        @include mq(md_down) {
            top: 75px !important;
        }

        }
    }

    .grid-container-outside{
        position: static;
        &--fixed{
            position: fixed;
            top: 220px;
            left: 0;
            width: 100%;
            @include mq(lg_down) {
                top: 202px;
            }
            @include mq(md_down) {
                top: 184px;

                .l-container {
                    padding: 0;
                }
            }
        }

        .e-headercontent,
        .e-gridcontent {
            .e-skeleton {
                height: 14px;
                @include border-radius(8px);
                background: rgba($day-utility-utility, 0.2);
            }
        }

        .e-frozencontent {
            td:nth-child(2) {
                .e-skeleton {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    margin-left: -2px;
                }
            }
        }
        .circle-skeleton {
            .e-skeleton {
                width: 24px;
                height: 24px;
                border-radius: 30%;
                margin-left: -2px;
            }
        }
    }
        .e-checkbox-wrapper, .e-css.e-checkbox-wrapper {
        display: flex; align-items: center;
        justify-content: center;
        min-width: 60px;
        label {
            display: inline-block!important;}
        }

    .e-checkbox-wrapper {
        .e-frame {
            @include appearance(none);
            outline: none;
            box-sizing: border-box;
            overflow: visible !important;
            width: 20px;
            height: 20px;
            flex-grow: 0;
            z-index:2;
            border-radius: 4px;
            border: solid 2px $day-utility-utility;
            border: 2px solid $day-utility-utility;
            @include border-radius(4px);
            position: static;
            &::after {
                content: '';
                position: absolute;
                transform: translate(0, 0);
                top: -8px;
                left: -8px;
                width: 32px;
                height: 32px;
                @include border-radius(50%);
                background: $day-primary-blue;
                opacity: 0;
            }
            &:hover, &:focus-within {
                position: relative;
                &::after {
                    opacity: 0.08;
                }
            }
            &:active {
                position: relative;
                &::after {
                    opacity: 0.12;
                }
            }
            &:disabled {
                border-color: $day-utility-light;
                &::after {
                    display: none;
                }
                &:checked {
                    border-color: $day-utility-light;
                    background-color: $day-utility-light;
                }
            }
        }

        input[type="checkbox"]:checked ~ .e-frame {
            border-color: $day-primary-blue;
            background-color: $day-primary-blue;
            &::before {
                font-family: '#{$icomoon-font-family}';
                content: '#{$icon-check}';
                margin: 0 0 1px 1px;
                font-weight: $font-weight-bold;
                color: $day-grey-white;
                position: absolute;
                top: 50%;
                @include transform(translate(-50%, -50%));
            }
        }

        .e-ripple-container {
            display: none !important;
        }
    }
    .e-columnheader input[type="checkbox"]:checked ~ .e-frame::before {
        top: 43%;
    }
}

#user-management-hyperfocus {display: block;}
.add-user-modal-content {width: 100%;}
.input-icon .e-multiselect .e-chips-collection .e-chipcontent, 
.e-dropdownbase .e-list-item, .e-dropdownbase .e-list-item.e-item-focus {
    font-family: $bst-font-family !important;
}

.invitations-modal{
    margin: 0 auto !important;
    padding: 15px !important;
    top: 0 !important;
    left: 0 !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    z-index: 2001;
    .input-icon--active {margin-top: 0;}
    .heading-xxl{color: $color-text; margin:0 0 1rem; white-space: normal;}
    .lead{color: $day-utility-utility; font-weight: $font-weight-light;  margin: 0 0 3rem;}
    .DNA-button{  min-width: 160px; margin: auto; text-transform: uppercase}
    &__content{
        @include border-radius(1rem);
        @include box-shadow(0px 20px 32px rgba(0, 37, 77, 0.12));
    }
    &__dialog{
         width: 100%;
         max-width: 47.3rem;
         margin: auto !important;
        padding: 0 !important;
        text-align: center;
    }

    &__body.modal-body {
        padding: 6.5rem 3rem;
        gap: 0;
    }

  @include mq(xl_down) {
      &__body{
        padding: 6rem 2.5rem;
    }
}

    @include mq(lg_down) {
        &__body{
         padding: 5rem 2.5rem;
        }
    }

    @include mq(md_down) {
        &__body{
         padding: 3rem 2rem;
        }
    }

    @include mq(xxs) {
        &__body{
         padding: 2rem 1rem;
        }
    }


}