﻿.alert {
    flex: 0 1 44px;
    align-self: stretch;
    @include border-radius(8px);
    @include flexbox();
    align-items: center;
    box-sizing: border-box;
    padding: 4px 1rem;
    background-color: $day-orange-lightest;
    margin: .75rem .75rem 0 .75rem;
    padding: 12px 16px;
    &.position-relative {
        z-index: 1000;
        position: relative;
    }
    span {
        color: $day-text-default;
        font-size: 14px;
        font-weight: $font-weight-semibold;

        .alert_highlight {
            color: $day-primary-blue;
            text-transform: capitalize;
        }
    }

    & > span {
        width: 100%;
    }

    .icon {
        font-size: 1.2rem;
    }

    & > .icon {
        flex-grow: 0;
        color: $day-orange-orange;
        margin-right: .75rem;
    }

    &--dismissable,
    &--success,
    &--error {
        @extend .alert;
    }

    &--dismissable {
        button {
            padding: 0;
            flex-grow: 0;
            margin-left: 1rem;

            .icon {
                color: $day-utility-utility;
            }
        }
    }

    &--success {
        background-color: $day-green-lightest;

        & > .icon {
            color: $day-green-green;
        }
    }

    &--error {
        background-color: $day-red-lightest;

        & > .icon {
            color: $day-red-red;
        }
    }
}

.toast {
    position: fixed;
    right: 5rem;
    bottom: 8.5rem;
    height: 56px;
    z-index: 1000;
    line-height: 56px;
    vertical-align: middle;
    @include border-radius(8px);
    @include box-shadow(4);
    padding: 0 24px;
    border-left: 8px solid $day-accent-purple;
    background-color: $day-grey-white;
    font-size: 1rem;
    font-weight: $font-weight-medium;
}