.pbicards {
    &__skeleton_div {
        background-color: #FFFF;
        border-radius: 8px;
        height: 366px;
        width: calc(100% - 144px);
        margin: 0px 72px;

        @include mq(lg_down){
            width: calc(100% - 64px);
            margin: 32px 32px;
        }
        @include mq(sm_down){
            width: calc(100% - 48px);
            margin: 24px 24px;
        }
        @include mq(xs_down){
            width:calc(100% - 32px);
            margin: 44px 16px;
        }
    }
    &__skeleton {
        width: 100%;
        height: 366px;
        @include animation(backgroundPosition, 2s, linear, 0s, infinite);
        
        &_rectangle1{
            x:32;
            y:35;
            rx:8;
            ry:8;
            width:98px;
            height:16px;
            background: $day-primary-lightest-tint linear-gradient(to right, rgba($day-utility-lightest, 0) 0%, rgba($day-utility-lightest, 0.16) 25%, $day-utility-lightest 50%, rgba($day-utility-lightest, 0.16) 75%, rgba($day-utility-lightest, 0) 100%);
        }
        &_rectangle2{
            x:32;
            y:59;
            rx:8;
            ry:8;
            width:283px;
            height:28px;
            background: $day-primary-lightest-tint linear-gradient(to right, rgba($day-utility-lightest, 0) 0%, rgba($day-utility-lightest, 0.16) 25%, $day-utility-lightest 50%, rgba($day-utility-lightest, 0.16) 75%, rgba($day-utility-lightest, 0) 100%);
        }
        &_rectangle3{
            x:32;
            y:95;
            rx:48;
            ry:48;
            width:32px;
            height:32px;
            background: $day-primary-lightest-tint linear-gradient(to right, rgba($day-utility-lightest, 0) 0%, rgba($day-utility-lightest, 0.16) 25%, $day-utility-lightest 50%, rgba($day-utility-lightest, 0.16) 75%, rgba($day-utility-lightest, 0) 100%);
        }
        &_rectangle4{
            x:76;
            y:105;
            rx:8;
            ry:8;
            width:98px;
            height:12px;
            background: $day-primary-lightest-tint linear-gradient(to right, rgba($day-utility-lightest, 0) 0%, rgba($day-utility-lightest, 0.16) 25%, $day-utility-lightest 50%, rgba($day-utility-lightest, 0.16) 75%, rgba($day-utility-lightest, 0) 100%);
        }
        &_rectangle5{
            x:434;
            y:47;
            rx:8;
            ry:8;
            width:1px;
            height:272px;
            background: $day-primary-lightest-tint linear-gradient(to right, rgba($day-utility-lightest, 0) 0%, rgba($day-utility-lightest, 0.16) 25%, $day-utility-lightest 50%, rgba($day-utility-lightest, 0.16) 75%, rgba($day-utility-lightest, 0) 100%);
        }
        &_rectangle6{
            x:32;
            y:139;
            rx:8;
            ry:8;
            width:370px;
            height:192px;
            background: $day-primary-lightest-tint linear-gradient(to right, rgba($day-utility-lightest, 0) 0%, rgba($day-utility-lightest, 0.16) 25%, $day-utility-lightest 50%, rgba($day-utility-lightest, 0.16) 75%, rgba($day-utility-lightest, 0) 100%);
        }
        &_rectangle7{
            x:466;
            y:32;
            rx:8;
            ry:8;
            width:958px;
            height:302px;   
            background: $day-primary-lightest-tint linear-gradient(to right, rgba($day-utility-lightest, 0) 0%, rgba($day-utility-lightest, 0.16) 25%, $day-utility-lightest 50%, rgba($day-utility-lightest, 0.16) 75%, rgba($day-utility-lightest, 0) 100%);
        }

        @include mq(md_down){
            width: 100%;
            height: 366px;

            &_rectangle1{
                x:24;
                y:24;
                rx:8;
                ry:8;
                width:98px;
                height:16px;
            }
            &_rectangle2{
                x:24;
                y:48;
                rx:8;
                ry:8;
                width:348px;
                height:28px;
            }
            &_rectangle3{
                x:24;
                y:84;
                rx:48;
                ry:48;
                width:32px;
                height:32px;
                
            }
            &_rectangle4{
                x:68;
                y:94;
                rx:8;
                ry:8;
                width:98px;
                height:12px;
            }
            &_rectangle5{
                x:24;
                y:128;
                rx:8;
                ry:8;
                width:calc(100% - 48px);
                height:140px;
            }
            &_rectangle6{
                x:0;
                y:284;
                rx:8;
                ry:8;
                width:100%;
                height:84px;
            }
            &_rectangle7{
                display: none; 
            }
        }
    }   
}