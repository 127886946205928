.my-settings {
  &-modal {
    .modal-body {
      height: 100%;
      scroll-behavior: smooth !important;
    }
    .app-settings__sidebar {
      padding-top: 64px;

      @include mq(md_down) {
        width: 72px;
        min-width: 72px;
      }

      .sidebar--second-level {
        cursor: pointer;

        @include mq(md_down) {
          padding: 0;
        }

        &-item {
          gap: 12px;
          display: flex;
          align-items: center;
          padding-left: 24px;

          @include mq(md_down) {
            padding: 0px;
            justify-content: center;
          }

          & .icon {
            font-size: 24px;
          }

          @include mq(md_down) {
            & span {
              display: none;
            }
          }
        }
      }
    }
  }

  &__content-body {
    margin: 64px 64px 0 64px;
    padding: 48px;
    border-radius: 16px;
    background-color: $day-grey-white;
  }

  &-mobile {
    & .modal-body {
      gap: 0;
      height: 100%;
    }
    &__header-title {
      display: flex;
      justify-content: space-between;
      padding: 0 16px;
      padding-top: 24px;
      font-size: 24px;
      font-weight: 300;
    }

    &__header {
      width: 100%;
      border-bottom: 1px solid $day-utility-lightest;
    }

    &__tabs {
      display: flex;
      gap: 24px;
      padding: 0 12px;
      margin-top: 24px;
      font-size: 16px;
      font-weight: 400;
      color: $day-utility-utility;
    }

    &__tab {
      cursor: pointer;
      border: 4px solid transparent;

      &.is-active {
        color: $day-primary-dark;
        border-bottom: 4px outset $day-primary-blue;
      }
    }

    &__body {
      width: 100%;
      height: 100%;
      overflow-y: hidden;
    }

    &__content {
      width: 100%;
      height: 100%;
      position: relative;
      &-body {
        width: 100%;
        height: 100%;
        padding: 24px;
        position: absolute;
        // for horizontal scroll navigation between settings screen
        $elements: 15;
        @for $i from 0 to $elements {
          &:nth-child(#{$i + 1}) {
            left: $i * 100%;
          }
        }
      }
    }
  }
}

.profile-section {
  &__avatar {
    position: relative;

    &-edit {
      width: 36px;
      height: 36px;
      color: $day-utility-utility;
      border: 1px solid $day-utility-lightest;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $day-grey-white;
      position: absolute;
      right: -10px;
      bottom: -10px;
    }
  }

  &__header {
    gap: 24px;
    display: flex;
    align-items: center;
    margin-bottom: 48px;
  }

  &__user {
    &-name {
      color: $day-text-default;
      font-size: 28px;
      font-weight: $font-weight-light;
    }
    &-role {
      color: $day-utility-utility;
      font-size: 16px;
      font-weight: $font-weight-medium;
    }
  }

  &__item {
    font-size: 16px;
    margin-bottom: 36px;

    &-value {
      color: $day-text-default;
      font-weight: $font-weight-light;
      margin-top: 12px;
      padding-left: 36px;
    }

    &-label {
      gap: 12px;
      display: flex;
      align-items: center;
      color: $day-utility-utility;
      font-weight: $font-weight-medium;

      .icon {
        font-size: 24px;
      }
    }
  }
}
