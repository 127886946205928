$icomoon-font-family: "bst-icon-font" !default;
$icomoon-font-path: "./../../fonts/icon-font" !default;

$icon-dependency: "\e900";
$icon-activity2: "\e901";
$icon-add: "\e902";
$icon-add_small: "\e903";
$icon-add-column: "\e904";
$icon-alert: "\e905";
$icon-analytics_solid: "\e906";
$icon-apps: "\e907";
$icon-archive: "\e908";
$icon-arrow-down: "\e909";
$icon-arrow-left: "\e90a";
$icon-arrow-negative_solid: "\e90b";
$icon-arrow-positive_solid: "\e90c";
$icon-arrow-right: "\e90d";
$icon-arrow-up: "\e90e";
$icon-attachment: "\e90f";
$icon-back: "\e910";
$icon-baseline: "\e911";
$icon-bids_solid: "\e912";
$icon-billing_solid: "\e913";
$icon-blueprint: "\e914";
$icon-bold: "\e915";
$icon-bold_small: "\e916";
$icon-bst_solid: "\e917";
$icon-budget: "\e918";
$icon-building-1: "\e919";
$icon-building-2: "\e91a";
$icon-building-3: "\e91b";
$icon-building-4: "\e91c";
$icon-building-5: "\e91d";
$icon-bullets: "\e91e";
$icon-calendar: "\e91f";
$icon-usd: "\e920";
$icon-camera: "\e921";
$icon-cards: "\e922";
$icon-change-orders_solid: "\e923";
$icon-check: "\e924";
$icon-check_small: "\e925";
$icon-chevron-down: "\e926";
$icon-chevron-down_micro: "\e927";
$icon-chevron-left: "\e928";
$icon-chevron-left_micro: "\e929";
$icon-chevron-right: "\e92a";
$icon-chevron-right_micro: "\e92b";
$icon-chevron-up: "\e92c";
$icon-chevron-up_micro: "\e92d";
$icon-client: "\e92e";
$icon-close: "\e92f";
$icon-close_micro: "\e930";
$icon-close_small: "\e931";
$icon-company: "\e932";
$icon-cone: "\e933";
$icon-construction_1: "\e934";
$icon-construction_2: "\e935";
$icon-copy: "\e936";
$icon-crop: "\e937";
$icon-delete: "\e938";
$icon-delivery: "\e939";
$icon-diamond: "\e93a";
$icon-display: "\e93b";
$icon-dot_solid: "\e93c";
$icon-double-arrow-down: "\e93d";
$icon-double-arrow-up: "\e93e";
$icon-download: "\e93f";
$icon-drag: "\e940";
$icon-drag-1: "\e941";
$icon-duration: "\e942";
$icon-edit: "\e943";
$icon-email: "\e944";
$icon-emoji: "\e945";
$icon-expand: "\e946";
$icon-expand_horizontal: "\e947";
$icon-external: "\e948";
$icon-uniE94A: "\e949";
$icon-uniE94B: "\e94a";
$icon-filter: "\e94b";
$icon-flag: "\e94c";
$icon-flag_solid: "\e94d";
$icon-forbidden: "\e94e";
$icon-forward: "\e94f";
$icon-gantt: "\e950";
$icon-general: "\e951";
$icon-hand: "\e952";
$icon-hand_solid: "\e953";
$icon-hash: "\e954";
$icon-help: "\e955";
$icon-home: "\e956";
$icon-house-check: "\e957";
$icon-insights: "\e958";
$icon-invoice_solid: "\e959";
$icon-italic: "\e95a";
$icon-jump-left: "\e95b";
$icon-jump-right: "\e95c";
$icon-language: "\e95d";
$icon-layers: "\e95e";
$icon-like: "\e95f";
$icon-like_solid: "\e960";
$icon-link: "\e961";
$icon-link_small: "\e962";
$icon-link-broken: "\e963";
$icon-list: "\e964";
$icon-logout: "\e965";
$icon-magic: "\e966";
$icon-menu: "\e967";
$icon-message: "\e968";
$icon-more_solid: "\e969";
$icon-more-actions: "\e96a";
$icon-notification: "\e96b";
$icon-opportunities_solid: "\e96c";
$icon-paper-clip: "\e96d";
$icon-paste: "\e96e";
$icon-people_solid: "\e96f";
$icon-percent: "\e970";
$icon-phone: "\e971";
$icon-pin: "\e972";
$icon-point-down_solid: "\e973";
$icon-point-up_solid: "\e974";
$icon-pricing: "\e975";
$icon-profile: "\e976";
$icon-project: "\e977";
$icon-projects_solid: "\e978";
$icon-rate-history: "\e979";
$icon-reset: "\e97a";
$icon-save: "\e97b";
$icon-scissors: "\e97c";
$icon-score: "\e97d";
$icon-search: "\e97e";
$icon-send: "\e97f";
$icon-settings: "\e980";
$icon-shrink: "\e981";
$icon-snooze: "\e982";
$icon-snooze_small: "\e983";
$icon-sort: "\e984";
$icon-star: "\e985";
$icon-subtract: "\e986";
$icon-subtract_small: "\e987";
$icon-suggestion: "\e988";
$icon-task: "\e989";
$icon-tasks_solid: "\e98a";
$icon-template: "\e98b";
$icon-time: "\e98c";
$icon-timesheet: "\e98d";
$icon-timesheets_solid: "\e98e";
$icon-underline: "\e98f";
$icon-unlike_solid: "\e990";
$icon-upload: "\e991";
$icon-work-type: "\e992";
$icon-wrench: "\e993";
$icon-appsettings: "\e994";
$icon-adduser: "\e995";
$icon-usersettings: "\e996";
