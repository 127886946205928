﻿.dropdown {
    @include border-radius(8px);
    @include box-shadow(4);
    background-color: $day-grey-white;
    box-sizing: border-box;
    margin: 4px 0 0;
    overflow: hidden;
    padding: 8px 0;
    position: absolute;
    text-align: center;
    width: 260px;
    z-index: 1004;
    opacity: 0;
    will-change: width, height, opacity;

    &[x-placement="bottom-start"],
    &[x-placement="bottom-end"] {
        margin-top: 8px;
    }

    &[x-placement="top-start"],
    &[x-placement="top-end"] {
        margin-bottom: 8px;
    }

    &--wide {
        width: 310px;
    }

    &:focus {
        outline: none;
    }

    &[right] {
        right: 0;
    }

    .dropdown__header {
        background-color: $day-primary-blue;
        color: $day-grey-white;
        margin: 0 0 8px 0;
        padding: 12px 16px;
        text-align: left;
        z-index: 20;
    }

    .dropdown__item {
        display: flex;
        align-items: center;
        justify-items: start;
        gap: 18px;
        flex-wrap: nowrap;
        white-space: nowrap;
        cursor: pointer;
        margin: 0;
        padding: 16px;
        height: 56px;
        text-align: left;
        text-decoration: none;
        font-size: 16px;
        font-weight: $font-weight-light;
        color: $day-text-default;
        width: 100%;


        &:hover {
            background-color: $day-primary-lightest;
        }

        &:focus {
            outline: none;
        }

        &:active {
            background-color: $day-primary-light;
        }

        &.is-active {
            font-weight: $font-weight-semibold;
        }

        &.is-selected {
            color: $day-grey-white;
            font-weight: $font-weight-semibold;
        }

        .dropdown__item__icon {
            color: $color-utility;
            font-size: 24px;
            line-height: 20px;

            &--white {
                color: $day-grey-white;
            }
        }

        .dropdown__item__label {
            font-size: 16px;
            line-height: 1.25;
        }
    }

    &--primary {
        background-color: $day-primary-blue;

        .dropdown__item {
            color: $day-grey-white;

            &:hover {
                background-color: $day-primary-tint;
                color: $day-grey-white;
            }

            &:active {
                background-color: $day-primary-dark;
                color: $day-grey-white;
            }
        }
    }

    &--project__header {
        top: calc(100% + 2px);
    }

    &--more__actions {
        right: 0;
        top: 32px;
        height: 72px;

        .dropdown__item {
            font-size: 16px !important;

            &:hover {
                background-color: $day-accent-lightest;
            }

            &:active {
                background-color: $day-accent-light;
            }

            .icon {
                font-size: 22px !important;
                color: $day-utility-utility !important;
            }
        }
    }

    &--column__chooser {
        width: 310px;
        top: 64px;
        text-align: left;
        margin-left: -16px;

        &[right] {
            right: unset;
        }
    }

    &--accent {
        .dropdown__item {
            &:hover {
                background-color: $day-accent-lightest;
            }

            &:active {
                background-color: $day-accent-light;
            }
        }
    }

    &--sort {
        right: 0;
        height: 336px;
    }

    &.expand-levels {
        padding: 0 8px;
        right: unset;
        top: 64px;
        left: 24px;
        height: 56px;
        width: auto;
        @include flexbox();
        align-items: center;
        @include box-shadow(0 12px 28px 0 rgba(0, 37, 77, 0.12));
        font-size: 14px;
        cursor: default;

        & > span {
            color: $day-utility-dark;
            font-weight: $font-weight-medium;
            margin-right: 8px;
            white-space: nowrap;
        }

        label {
            pointer-events: auto;
            @include border-radius(16px);
            height: 32px;
            min-width: 32px;
            line-height: 32px;
            text-align: center;
            vertical-align: middle;
            background-color: $day-grey-lightest;
            margin-left: 8px;
            color: $day-utility-utility;
            font-weight: $font-weight-semibold;
            cursor: pointer;

            &:last-child {
                padding: 0 4px;
            }

            &:hover {
                background-color: $day-primary-lightest;
                color: $day-primary-darkest;
            }

            &:active {
                background-color: $day-primary-light;
            }

            input {
                display: none;
            }
        }
    }

    &.is-closed {
        height: 0;
        width: 0;
    }

    &--fab,
    &--fab[right] {
        padding: 0;
        right: 3.5rem;
        bottom: 8.5rem;
    }

    &--new-project {

        .dropdown__item {
            font-size: 1rem;

            .icon {
                font-size: 24px;
            }
        }
    }
}
