.container {
    display: flex;
    justify-content: center;
    padding-top: 44px;
    margin: 0px;
    max-width: unset;
    padding-left: 0px;
    padding-right: 0px;
}

.content {
    border-radius: 3px;
    box-shadow: var(--shadow);
    width: 100%;
}
