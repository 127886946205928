.settings {
  &-container {
    width: 100%;
    padding: 44px 48px;
    border-radius: 16px;
    background-color: $day-grey-white;
  }

  &-title {
    color: $day-text-default;
    font-size: 28px;
    font-weight: $font-weight-light;
    margin-bottom: 36px;
  }
}

.input-control {
  width: 100%;
  border: 0;
  color: $day-text-default;
  padding: 12px 0;
  font-size: 16px;
  font-family: $bst-font-secondary;
  font-weight: $font-weight-light;
  border-bottom: 1px solid $day-utility-lightest;
  outline: none;

  &:focus {
    outline: none;
  }

  &__date {
    position: relative;
  }

  &__date-input {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__calendar {
    top: 100%;
    z-index: 11;
    position: absolute;
  }
}
