﻿.chip {
    @include border-radius(16px);
    background-color: $day-primary-lightest;
    color: $day-utility-dark;
    font-size: 12px;
    font-weight: $font-weight-medium;
    padding: 8px 16px;

    &--light {
        background-color: $day-grey-lightest;
        color: $day-grey-dark;
    }
}
