﻿.addnewtask {
    @include flexbox;
    height: 54px;
    align-items: center;
    margin-bottom: 2rem;
    color: $day-utility-lightest;
    font-size: 14px;
    font-weight: $font-weight-semibold;

    div {
        width: 58px;
        text-align: center;
        padding-left: 16px;
    }

    button {
        color: inherit;
        font-weight: inherit;
        font-size: inherit;
        padding: 0 26px;
        letter-spacing: .5px;
    }
}
