﻿.button-group {
    align-items: center;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    justify-content: start;

    &--taskActions {
        grid-gap: 8px;
    }

    &.padding-right{
        padding-right: 1rem;
    }

    &.paddingx {
        padding-left: 1em;
        padding-right: 1em;
    }
}

.button-group-vertical {
    padding: .5rem 0;

    button {
        display: block;
        color: $day-text-default;
        font-size: 1rem;
        width: 100%;
        height: 56px;
        line-height: 56px;
        text-align: left;
        vertical-align: middle;

        &:not(.button_more-actions) {
            &:hover {
                background-color: $day-accent-lightest;
            }

            &:active {
                background-color: $day-accent-light;
            }
        }

        .icon {
            font-size: 1.5rem;
            margin: auto .75rem;
            vertical-align: middle;
            color: $day-accent-purple;
        }

        &.task_delete {
            .icon {
                color: $day-utility-utility;
                margin-right: .5rem;
            }
            span {
                margin: 0;
                vertical-align: middle;
            }
        }

        &.button_more-actions {
            font-size: 14px;
            font-weight: $font-weight-semibold;
            color: $day-text-default;
            position: relative;
            border-top: 1px solid $day-accent-lightest;

            span {
                margin-left: .75rem;
            }

            .icon {
                position: absolute;
                right: 1rem;
                top: 23px;
                color: $day-utility-utility;
                font-size: .75rem;
            }
        }
    }

    .section_more-actions {
        button > span {
            margin-left: .75rem;
        }
    }
}


.button-group-v2 {
    @include flexbox;
    justify-content: flex-end;
    padding: 24px 32px;

    &--center {
        justify-content: center;
        padding: 5px 0 0;
    }

    .button:not(:last-child) {
        margin-right: 16px;
    }
}