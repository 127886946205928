﻿.info-table {
    @include border-radius(16px);
    border: 1px solid #d9e5f2;
    margin-bottom: 24px;
    overflow: hidden;

    &--bottom-spacing .e-gridcontent .e-table {
        margin-bottom: 25px;
    }

    &__header {
        @include flexbox;
        flex-direction: row;
        padding: 24px 32px;
        justify-content: space-between;

        .label-md {
            margin: 0 0 8px;
            color: $day-utility-utility;
        }

        .text-truncate {
            display: block;
            padding-bottom: 10px;
            border-bottom: 1px solid $day-utility-lightest;
        }
    }

    th {
        padding: 28px 24px !important;
    }

    td {
        padding: 0 !important;
    }

    th, td {
        &:not(:last-child) {
            border-right: 1px solid $day-utility-lightest !important;
        }

        &:first-child {
            border: none !important;
        }
    }

    th:nth-child(2),
    td:nth-child(2) label {
        padding-left: 5px !important;
    }

    th span {
        @extend .label-md;
        font-weight: 500;
        color: $day-utility-dark;
        font-family: $bst-font-family;
    }

    td span {
        font-size: 14px;
        color: $day-text-default;
        font-family: 'Open Sans', sans-serif;
    }
    
    .e-gridpopup {display: none;}

    .sf-grid.e-grid {
        border: none;
    }

    &__scroll-wrap {
        @include scrollbars($mode: 'h');
    }

    .sf-grid.e-grid {
        min-width: 1100px;
    }

    .e-gridheader {
        border-top: 1px solid $day-utility-lightest;
        border-bottom: 2px solid $day-utility-lightest;
    }

    .e-gridcontent {
        @include scrollbars($mode: 'v');
        max-height: 400px;

        .e-row {
            height: 56px;

            &:hover {
                .e-rowcell {
                    background: rgba(18, 116, 230, 0.04) !important;
                }
            }

            .e-active {
                background: $day-primary-lightest !important;
            }

            .e-rowcell {
                overflow: visible !important;
                height: 56px;
                border-right: none !important;

                &.e-inputbox:focus-within {
                    border-right: 1px solid $day-primary-blue !important;
                }

                &:not(:last-child):not(:first-child) {
                    position: relative;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 12px;
                        right: -1px;
                        bottom: 12px;
                        width: 1px;
                        background-color: #d9e5f2;
                        opacity: .4;
                    }
                }

                & > div {
                    position: relative;
                    height: 100%;

                    .floating-menu {
                        left: 190px !important;
                        padding: 0 !important;
                        top: 100%;
                        transform: translate(0, -50%);
                        bottom: unset;
                        right: unset;
                    }

                    .icon-button {
                        cursor: pointer;
                        border: 1px solid $day-utility-lightest;
                        box-shadow: none;
                        background: $day-text-white;

                        .icon {
                            color: $day-primary-blue;
                            font-size: 16px;
                        }
                    }
                }
            }

            &:not(:first-child) {
                td {
                    border-top: 1px solid $day-utility-lightest !important;

                    &.e-inputbox:focus-within {
                        border-top: 1px solid $day-primary-blue !important;
                    }
                }
            }

            &:last-child {
                td {
                    border-bottom: 1px solid $day-utility-lightest !important;
                }
            }
        }
    }
}

.list-view {
    overflow: hidden;

    .e-gridheader {
        border-top: none;
        border-bottom: 2px solid $day-utility-lightest;
        padding-right: 0 !important;
    }

    .e-yscroll {
        @include scrollbars($mode: 'v');
    }

    .e-gridcontent .e-row {
        .e-rowcell {
            border-color: $day-primary-lightest;
            //** Refactor this with second interation
            &:first-child label {
                font-size: 18px;
                font-weight: 500;
                color: $day-text-default;
            }

            &:nth-child(2),
            &:nth-child(3) {
                label {
                    font-size: 14px;
                    font-weight: 600;

                    input {
                        color: $day-utility-utility;
                    }
                }
            }
            //**
        }

        &:hover .e-rowcell {
            background: $day-primary-lightest !important;
            //** Refactor this with second interation
            &:nth-child(2),
            &:nth-child(3) {
                label input {
                    color: $day-utility-dark;
                }
            }
            //**
        }
    }

    .e-headercell .column-header {
        height: 80px;
    }

    th, td {
        padding: 0 !important;
    }

    .e-headercelldiv,
    .e-headercell {
        height: 84px;

        .e-rhandler {
            border-color: $day-utility-lightest !important;
        }
    }

    .column-header {
        > div {
            @extend .label-md;
            font-weight: 500;
            color: $day-utility-dark;
        }
    }

    .property {
        height: 84px;
        line-height: 83px;
    }

    td:not(:last-child) {
        .property:after {
            content: '';
            position: absolute;
            width: 1px;
            height: 32px;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            background: $day-utility-lightest;
        }
    }

    .e-grid .e-cloneproperties {
        border: 2px solid $day-primary-blue;
        background: $day-primary-lightest;
        border-radius: 0;
        padding: 32px 24px;
        color: $day-primary-blue;
        font-family: $bst-default-font-family;
        text-align: left;
        line-height: 1 !important;
        height: 84px !important;
        @extend .label-md;
    }
    //** TO DO, make this style global
    .e-reorderuparrow {
        display: none !important;
    }
    
    .e-reorderdownarrow:before {
        content: '';
        position: absolute;
        width: 4px;
        background-color: $day-primary-blue;
        border-radius: 2px;
        right: -6px;
        top: 2px;
        height: 84px;
    }
    //**
}
