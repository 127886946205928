iframe {
    border: 0;
    padding: 0px;
}

.pbi-summary__report {
    height: 300px;
    margin-top: -15px;
    @media screen and (max-width: 1279px) and (min-width: 920px) {
        height: 242px;
    }
    @media screen and (max-width: 919px) and (min-width: 720px) {
        height: 226px;
    }
    @media screen and (max-width: 719px) {
        height: 294px;
    }
}

.pbi-details__report{
    height: 1000px;
}

.isLoading {
    display: none;
}
.rectangle1 {
    width: 1px;
    height: 1px;
}

.skeleton {
    width: 100%;
    height: 289px;
    @include animation(backgroundPosition, 2s, linear, 0s, infinite);
   
    
    &_rectangle1 {
        x:64;
        y:41;
        rx:8;
        ry:8;
        width:283px;
        height:26px;
        background: $day-primary-lightest-tint linear-gradient(to right, rgba($day-utility-lightest, 0) 0%, rgba($day-utility-lightest, 0.16) 25%, $day-utility-lightest 50%, rgba($day-utility-lightest, 0.16) 75%, rgba($day-utility-lightest, 0) 100%);
    }
    &_rectangle2{
        x:64; 
        y:76; 
        rx:8; 
        ry:8; 
        width:200px; 
        height:16px;
    }
    &_rectangle3{
        x:64; 
        y:113.5; 
        rx:8; 
        ry:8; 
        width:506px; 
        height:136px;
        background: $day-primary-lightest-tint linear-gradient(to right, rgba($day-utility-lightest, 0) 0%, rgba($day-utility-lightest, 0.16) 25%, $day-utility-lightest 50%, rgba($day-utility-lightest, 0.16) 75%, rgba($day-utility-lightest, 0) 100%);
    }
    &_rectangle4{
        x:602;
        y:113; 
        rx:0; 
        ry:0;
        width:1px;
        height:136px;
        background: $day-primary-lightest-tint linear-gradient(to right, rgba($day-utility-lightest, 0) 0%, rgba($day-utility-lightest, 0.16) 25%, $day-utility-lightest 50%, rgba($day-utility-lightest, 0.16) 75%, rgba($day-utility-lightest, 0) 100%);
    }
    &_rectangle5{
        x:634;
        y:164.5;
        rx:8;
        ry:8;
        width:calc(100% - 768px);
        height:34px;
        background: $day-primary-lightest-tint linear-gradient(to right, rgba($day-utility-lightest, 0) 0%, rgba($day-utility-lightest, 0.16) 25%, $day-utility-lightest 50%, rgba($day-utility-lightest, 0.16) 75%, rgba($day-utility-lightest, 0) 100%);
    }

    @include mq(sm_down){
        width: 100%;
        height: 289px;

        &_rectangle1 {
            x:24;
            y:33;
            rx:8;
            ry:8;
            width:calc(100% - 917px);
            height:26px;
        }
        &_rectangle2{
            x:24; 
            y:68; 
            rx:8; 
            ry:8; 
            width:calc(100% - 1001px); 
            height:12px;
        }
        &_rectangle3{
            x:24; 
            y:92; 
            rx:8; 
            ry:8; 
            width:calc(100% - 82px); 
            height:100px;
        }
        &_rectangle4{
            x:24;
            y:212; 
            rx:8; 
            ry:8;
            width:calc(100% - 84px);
            height:34px;
        }
        &_rectangle5{
            display: none;
    
        }
    }
   
    
}