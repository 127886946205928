﻿.app-home {
    @include flexbox();
    align-items: center;
    color: $day-primary-blue;
    cursor: pointer;
    font-size: 20px;
    font-weight: $font-weight-semibold;
    margin: 0;
    padding: 0 24px;

    @include mq(sm_down) {
        padding: 0 16px;
    }

    .app-home__app-name {
        padding-bottom: 1px;
        border-bottom: 2px solid transparent;
    }

    &:focus-visible {
        .app-home__app-name {
            border-color: $day-primary-blue;
        }
    }
        
    .app-home__logo {
        padding: 8px 16px 0 0;
        font-size: 2.5rem;

        @include mq(md_down) {
            padding: 4px 16px 0 0;
        }
    }
}
