﻿.e-gantt .e-splitter {
    min-height: 0;

    .e-split-bar,
    .e-split-bar-hover,
    .e-split-bar-active {
        position: static;
        min-width: 1px !important;
        max-width: 1px !important;
        width: 1px !important;
        background-color: transparent !important;
        z-index: 1;

        .e-resize-handler.e-icons {
            all: initial;
            position: absolute;
            top: 0;
            align-self: center;
            min-width: 46px !important;
            min-height: 100%;
            @include flexbox();
            align-content: center;
            justify-content: center;
            align-items: center;
            cursor: col-resize;
            z-index: 2;

            &::before {
                all: initial;
                content: '';
                position: absolute;
                width: 1px;
                background-color: $day-primary-blue;
                top: $layout-gantt-header-height;
                height: 100%;
                visibility: hidden;
                cursor: col-resize;
            }

            &::after {
                all: initial;
                font-family: '#{$icomoon-font-family}';
                content: '#{$icon-drag}';
                color: $day-grey-white !important;
                font-size: .75rem;
                background-color: $day-primary-blue;
                width: 24px !important;
                height: 24px !important;
                @include border-radius(50%);
                align-content: center;
                justify-content: center;
                align-items: center;
                @include transform(translateY(28px));
                cursor: pointer;
                display: none;
            }

            &:hover,
            &:active,
            &.e-resize-handler--active {
                &::before {
                    visibility: visible;
                }

                &::after {
                    @include flexbox();
                }
            }
        }

        &:hover, &:active {
            background-color: transparent;
        }

        .e-navigate-arrow,
        &:hover .e-navigate-arrow,
        &:active .e-navigate-arrow {
            all: initial;
            position: relative;

            &.e-arrow-left {
                all: initial;
                position: absolute;
                right: 1.25rem;
                top: 6.44rem;
                padding: 0;
                width: 2rem;
                height: 2rem;
                background-color: $day-grey-white !important;
                @include border-radius(50%);
                @include box-shadow(0);
                @include flexbox;
                align-content: center;
                justify-content: center;
                align-items: center;

                &::before {
                    all: initial;
                    font-family: '#{$icomoon-font-family}';
                    content: '#{$icon-expand}';
                    color: $day-utility-utility;
                }

                &::after {
                    all: initial;
                }

                &:active {
                    background-color: $day-primary-lightest !important;
                }
            }

            &.e-arrow-right {
                all: initial;
                align-self: flex-start;
                margin-top: 14px;
                min-width: 24px;
                height: 64px;
                @include filter(drop-shadow(8px 0 4px rgba(55, 78, 91, 0.05)));
                @include transform(translateX(11px));
                background-color: transparent !important;
                z-index: 2;

                &::before {
                    all: initial;
                    font-family: '#{$icomoon-font-family}';
                    content: '#{$icon-chevron-right}';
                    @include flexbox();
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                    color: $day-utility-utility;
                    min-width: 24px;
                    height: 64px;
                    clip-path: polygon(0 0, 100% 13%, 100% 87%, 0 100%);
                    background-color: $day-grey-white !important;
                    @include border-radius(0 20px 20px 0);
                }

                &::after {
                    all: initial;
                }
            }
        }

        &::after {
            width: 46px !important;
            z-index: 1 !important;
            color: $day-primary-blue !important;
        }

        .today-button,
        .today-button.e-split-bar-hover {
            visibility: visible;
            position: absolute;
            align-self: flex-start;
            font-size: 12px;
            height: 24px;
            min-width: 66px !important;
            line-height: 24px;
            vertical-align: middle;
            text-align: center;
            font-weight: $font-weight-semibold;
            @include border-radius(12px);
            padding: 0 1rem;
            background-color: $day-grey-lightest;
            color: $day-utility-utility;
            @include transform(translate(76px, 10px));

            &:hover {
                background-color: $day-primary-lightest !important;
                color: $day-primary-dark !important;
            }

            &:active {
                background-color: $day-primary-light !important;
                color: $day-primary-darkest !important;
            }
        }
    }

    .e-expanded ~ .e-split-bar,
    .e-collapsed ~ .e-split-bar {
        margin: 0 !important;
        visibility: hidden;
        width: 0 !important;

        .e-hide-handler,
        .e-arrow-right.e-icon-hidden {
            visibility: hidden;
            display: none;
            pointer-events: none;
        }

        .e-navigate-arrow,
        &:hover .e-navigate-arrow,
        &:active .e-navigate-arrow {
            &.e-arrow-left:not(.e-icon-hidden) {
                all: initial;
                align-self: flex-start;
                margin-top: 14px;
                min-width: 24px;
                height: 64px;
                @include filter(drop-shadow(-2px 0 10px rgba(55, 78, 91, 0.1)));
                background-color: transparent !important;
                @include transform(translateX(-11px));
                z-index: 2;

                &::before {
                    content: '#{$icon-gantt}';
                    @include flexbox();
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                    color: $day-utility-utility;
                    min-width: 24px;
                    height: 64px;
                    clip-path: polygon(0 13%, 100% 0, 100% 100%, 0 87%);
                    background-color: $day-grey-white !important;
                    @include border-radius(20px 0 0 20px);
                }
            }

            &.e-arrow-left.e-icon-hidden::before {
                content: '#{$icon-shrink}' !important;
            }

            &.e-arrow-right {
                @include filter(drop-shadow(2px 0 10px rgba(55, 78, 91, 0.15)));
            }
        }
    }
}
