.content-not-supported {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  .content-not-supported-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 104px 48px;
    gap: 24px;
    background: #FFFFFF;
    box-shadow: $box-shadow--1;
    @include border-radius(16px);
    margin: 16px;
    max-width: 634px;
    .heading-xl { text-align: center;}
    .lead { color: $day-utility-utility;}
  }
}