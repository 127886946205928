﻿.form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 32px;
    max-width: 824px;
}
.form__group {
    color: $color-utility;
    margin: 0 0 24px;
}
.form__select {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $color-utility-lightest;
    color: $color-text;
    display: block;
    font-size: 16px;
    height: 48px;
    outline: none;
    width: 100%;
}