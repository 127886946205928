.input-control__calendar {
  @include border-radius(8px);

  &.e-calendar {
    box-sizing: border-box;
    min-width: 332px;
    font-family: $bst-font-family !important;
    overflow: hidden;

    .e-header {
      background-color: $day-primary-blue;
      position: relative;
      @include flexbox();
      height: 60px;
      justify-content: center;
      align-items: center;
      padding: unset !important;

      div:first-child {
        color: $day-grey-white !important;
        margin: 0 !important;
        padding: 2px bottom !important;
        align-self: center;
        font-size: 18px;
        font-weight: $font-weight-medium;
        line-height: 1.5rem;
      }

      .e-icon-container {
        position: static;

        .e-prev,
        .e-next {
          position: absolute;
          height: 24px !important;
          width: 24px !important;
          top: calc(50% - 14px);
          overflow: hidden;

          &:hover {
            background-color: $day-primary-dark;
          }

          &:active {
            background-color: $day-primary-blue;
          }

          .e-date-icon-prev,
          .e-date-icon-next {
            position: relative;
            color: $day-grey-white;
            font-size: 1rem;
            width: auto;
            height: auto;
            padding: 0;
            display: block;
            vertical-align: middle;
          }

          .e-date-icon-prev::before,
          .e-date-icon-next::before {
            font-family: "#{$icomoon-font-family}";
            content: "#{$icon-chevron-left}";
            position: relative;
            display: block;
          }

          .e-date-icon-prev::before {
            content: "#{$icon-chevron-left}";
          }

          .e-date-icon-next::before {
            content: "#{$icon-chevron-right}";
          }
        }

        .e-prev {
          left: 12px;
        }

        .e-next {
          right: 12px;
        }
      }
    }

    .e-content {
      table {
        background-color: $day-primary-tint !important;
        padding: unset !important;

        thead {
          background-color: $day-primary-blue;

          th {
            box-sizing: content-box;
            padding: unset;
            color: $day-grey-white;
            width: 44px;
            height: 24px;
            font-size: 12px;
            line-height: 0.75rem;
            vertical-align: top;

            &:first-child {
              border-left: 12px solid $day-primary-blue;
            }

            &:last-child {
              border-right: 12px solid $day-primary-blue;
            }
          }
        }

        tr {
          &:first-child > td {
            height: 48px !important;
          }

          &:nth-last-child(2) > td {
            height: 48px !important;
          }

          td {
            padding: 0 !important;
            width: 44px !important;
            height: 44px !important;

            &:first-child {
              border-left: 12px solid $day-primary-tint;
            }

            &:last-child {
              border-right: 12px solid $day-primary-tint;
            }

            & > .e-day {
              color: $day-grey-white !important;
              background-color: $day-primary-tint !important;
              box-sizing: content-box;
              font-size: 14px;
            }

            &:hover > .e-day {
              box-shadow: 0 0 0 4px rgba($day-grey-white, 0.12) !important;
              background-color: rgba($day-grey-white, 0.12) !important;
            }

            &.e-today > .e-day {
              color: $day-grey-white !important;
              border: 1px solid $day-grey-white !important;
            }

            &.e-selected > .e-day,
            &:active > .e-day {
              color: $day-primary-darkest !important;
              background-color: $day-grey-white !important;
            }
          }

          td.e-disabled.e-today span.e-day {
            opacity: 0.35;
            box-shadow: none;
          }
        }
      }
    }

    .e-content.e-year,
    .e-content.e-decade {
      table {
        height: 228px !important;

        tr > td {
          height: 76px !important;
          width: 83px !important;
        }
      }
    }

    .e-footer-container {
      display: flex;
      align-content: center;
      justify-content: center;
      background-color: $day-primary-blue;
      padding: 10px;
      height: 72px;
      align-items: center;

      .e-btn.e-today {
        color: $day-grey-white !important;
        @include border-radius(40px);
        border: 1px solid $day-primary-light;
        height: 40px;
        width: 200px;
        text-transform: capitalize;
        font-family: $bst-font-family !important;
        font-size: 14px;
        font-weight: $font-weight-medium;
        line-height: 1.125rem;

        &:hover {
          background-color: $day-primary-dark;
          border: 1px solid $day-primary-light;
        }

        &:active {
          background-color: $day-primary-darkest;
          border: 1px solid $day-primary-light;
        }
      }
    }
  }
}
