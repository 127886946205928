﻿.two-column-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 44px 32px;

    @include mq(md_up) {
        grid-gap: 48px;
    }

    @include mq(xxl) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
