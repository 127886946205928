﻿.property {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 56px;
    line-height: 55px;
    vertical-align: middle;
    /*    overflow: hidden; */
    padding: 0 24px;
    text-overflow: ellipsis;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;

    &:not(.property-cell):not(.property-cell--readonly) {
        border-bottom: 1px solid $day-utility-lightest;

        &:before {
            content: '';
            position: absolute;
            width: 1px;
            height: 36px;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            background: $day-utility-lightest;
        }
    }

    &:not(.property-cell--readonly):focus-within {
        &:not(div.property-cell) {
            @extend .property;
            background-color: $day-grey-white;
            text-overflow: clip;

            &::before {
                content: '';
                position: absolute;
                box-sizing: border-box;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border: 1px solid $day-primary-blue;
            }

            &::after {
                display: none;
            }
        }
    }

    & > input,
    & > input:read-only,
    .e-datepicker,
    .e-autocomplete {
        font-size: 14px !important;
        color: $day-text-default;
        font-family: "Open Sans", Arial, Helvetica, sans-serif !important;
        overflow-x: hidden;
        text-overflow: ellipsis;

        &[type="text"] {
            border: none;
            width: 100%;
            padding: 0;
            outline: none;
            font: inherit;

            &.text-right {
                text-align: right;
            }
        }
    }

    .property-cell--checkbox {
        .icon {
            display: none;
            margin: 1px 0 0 1px;
            font-weight: 600;
            color: $day-grey-white;
        }

        input[type="checkbox"] {
            opacity: 0;
        }

        input[type="checkbox"]:checked + .property-cell__checkbox-dummy {
            border: 2px solid $day-primary-blue !important;
            background: $day-primary-blue;

            .icon {
                display: block;
            }
        }

        input[type="checkbox"]:active + .property-cell__checkbox-dummy:before {
            opacity: 0.12;
        }
    }

    .property-cell__checkbox-dummy {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        border: solid 2px $day-utility-utility;
        line-height: 1;
        box-sizing: border-box;
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: $day-primary-blue;
            opacity: 0;
        }

        &:hover:before {
            opacity: 0.08;
        }
    }

    &:focus-within {
        .property-cell__checkbox-dummy:before {
            opacity: 0.08;
        }
    }

    .e-date-wrapper,
    .e-control-wrapper {
        margin-bottom: 0;
        border-bottom: none !important;

        &:before,
        &:after {
            display: none !important;
        }
    }

    .e-date-wrapper {
        font-size: 14px;

        .e-date-icon {
            color: $day-utility-utility;

            &:before {
                border: 1px solid transparent;
                border-radius: 50%;
                padding: 5px;
            }

            &:hover,
            &.e-active {
                &:before {
                }
            }

            &:hover {
                color: $day-utility-dark;

                &:before {
                    background: $day-primary-lightest-tint;
                }
            }

            &.e-active {
                color: $day-primary-blue !important;

                &:before {
                    border: 1px solid $day-primary-blue;
                    background: $day-primary-lightest;
                }
            }
        }
    }

    .text-right {
        line-height: 1 !important;
    }
}

.property,
.property-form {
    * {
        &::selection {
            background: $day-primary-blue !important;
            color: $day-text-white !important;
        }
    }

    .e-input-group-icon.e-ddl-icon:before {
        font-family: "bst-icon-font" !important;
        content: $icon-chevron-down !important;
        color: $day-utility-utility;
    }
}

.property-form {
    label {
        @extend .label-xs;
        font-weight: 500;
        color: $day-utility-utility;

        > span {
            margin-top: 4px;
        }
    }

    span {
        border-color: $day-utility-lightest !important;
        margin-bottom: 0;

        &.e-input-focus {
            border-color: $day-primary-blue;
        }
    }

    label > span:before,
    label > span:after {
        display: none !important;
    }

    input {
        width: 100%;
        font-size: 16px !important;
        color: $day-text-default !important;
        border: none;
        outline: none;
        border-bottom: 1px solid $day-utility-lightest;
        padding: 8px 0 12px 0;
        font-family: "Open Sans", Arial, Helvetica, sans-serif !important;

        &.e-input {
            padding-bottom: 10px !important;
        }

        &:focus,
        &:active {
            border-color: $day-primary-blue;
        }
    }

    &--read-only {
        opacity: 0.5;

        input {
            &:focus,
            &:active {
                border-color: $day-utility-lightest;
            }
        }
    }
}
