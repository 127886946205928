﻿.global-utilities__hyperFocus {
    height: calc(100%) !important;
    top: 80px !important;

    @include mq(sm_down){
        top:24px !important;
    }

    .summary-top {
        margin-top: 0;
    }

    .modal-content {
        height: 100%;
    }

    .modal-body {
        padding: 0;
        @include scrollbars($thumb-background: rgb(64, 71, 79, 0.3), $mode: 'h', $size: 0px, $staticV: true);
    }

    .modal-header {
        padding: 30px 32px 33px;
        border-bottom: 1px solid $day-utility-lightest;
    }


    .content-center.dialog {
        border-top-left-radius: var(--bs-modal-inner-border-radius);
        border-top-right-radius: var(--bs-modal-inner-border-radius);
        margin: 0 !important;
        height: 100%;
        animation: slide-up-fade-in ease 700ms;
        animation-iteration-count: 1;
        transform-origin: 50% 50%;
        animation-fill-mode: forwards;
        opacity: 0;
        opacity: 1\9;
        overflow: hidden;
    }


    &--bottom {
        height: 100% !important;
        top: 0px !important;
        display: flex !important;
        justify-content: flex-end;
        align-items: flex-end;

        .modal-header {
            display: flex;
            align-items: center;
            padding: 24px;
            padding-left: 32px;
            border-bottom: 1px solid $day-utility-lightest;
        }

        .modal-body {
            padding: 24px 32px 40px;
        }

        .modal-content {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .content-center.dialog {
            width: 100%;
            max-width: 740px;
            margin-bottom: 0;
            margin-right: 4.5rem;
        }

        .modal-footer {
            padding: 16px 32px 24px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 16px;

            .button {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 18px;
                min-width: 160px;

                .icon {
                    font-size: 24px !important;
                }
            }

        }
    }


    @include mq(xl_down) {
        &--bottom .content-center.dialog {
            margin-right: 4rem;
        }
    }

    @include mq(lg_down) {
        &--bottom .content-center.dialog {
            margin-right: 2.75rem;
        }
    }

    @include mq(md_down) {
        &--bottom .content-center.dialog {
            margin: 0 2rem;
            width: 100%;
            max-width: 100%;
        }
    }
}

.global-utilities__container {
    width: 100%;
    box-sizing: border-box;
    padding: 0 4.5rem;

    @include mq(xl_down) {
        padding: 0 4rem;
    }

    @include mq(lg_down) {
        padding: 0 2.75rem;
    }

    @include mq(md_down) {
        padding: 0 2rem;
    }
}

.e-multi-select-list-wrapper {
    padding: 0;

    .e-list-parent {
        padding: 0 !important;
        border: none !important;
    }

    .e-nodata {
        color: $color-gray-white !important;
    }

    .e-list-item {
        border: none !important;
        margin: 0 !important;
        padding: 12px 0 !important;
        height: auto !important;
        min-height: 0 !important;
        line-height: normal !important;
        font-family: 'Open Sans !important';
        font-size: 16px !important;

        &.e-active,
        &.e-item-focus,
        &:hover {
            color: $color-gray-white !important;
            background-color: transparent !important;
        }
    }

    .e-checkbox-wrapper {
        span.e-frame.e-icons {
            border-color: $color-gray-white !important;
            background-color: transparent !important;

            &.check {
                border-color: $color-gray-white !important;
                background-color: $color-gray-white !important;
            }
        }
    }
}

.input-error-message {
    color:$day-red-dark;
    font-size: 12px;
    position: absolute;
    margin: -24px 0 0 40px;
}

.input-icon {
    display: flex;
    width: 100%;
    margin-bottom: 32px;
    align-items: center;
    flex-wrap: nowrap;

    &:first-child {
        margin-top: 24px;
    }

    &__label {
        color: $day-utility-utility;
        font-family: $bst-font-family;
        font-size: 12px;
        text-transform: uppercase;
        position: absolute;
        bottom: 13px;
        left: 0;
        font-weight: $font-weight-medium;
    }

    &:hover {
        .input-icon__label {
            color: $day-utility-dark;
        }
    }

    &__icon {
        flex: 0 0 auto;
        font-size: 24px;
        margin-right: 16px;
        color: $color-utility;
    }

    &__container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        position: relative;
        flex: 1 1 auto;

        &.select {
            border-bottom: none;
        }
    }

    &__input {
        &:focus {
            border-color: $day-primary-blue;
        }
    }

    &__input,
    &__select {
        border: none;
        border-color: none;
        border-bottom: 1px solid $day-utility-lightest;
        outline: 0;
        width: 100%;
        color: $color-text;
        padding: 13px 0;
        font-size: 16px;
        font-family: 'Open Sans', sans-serif;
    }

    &__select {
        pointer-events: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
        border: none;
        padding: 0 !important;
    }

    &.input-error {
        .input-icon__input, .input-icon__select {
            border-color:$day-red-red;
        }
    }

    .e-multiselect {
        flex: 1 1 auto;
        border-bottom: 1px solid $day-utility-lightest !important;

        .e-multi-select-wrapper {
            display: flex !important;
            flex-flow: row !important;
            align-items: flex-end !important;
            justify-content: flex-end !important;
        }

        .e-dropdownbase,
        .e-float-text {
            color: $day-utility-utility;
            font-size: 12px;
            text-transform: uppercase;
            font-family: 'Open Sans !important';
        }

        .e-float-text {
            &.e-label-top {
                color: $day-utility-dark !important;
            }

            &.e-label-bottom {
                color: $day-utility-utility !important;
                font-family: $bst-font-family;
                font-weight: $font-weight-medium;
            }
        }

        .e-close-hooker {
            display: none !important;
        }


        &.e-input-group .e-ddl-icon {
            order: 99;

            &::before {
                color: $day-utility-utility !important;
                content: '\e927';
                opacity: 1 !important;
                font-family: "bst-icon-font" !important;
            }
        }

        .e-chips-collection {
            padding-top: 13px !important;
            flex: 1 1 auto !important;

            .e-chips {
                background: $color-blue !important;
                color: $color-gray-white !important;
                display: flex !important;
                align-items: center !important;
            }

            .e-chipcontent,
            .e-chips-close::before {
                font-size: 14px !important;
                color: $color-gray-white !important;
                -webkit-text-fill-color: $color-gray-white !important;
            }

            .e-chips-close {
                @extend .icon-button, .icon-button--ghost-dark, .icon-button--ghost-sm;
                font-size: 14px;

                &::before {
                    content: '\e92f';
                    font-family: "bst-icon-font" !important;
                }
            }
        }

        &.e-input-focus,
        &.e-valid-input {

            // border-bottom-color: transparent !important;
            .e-float-line {

                &::before,
                &::after {
                    background: $color-blue !important;
                    height: 1px !important;
                }
            }

            &.e-input-group .e-ddl-icon::before {
                color: $day-utility-dark !important;
            }
        }
    }


    &--active {
        label {
            bottom: auto;
            top: -13px;
            transition: 200ms;
        }
    }
}

.e-toast:not(.e-toast-container) {
    height: 56px;
    width: auto !important;
    z-index: 1000;
    line-height: 56px;
    vertical-align: middle !important;
    @include border-radius(8px);
    @include box-shadow(4);
    padding: 0 24px !important;
    border-left: 8px solid $day-accent-purple !important;
    background-color: $day-grey-white !important;

}

.e-toast-message,
.e-toast-content {
    font-size: 16px !important;
    font-weight: $font-weight-medium !important;
    color: $color-text !important;
}


.DNA-button {
    color: $day-grey-white;
    padding: 12px 24px;
    border-radius: 32px;
    font-size: 16px;
    font-weight: $font-weight-medium;
    background-color: $day-primary-blue;

    &:hover {
        background-color: $color-blue-darkest !important;
    }
}

@keyframes slide-up-fade-in {
    0% {
        opacity: 0;
        transform: translate(0px, 40px);
    }

    100% {
        opacity: 1;
        transform: translate(0px, 0px);
    }
}

@-moz-keyframes slide-up-fade-in {
    0% {
        opacity: 0;
        -moz-transform: translate(0px, 40px);
    }

    100% {
        opacity: 1;
        -moz-transform: translate(0px, 0px);
    }
}

@-webkit-keyframes slide-up-fade-in {
    0% {
        opacity: 0;
        -webkit-transform: translate(0px, 40px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translate(0px, 0px);
    }
}

@-o-keyframes slide-up-fade-in {
    0% {
        opacity: 0;
        -o-transform: translate(0px, 40px);
    }

    100% {
        opacity: 1;
        -o-transform: translate(0px, 0px);
    }
}

@-ms-keyframes slide-up-fade-in {
    0% {
        opacity: 0;
        -ms-transform: translate(0px, 40px);
    }

    100% {
        opacity: 1;
        -ms-transform: translate(0px, 0px);
    }
}

.user-profile-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}
.text-tooltip {
  color: $day-text-white;
  padding: 6px 8px;
  position: fixed;
  font-size: 12px;
  text-align: center;
  font-weight: 400;
  border-radius: 4px;
  background-color: $day-utility-dark;
  z-index: 10;
}