﻿.handle {
    position: absolute;
    height: 64px;
    width: 24px;
    padding: 0;
    @include flexbox();
    align-content: center;
    justify-content: center;
    align-items: center;

    &--left {
        color: $day-grey-white;
        left: 0;

        .handle__icon {
            z-index: 1;
        }

        &::before {
            content: '';
            position: absolute;
            width: 24px;
            height: 64px;
            clip-path: polygon(0 0, 100% 13%, 100% 87%, 0 100%);
            @include border-radius(0 20px 20px 0);
            background-color: $day-other-drawer;
        }

        &:hover,
        &.is-open,
        &--white {
            color: $day-utility-utility;

            &::before {
                background-color: $day-grey-white;
            }
        }

        &:hover:after,
        &.is-open:after,
        &--white:after {
            background-color: $day-grey-white;
        }
    }

    &--right {
        @include border-radius(8px 0 0 8px);
        right: 0;
    }
}
.handle__icon {
    font-size: 16px;
}
