﻿/* Light theme */

$day-primary-light: #99cbff;
$day-primary-blue: #1274e6;
$day-primary-darkest: #004fa3;
$day-primary-lightest: #ebf5ff;
$day-primary-tint: #257fe8;
$day-primary-dark: #0063cc;
$day-primary-lightest-tint: #f6f9fe;

$day-accent-purple: #a07ce8;
$day-accent-dark: #764fc4;
$day-accent-light: #baa0ee;
$day-accent-darkest: #553399;
$day-accent-lightest: #f3f0fa;

$day-utility-utility: #57799d;
$day-utility-dark: #32475d;
$day-utility-light: #87a2bf;
$day-utility-lightest: #d9e5f2;

$day-grey-grey: #89949f;
$day-grey-dark: #6c7784;
$day-grey-darkest: #40474f;
$day-grey-light: #eef0f1;
$day-grey-lightest: #fafafa;
$day-grey-white: #ffffff;

$day-text-default: #00254d;
$day-text-white: #ffffff;

$day-red-red: #e65722;
$day-red-dark: #ca4a2b;
$day-red-lightest: #fdf1ed;
$day-red-light: #f9d5c8;

$day-green-green: #14aa51;
$day-green-dark: #0c822e;
$day-green-lightest: #edfdf3;
$day-green-light: #c8f9dc;

$day-orange-orange: #f79708;
$day-orange-dark: #c67906;
$day-orange-light: #fde6c4;
$day-orange-lightest: #fef7eb;

$day-other-gantt-bar: #79a8db;
$day-other-drawer: #b9d4f0;
