﻿.e-toolbar-items {
    .e-toolbar-right {
        .button-group {
            &.paddingx {
                padding-right: 17.5px; // set right side spacing
            }
        }
    }
}

.toggle {
    @include border-radius(18px);
    background-color: $color-gray-white;
    border: 1px solid $color-utility-lightest;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 8px;
    height: 36px;
    outline: none;
    overflow: hidden;
    position: relative;
    width: auto;

    &--sm {
        width: 132px;
        max-width: 132px;
        height: 28px;
        grid-column-gap: 0;

        .toggle__label {
            font-size: 12px;
            padding: 6px 12.8px;
            text-align: center;
        }
    }
}

.toggle__input {
    height: 0;
    margin: 0;
    opacity: 0;
    width: 0;
}

.toggle__label {
    @include border-radius(18px);
    border: 1px solid transparent;
    color: $color-utility-lightest;
    cursor: pointer;
    font-size: 16px;
    font-weight: $font-weight-semibold;
    line-height: 1;
    padding: 4px 12px;
    text-decoration: none;

    &:hover {
        color: $day-primary-dark;
    }

    &.is-active {
        background-color: $color-primary-lightest;
        border: 1px solid $color-primary;
        color: $day-primary-dark;
        padding: 6px 8px;

        &.day {
            width: 21px; //Width set as per design
        }

        &.week {
            width: 30px; //Width set as per design
        }

        &.month {
            width: 34px; //Width set as per design
            margin-left: auto;
            margin-right: 0;
        }
    }
}

.toggle__icon {
    @include border-radius(18px);
    border: 1px solid transparent;
    color: $color-utility-lightest;
    cursor: pointer;
    display: grid;
    font-size: 24px;
    width: 24px;
    padding: 4px;
    text-decoration: none;

    &:hover {
        color: $day-primary-dark;
    }

    &.is-active {
        background-color: $color-primary-lightest;
        border: 1px solid $color-primary;
        color: $day-primary-dark;
    }

    & > .icon-cards,
    & > .icon-list {
        font-size: 18px;
        margin: 3px 0px 0px 2px;
    }
}
