﻿.project-card + .project-card--ucon {
    @include mq(xl_up) {
        margin: 0 2rem 2rem 0;
    }
}

.card--ucon {
    height: 336px;
    overflow: hidden;
    padding: 0px;
    grid-template-rows: auto min-content;
    grid-template-columns: 100%;
    grid-row-gap: 0px;

    @include mq(lg_down) {
        height: 264px;
    }

    @include mq(md_down) {
        height: 252px;
    }
}

.card__image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0.75rem 2rem;
    display: grid;
    grid-template-rows: auto min-content;

    .card__tags {
        grid-row-start: 2;
        height: auto;

        display: grid;
        grid-template-columns: min-content min-content;
        grid-column-gap: 8px;

        .chip {
            display: inline-block;
            white-space: nowrap;
            /*margin: 0px 8px 0px 0px;*/
        }
    }

    @include mq(lg_down) {
        padding: 0.75rem 1.5rem;
    }

    @include mq(md_down) {
        padding: 0.75rem 1rem;
    }
}


.card__bottom {
    background-color: $day-grey-white;
    padding: 1.5rem 2rem;
    display: grid;
    grid-template-rows: min-content min-content;
    grid-row-gap: 0.5rem;

    h1 {
        color: $day-text-default;
        height: 3.5rem;
        line-height: 1.07;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @include mq(lg_down) {
        padding: 1.25rem 1.5rem;

        h1 {
            line-height: 1.27;
        }
    }

    @include mq(md_down) {
        padding: 0.75rem 1rem;

        h1 {
            line-height: 1.17;
        }
    }
}