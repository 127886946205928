﻿.loading {
    &.is-loading {
        @include animation(fade-in, 1ms, ease-in, 2s);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 60px auto;
        .loading__content {
            opacity: 0;
        }
    }
}
.loading__content {
    @include transition(opacity 500ms ease-out);
    opacity: 1;
}
