.dropdown--sort {
    background: unset;
    box-shadow: unset;


    padding: 0;
    height: unset;
    right:unset;

    .dropdown__items-wrap {
        gap: 16px;

        .dropdown__item {
            box-sizing: border-box;
            color: $day-grey-white;
            flex-grow: 1;
            font-family: "Open Sans";
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: left;
            
            &:hover {
                background-color: $day-primary-tint;
            }

            &:active,
            &:focus {
                background-color: $day-primary-dark !important;
            }
            &.is-active {
                font-weight: $font-weight-semibold;
            }
        }
    }

    .dropdown__header {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;

        display: flex;
        align-items: center;

        color: $day-text-white;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .dropdown__menu {
        background-color: $day-primary-blue;
        padding: 8px 0;
        box-shadow: 0 20px 32px 0 rgba(0, 37, 77, 0.12);
    }

    .dropdown__button {
        padding: 0px;
        height: unset;
    }

    &-scrollable {
        height: 160px;
        overflow: auto;
    }
}