﻿.tab-group {
    align-items: center;
    display: grid;
    font-size: 18px;
    grid-auto-flow: column;
    grid-gap: 8px;
    justify-content: start;
    margin: 12px 0 0;
    padding: 0;
}
.tab-group__tab {
    color: $color-utility;
    cursor: pointer;
    display: block;
    font-weight: $font-weight-semibold;
    line-height: 1.5;
    margin: 0 12px;
    padding: 12px 12px 4px;
    position: relative;
    text-decoration: none;
    &:hover {
        color: $color-primary;
    }
    &.is-active {
        color: $color-primary;
        &:before {
            @include border-radius(4px 4px 0 0);
            background-color: $color-primary;
            bottom: 0;
            content: '';
            display: block;
            height: 4px;
            left: 0;
            position: absolute;
            width: 100%;
        }
    }
    &--dark {
        color: $color-primary;
        &:hover {
            color: $color-primary;
        }
        &.is-active {
            color: $color-primary-dark;
            &:before {
                background-color: $color-primary-dark;
            }
        }
    }
    &--sm {
        font-size: 16px;
        font-weight: $font-weight-regular;
        margin: 0;
        padding: 4px 8px;
    }
}
.tab-group__tab__icon {
    font-size: 24px;
}
